import { h, render, Component } from 'preact';
import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { Color } from 'utilities/color.js';
import { shouldUseNarrowLayout } from './galleryMath.js';

const TRANSITION_DURATION = 200;

class GalleryCardCover extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.getIsTweakModeEnabled && nextProps.getIsTweakModeEnabled()) {
      return false;
    }

    return anyValuesChanged(this.props, nextProps, ['backgroundColor', 'galleryContext']);
  }

  backgroundColorWithAlpha(alpha) {
    return new Color(this.props.backgroundColor).alpha(alpha).toRgba();
  }

  backgroundGradient() {
    const { backgroundColor, galleryContext, heroAlignment, inDarkMode, shouldShowFeatured } =
      this.props;

    const gradientHeight = shouldUseNarrowLayout(galleryContext) ? '60px' : '120px';

    const darkModeNarrowLayoutGradient = `linear-gradient(to top, ${this.backgroundColorWithAlpha(
      1,
    )} 40%, ${this.backgroundColorWithAlpha(0)} 80%)`;

    const darkModeLeftAlignedGradient =
      `linear-gradient(to top, ${this.backgroundColorWithAlpha(
        1,
      )} 0%, ${this.backgroundColorWithAlpha(0)} 60%),` +
      `linear-gradient(to right, ${this.backgroundColorWithAlpha(
        1,
      )} 0%, ${this.backgroundColorWithAlpha(0)} 60%)`;

    const centerAlignedOrNotDarkModeGradient = `linear-gradient(to top, #${backgroundColor} ${gradientHeight}, ${this.backgroundColorWithAlpha(
      0.666,
    )})`;

    if (shouldUseNarrowLayout(galleryContext) && inDarkMode) {
      return darkModeNarrowLayoutGradient;
    }
    if (shouldShowFeatured && inDarkMode) {
      return centerAlignedOrNotDarkModeGradient;
    }
    if (heroAlignment === 'left' && inDarkMode) {
      return darkModeLeftAlignedGradient;
    }
    return centerAlignedOrNotDarkModeGradient;
  }

  galleryCardCoverStyle() {
    const { galleryHeroContentElem, galleryContext, videoCardsLayout } = this.props;
    const isListView = videoCardsLayout === 'list' && !shouldUseNarrowLayout(galleryContext);

    return {
      backgroundImage: this.backgroundGradient(),
      height: isListView ? galleryHeroContentElem?.clientHeight : '100vh',
      left: 0,
      maxHeight: '960px',
      position: 'absolute',
      top: 0,
      transition: `background-color ease ${TRANSITION_DURATION}ms`,
      width: '100%',
    };
  }

  render() {
    return (
      <div
        class="w-gallery-view__gallery-card-cover w-css-reset w-css-reset-tree"
        style={this.galleryCardCoverStyle()}
      />
    );
  }
}

export default GalleryCardCover;
