import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { h, render, Component } from 'preact';
import { cachedDetect } from 'utilities/detect.js';
import { throttle } from 'utilities/throttle.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import CarouselArrow from './CarouselArrow.jsx';
import CarouselMediaCard from './CarouselMediaCard.jsx';
import headerFontSizeGw from '../headerFontSizeGw.js';
import { MARGIN_AS_RATIO_OF_WIDTH, calculateCardData } from './carouselMath.js';
import SectionName from '../SectionName.jsx';
import SubscribeButton from '../SubscribeButton.jsx';
import SearchInput from '../SearchInput.jsx';
import { SearchProvider } from '../SearchProvider.jsx';
import NoResultsCard from '../NoResultsCard.jsx';
import { getChannelStorage, updateChannelStorage } from '../channelStorage.js';

const detect = cachedDetect();

const SCROLLBAR_SIZE = 25;

class CarouselSection extends Component {
  state = {
    currentHorizontalScrollDistance: 0,
    hasEnteredViewport: false,
    isHovering: false,
    isSectionCollapseToggled: false,
    algoliaSearchClient: undefined,
    totalMediaCardHeight: undefined,
  };

  constructor(props) {
    super(props);

    this.throttleUpdateHorizontalScrollDistance = throttle(500, () => {
      this.setState({
        currentHorizontalScrollDistance: this.cardsContainerRef.scrollLeft,
      });
    });
  }

  arrowSize() {
    return this.cardData.cardHeight / 3;
  }

  cardsContainerStyle() {
    // Even though the text fits perfectly in our DOM skeleton, we need to give
    // a little extra room below to avoid clipping when we scale it up on
    // hover. 1.4 is the media name's font-size; 1.12 is the size of the
    // description text.
    const paddingBottom = headerFontSizeGw(this.props, 1.4 + 1.12);
    return {
      boxSizing: 'border-box',
      overflowX: 'auto',
      overflowY: 'hidden',
      padding: 0,
      paddingBottom: `${paddingBottom}px`,
      paddingLeft: `${this.leftMarginWidth()}px`,
      paddingTop: '42px',
      position: 'relative',
      top: `${SCROLLBAR_SIZE}px`,
      webkitOverflowScrolling: 'touch',
      whiteSpace: 'nowrap',
    };
  }

  isFilteringFromSearch() {
    return this.props.isFilteringFromSearch && this.props.searchResultMediaIds;
  }

  shouldDisplayMediaFromFilter(hashedId) {
    if (this.isFilteringFromSearch()) {
      return this.props.searchResultMediaIds.includes(hashedId);
    }
    return true;
  }

  shouldHideAllCardsInSection() {
    const { isSectionCollapseToggled } = this.state;
    return isSectionCollapseToggled && !this.isFilteringFromSearch();
  }

  cardWrapperStyle(hashedId) {
    const shouldHideMedia =
      !this.shouldDisplayMediaFromFilter(hashedId) || this.shouldHideAllCardsInSection();
    return {
      display: shouldHideMedia ? 'none' : 'inline-block',
      marginRight: `${this.marginWidth()}px`,
      verticalAlign: 'top',
    };
  }

  componentDidMount() {
    this.setUpFancyRevealAnimation();
    if (this.props.shouldShowSearch) {
      this.setUpAlgoliaSearchClient();
    }

    this.setInitialCollapsedness();
  }

  componentDidUpdate() {
    // Search has been enabled but algolia client is not yet loaded
    // Load client and then force update to render the search input
    if (!this.state.algoliaSearchClient && this.props.shouldShowSearch) {
      this.setUpAlgoliaSearchClient();
    }

    // Only update if there's a media card ref to read & its height is new and greater than zero
    // Avoids superfluous rerenders and avoids bad updates to this value when the media card is hidden from search (height is zero)
    const shouldUpdateTotalMediaCardHeight =
      this.firstMediaCardDiv &&
      this.firstMediaCardDiv.clientHeight > 0 &&
      this.firstMediaCardDiv.clientHeight !== this.state.totalMediaCardHeight;

    if (shouldUpdateTotalMediaCardHeight) {
      this.setState({ totalMediaCardHeight: this.firstMediaCardDiv.clientHeight });
    }
  }

  galleryViewWidth() {
    return this.props.galleryContext.galleryViewWidth;
  }

  searchInputFontSize() {
    return headerFontSizeGw(this.props, 1.1);
  }

  leftArrowStyle() {
    const { isHovering } = this.state;
    const shouldShow = isHovering && this.shouldShowLeftArrow();
    return {
      height: `${this.arrowSize()}px`,
      left: shouldShow ? '5px' : '-99999em',
      position: 'absolute',
      top: `${SCROLLBAR_SIZE + this.marginWidth() + this.cardData.cardHeight / 2}px`,
      width: `${this.arrowSize()}px`,
      zIndex: 1,
    };
  }

  leftMarginWidth() {
    return 0.08 * this.galleryViewWidth();
  }

  rightMarginWidth() {
    return 0.09 * this.galleryViewWidth();
  }

  marginWidth() {
    return MARGIN_AS_RATIO_OF_WIDTH * this.galleryViewWidth();
  }

  onClickLeftArrow = () => {
    this.scrollToLeft();
  };

  onClickRightArrow = () => {
    this.scrollToRight();
  };

  onIntersectViewport = (entries) => {
    if (entries[0].intersectionRatio > 0) {
      this.setState({
        hasEnteredViewport: true,
      });
      this._intersectionObserver.disconnect();
    }
  };

  onMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  maxHorizontalScrollDistance() {
    return Math.floor(this.cardsContainerRef.scrollWidth - this.cardsContainerRef.clientWidth);
  }

  onScroll = () => {
    // update currentHorizontalScrollDistance after scrolling stops, which
    // causes a render, so the left/right buttons can show or hide
    this.throttleUpdateHorizontalScrollDistance();
  };

  rightArrowStyle() {
    const { isHovering } = this.state;
    const shouldShow = isHovering && this.shouldShowRightArrow();
    return {
      height: `${this.arrowSize()}px`,
      left: shouldShow ? `${this.galleryViewWidth() - this.arrowSize() - 5}px` : '-99999em',
      position: 'absolute',
      top: `${SCROLLBAR_SIZE + this.marginWidth() + this.cardData.cardHeight / 2}px`,
      width: `${this.arrowSize()}px`,
      zIndex: 1,
    };
  }

  scrollbarClipStyle() {
    return {
      overflow: 'hidden',
      marginTop: `${-SCROLLBAR_SIZE}px`,
    };
  }

  scrollToLeft() {
    this.setHorizontalScrollDistance(
      Math.max(
        0,
        this.state.currentHorizontalScrollDistance - (this.galleryViewWidth() - this.marginWidth()),
      ),
    );
  }

  scrollToRight() {
    this.setHorizontalScrollDistance(
      Math.min(
        this.maxHorizontalScrollDistance(),
        this.state.currentHorizontalScrollDistance + (this.galleryViewWidth() - this.marginWidth()),
      ),
    );
  }

  sectionNameStyle() {
    const { headerFontFamily, initialPaintComplete } = this.props;
    const { hasEnteredViewport } = this.state;

    return {
      fontFamily: headerFontFamily,
      fontSize: `${headerFontSizeGw(this.props, 1.4)}px`,
      letterSpacing: `${headerFontSizeGw(this.props, 0.1, 1)}px`,
      opacity: initialPaintComplete && hasEnteredViewport ? 1 : 0,
    };
  }

  searchInputStyle() {
    const { headerFontFamily, initialPaintComplete } = this.props;
    const { hasEnteredViewport } = this.state;

    return {
      fontFamily: headerFontFamily,
      opacity: initialPaintComplete && hasEnteredViewport ? 1 : 0,
      transition: 'opacity 1s',
      width: Math.min(550, Math.max(250, this.galleryViewWidth() * 0.22)),
    };
  }

  sectionStyle() {
    return {
      display: this.shouldDisplaySection() ? '' : 'none',
      position: 'relative',
    };
  }

  shouldDisplaySection() {
    // Only hide section if actively filtering from search and
    // no video hashedIds in this section match the search results
    // Also, don't hide it if it contains the search input. That would be bad.
    return !this.hasSectionBeenFilteredOutFromSearch() || this.props.shouldShowSearchInSection;
  }

  handleToggleSectionCollapse = () => {
    const { isSectionCollapseToggled } = this.state;
    const {
      section: { numericId: sectionId },
      galleryData: { hashedId },
    } = this.props;

    updateChannelStorage(hashedId, (ls) => {
      ls.collapsednessOfSections = ls.collapsednessOfSections || {};
      ls.collapsednessOfSections[sectionId] = !isSectionCollapseToggled;
    });

    this.setState({
      isSectionCollapseToggled: !isSectionCollapseToggled,
    });
  };

  hasSectionBeenFilteredOutFromSearch() {
    return (
      this.isFilteringFromSearch() &&
      this.cardData.cards.filter(({ video }) =>
        this.props.searchResultMediaIds.includes(video.hashedId),
      ).length === 0
    );
  }

  setHorizontalScrollDistance(distance) {
    if (Element.prototype.scrollTo && detect.safari) {
      this.cardsContainerRef.scrollTo(distance, 0);
    } else if (Element.prototype.scrollTo) {
      this.cardsContainerRef.scrollTo({ left: distance, behavior: 'smooth' });
    } else {
      this.cardsContainerRef.scrollLeft = distance;
    }
    this.setState({
      currentHorizontalScrollDistance: distance,
    });
  }

  setUpFancyRevealAnimation() {
    this._intersectionObserver = new window.IntersectionObserver(this.onIntersectViewport);
    this._intersectionObserver.observe(this.sectionRef);
  }

  setUpAlgoliaSearchClient() {
    const { galleryData } = this.props;
    const { searchApiKey, searchApplicationId } = galleryData;

    dynamicImport('assets/external/channel/initAlgoliaSearchClient.js').then((mod) => {
      const { initAlgoliaSearchClient } = mod;
      this.setState({
        algoliaSearchClient: initAlgoliaSearchClient({
          apiKey: searchApiKey,
          applicationId: searchApplicationId,
        }),
      });
    });
  }

  setInitialCollapsedness() {
    const {
      section: { numericId: sectionId },
      galleryData: { hashedId },
    } = this.props;

    const collapsednessOfSections = getChannelStorage(hashedId).collapsednessOfSections;
    const shouldSectionBeCollapsed = collapsednessOfSections?.[sectionId] || false;
    this.setState({
      isSectionCollapseToggled: shouldSectionBeCollapsed,
    });
  }

  shouldBeScrollable() {
    return this.cardsContainerRef.scrollWidth > this.cardsContainerRef.clientWidth;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return anyValuesChanged(this.props, nextProps) || anyValuesChanged(this.state, nextState);
  }

  shouldShowLeftArrow() {
    const { currentHorizontalScrollDistance, isHovering } = this.state;
    const distanceFromEdge = currentHorizontalScrollDistance;
    return (
      (detect.touchScreen || isHovering) &&
      distanceFromEdge > this.marginWidth() &&
      this.shouldBeScrollable()
    );
  }

  shouldShowRightArrow() {
    const { currentHorizontalScrollDistance, isHovering } = this.state;
    const distanceFromEdge = this.maxHorizontalScrollDistance() - currentHorizontalScrollDistance;
    return (
      (detect.touchScreen || isHovering) &&
      distanceFromEdge > this.marginWidth() &&
      this.shouldBeScrollable()
    );
  }

  sectionHeaderStyle() {
    const searchInputHeight = this.searchInputFontSize() * 2.5;
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `0 ${this.rightMarginWidth()}px 0 ${this.leftMarginWidth()}px`,
      minHeight: `${searchInputHeight}px`,
    };
  }

  sectionNameAndLockIconWrapperStyle() {
    return {
      alignItems: 'center',
      // We need to use display so media is still initialized, otherwise playlists/autoplay will skip filtered media
      display: this.hasSectionBeenFilteredOutFromSearch() ? 'none' : 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    };
  }

  shouldDisplayNoResultsCard() {
    // Only display "no results" in the first section if actively filtering from search and
    // no video hashedIds in the entire channel match the search results
    if (this.isFilteringFromSearch() && this.props.shouldShowSearchInSection) {
      return this.props.searchResultMediaIds.length === 0;
    }
    return false;
  }

  renderCards() {
    return this.cardData.cards.map(({ cardHeight, cardWidth, index, video }, i) => {
      return (
        <div
          style={this.cardWrapperStyle(video.hashedId)}
          ref={
            i === 0
              ? (el) => {
                  this.firstMediaCardDiv = el;
                }
              : null
          }
        >
          <CarouselMediaCard
            {...this.props}
            key={video.hashedId}
            cardsPerRow={this.cardData.cardsPerRow}
            cardHeight={cardHeight}
            cardWidth={cardWidth}
            episodeId={video.episodeNumericId}
            hashedId={video.hashedId}
            index={index}
            name={video.name}
            playerLanguage={'en-US'}
            type={video.type}
          />
        </div>
      );
    });
  }

  renderSearchInput() {
    const { algoliaSearchClient } = this.state;
    if (!algoliaSearchClient) {
      return;
    }

    const { color, backgroundColor, contentTypeLabel, onUpdateMediaFilterFromSearch } = this.props;
    const { searchIndexName } = this.props.galleryData;
    return (
      <SearchProvider
        algoliaSearchClient={algoliaSearchClient}
        algoliaSearchIndexName={searchIndexName}
      >
        <SearchInput
          accentColor={color}
          backgroundColor={backgroundColor}
          contentTypeLabel={contentTypeLabel}
          fontSize={this.searchInputFontSize()}
          onUpdateMediaFilterFromSearch={onUpdateMediaFilterFromSearch}
          customSectionStyle={this.searchInputStyle()}
        />
      </SearchProvider>
    );
  }

  render() {
    const {
      onClickOpenSubscribe,
      subscribeIsRequired,
      section,
      viewerIsSubscribed,
      shouldShowSearchInSection,
      backgroundColor,
    } = this.props;

    const { name } = section;
    const shouldShowHeader = !!section.name || subscribeIsRequired;
    const shouldShowSubscribeButton = subscribeIsRequired && !viewerIsSubscribed;
    const sectionHeaderForegroundColor = backgroundColor === 'ffffff' ? '#000000' : '#ffffff';

    const { isHovering, isSectionCollapseToggled } = this.state;
    this.cardData = calculateCardData(this.props);

    return (
      <div
        class="w-gallery-view__section"
        onMouseEnter={detect.hoverIsNatural ? this.onMouseEnter : null}
        onMouseLeave={detect.hoverIsNatural ? this.onMouseLeave : null}
        ref={(el) => {
          this.sectionRef = el;
        }}
        style={this.sectionStyle()}
      >
        <div style={this.sectionHeaderStyle()}>
          {shouldShowHeader && (
            <div style={this.sectionNameAndLockIconWrapperStyle()}>
              <SectionName
                name={name}
                foregroundColor={sectionHeaderForegroundColor}
                isToggleCollapseEnabled={!this.isFilteringFromSearch()}
                isSectionCollapsed={isSectionCollapseToggled}
                onToggleSectionCollapse={this.handleToggleSectionCollapse}
                customNameStyle={this.sectionNameStyle()}
              />
              {shouldShowSubscribeButton && (
                <SubscribeButton
                  foregroundColor={sectionHeaderForegroundColor}
                  onClickOpenSubscribe={onClickOpenSubscribe}
                />
              )}
            </div>
          )}
          {shouldShowSearchInSection && this.renderSearchInput()}
        </div>
        <div style={this.scrollbarClipStyle()}>
          <div
            class="w-gallery-view__video-cards"
            ref={(el) => {
              this.cardsContainerRef = el;
            }}
            onScroll={this.onScroll}
            style={this.cardsContainerStyle()}
          >
            {this.renderCards()}
            {this.shouldDisplayNoResultsCard() && (
              <NoResultsCard
                backgroundColor={backgroundColor}
                cardWidth={this.cardData.cards[0].cardWidth}
                cardHeight={this.cardData.cards[0].cardHeight}
                fontSize={headerFontSizeGw(this.props, 1.4)}
                totalMediaCardHeight={this.state.totalMediaCardHeight}
              ></NoResultsCard>
            )}
          </div>
        </div>
        <CarouselArrow
          dir="left"
          isVisible={isHovering && this.shouldShowLeftArrow()}
          onClick={this.onClickLeftArrow}
          style={this.leftArrowStyle()}
          tabbable={false}
        />
        <CarouselArrow
          dir="right"
          isVisible={isHovering && this.shouldShowRightArrow()}
          onClick={this.onClickRightArrow}
          style={this.rightArrowStyle()}
          tabbable={false}
        />
      </div>
    );
  }
}

export default CarouselSection;
