const EPISODE = 'episode';
const VIDEO = 'video';
const MEDIA = 'media';
const COURSE = 'course';

export const getChannelContentTypeLabel = (label: string, isPlural = false): string => {
  if (isPlural) {
    switch (label) {
      case EPISODE:
        return 'Episodes';
      case VIDEO:
        return 'Videos';
      case MEDIA:
        return 'Media';
      case COURSE:
        return 'Courses';
      default:
        return 'Episodes';
    }
  }

  switch (label) {
    case EPISODE:
      return 'Episode';
    case VIDEO:
      return 'Video';
    case MEDIA:
      return 'Media';
    case COURSE:
      return 'Course';
    default:
      return 'Episode';
  }
};
