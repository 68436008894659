import { h, render } from 'preact';

const CheckIcon = ({ color }) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 10 7.9" enable-background="new 0 0 10 7.9">
      <path
        fill={color}
        d="M3.7,5.2L1.9,3.4c-0.1-0.1-0.4-0.1-0.5,0L0.9,3.9c-0.1,0.1-0.1,0.4,0,0.5l2.6,2.6c0.1,0.1,0.4,0.1,0.5,0 l5.1-5.1c0.1-0.1,0.1-0.4,0-0.5L8.6,0.9c-0.1-0.1-0.4-0.1-0.5,0L3.7,5.2z"
      />
    </svg>
  );
};

export default CheckIcon;
