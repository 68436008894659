import { VIDEO_CARD_POSTER_ASPECT } from '../galleryMath.js';
import headerFontSizeGw from '../headerFontSizeGw.js';
import memoizedSections from '../memoizedSections.js';

export const MARGIN_AS_RATIO_OF_WIDTH = 0.04;
export const MIN_VIDEO_CARD_WIDTH_IN_PX = 200;

export const galleryViewWidth = (props) => {
  return props.galleryContext.galleryViewWidth;
};

export const marginWidth = (props) => {
  return MARGIN_AS_RATIO_OF_WIDTH * galleryViewWidth(props);
};

export const calculateCardData = (props) => {
  const { section } = props;
  const { videos } = section;

  const mediaTypes = {};
  const isMixedMedia = videos.some((video) => {
    mediaTypes[video.type] = true;
    return Object.keys(mediaTypes).length > 1;
  });
  const primaryMediaType = videos[0].type;
  let cardsPerRow = galleryViewWidth(props) < 1080 ? 3 : 4;
  const isAllAudio = !isMixedMedia && mediaTypes.Audio;
  if (isAllAudio) {
    cardsPerRow += 1;
  }
  const availableWidth = galleryViewWidth(props) - (cardsPerRow + 1) * marginWidth(props);
  const cards = videos.map((video, index) => {
    let cardWidth;
    let cardHeight;
    if (isMixedMedia) {
      // When we have mixed media, figure out what the width and height of
      // this card would be if it was a video. For audio, the width is equal
      // to that height. This kinda breaks cardsPerRow logic, but the
      // cardsPerRow value doesn't make much sense to use for mixed media.
      const videoCardWidth = Math.round(
        Math.max(MIN_VIDEO_CARD_WIDTH_IN_PX, availableWidth / cardsPerRow),
      );
      const videoCardHeight = Math.round(videoCardWidth / VIDEO_CARD_POSTER_ASPECT);
      if (video.type === 'Audio') {
        cardHeight = videoCardHeight;
        cardWidth = videoCardHeight;
      } else {
        cardWidth = videoCardWidth;
        cardHeight = videoCardHeight;
      }
    } else {
      // For video-only or audio-only sections, derive the width from
      // cardsPerRow, then set the height based on the desired aspect ratio.
      // This'll make audio-only sections kinda tall, but that's what we're
      // going for.
      const cardAspect = video.type === 'Video' ? VIDEO_CARD_POSTER_ASPECT : 1;
      cardWidth = Math.round(
        Math.max(MIN_VIDEO_CARD_WIDTH_IN_PX, galleryViewWidth(props) / cardsPerRow),
      );
      cardHeight = Math.round(cardWidth / cardAspect);
    }
    return {
      cardHeight,
      cardWidth,
      index,
      video,
    };
  });

  return {
    cardHeight: cards[0].cardHeight,
    cards,
    cardsPerRow,
    isMixedMedia,
    primaryMediaType,
  };
};

export const firstRowHeight = (props) => {
  const section = memoizedSections(props.galleryData)[0];
  if (!section) {
    return 0;
  }

  const cardData = calculateCardData({ ...props, section });

  const { galleryContext } = props;
  const { firstSectionHasName } = galleryContext;

  const sectionNameHeight = firstSectionHasName
    ? marginWidth(props) + headerFontSizeGw(props, 1.4) * 1.2
    : marginWidth(props);

  return sectionNameHeight + cardData.cardHeight + headerFontSizeGw(props, 1.4) * 1.2 * 3;
};
