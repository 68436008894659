import { h, render, Component } from 'preact';
import { CloseButton } from 'utilities/CloseButton.jsx';
import { RawHTMLStub } from '../shared/RawHTMLStub.jsx';

class GalleryOverlay extends Component {
  render() {
    const { galleryOverlayRef } = this.props;
    return (
      <div class="w-gallery-overlay" style={this.overlayStyle()} ref={galleryOverlayRef}>
        <RawHTMLStub
          class="w-gallery-overlay__content"
          stubRef={this.props.contentRef}
          style={{ height: '100%' }}
        />
        <CloseButton
          class="w-gallery-overlay__close-button"
          color={this.props.foregroundColor}
          style={this.closeButtonStyle()}
          onClickClose={this.onClickClose}
        />
      </div>
    );
  }

  componentDidMount() {
    this.setBodyOverflow(this.props.isVisible);
    this.handleOverlayAnimation({});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible !== this.props.isVisible) {
      this.setBodyOverflow(this.props.isVisible);
    }

    this.handleOverlayAnimation(prevProps);
  }

  componentWillUnmount() {
    clearTimeout(this.animTimeout);
    this.setBodyOverflow(false);
  }

  handleOverlayAnimation(prevProps) {
    if (this.props.isVisible !== prevProps.isVisible) {
      if (this.props.isVisible) {
        this.fadeIn();
      } else {
        this.fadeOut();
      }
    }
  }

  fadeIn() {
    clearTimeout(this.animTimeout);
    this.setState({ isVisible: true }, () => {
      if (!this.props.isVisible) {
        return;
      }
      this.animTimeout = setTimeout(() => {
        if (!this.props.isVisible) {
          return;
        }
        this.setState({ isOpaque: true });
      }, 0);
    });
  }

  fadeOut() {
    clearTimeout(this.animTimeout);
    this.setState({ isOpaque: false }, () => {
      if (this.props.isVisible) {
        return;
      }
      this.animTimeout = setTimeout(() => {
        if (this.props.isVisible) {
          return;
        }
        this.setState({ isVisible: false });
      }, 200);
    });
  }

  overlayStyle() {
    const { zIndex } = this.props;
    const { isVisible, isOpaque } = this.state;
    return {
      background: 'rgba(0,0,0,1)',
      color: '#fff',
      height: '100%',
      left: isVisible ? 0 : '-99999px',
      opacity: isOpaque ? 1 : 0,
      overflowX: 'hidden',
      overflowY: 'auto',
      position: 'fixed',
      top: 0,
      transition: 'opacity 200ms',
      webkitOverflowScrolling: 'touch',
      width: '100%',
      zIndex: zIndex != null ? zIndex : 10000,
    };
  }

  closeButtonStyle() {
    return {
      background: 'transparent',
      border: 0,
      cursor: 'pointer',
      display: this.props.videoIsOpen ? 'none' : 'block',
      height: '20px',
      padding: '2px',
      position: 'absolute',
      right: '30px',
      top: '30px',
      width: '20px',
    };
  }

  setBodyOverflow(isVisible) {
    if (isVisible) {
      this.existingBodyOverflow = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = this.existingBodyOverflow;
    }
  }

  onClickClose = (e) => {
    e.preventDefault();
    if (this.props.onClickClose) {
      this.props.onClickClose();
    }
  };
}

export default GalleryOverlay;
