import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { h, render, Component } from 'preact';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import GridMediaCard from './GridMediaCard.jsx';
import headerFontSizeGw from '../headerFontSizeGw.js';
import { calculateCardData, marginWidth } from './gridMath.js';
import SectionName from '../SectionName.jsx';
import SubscribeButton from '../SubscribeButton.jsx';
import SearchInput from '../SearchInput.jsx';
import { SearchProvider } from '../SearchProvider.jsx';
import NoResultsCard from '../NoResultsCard.jsx';
import { getChannelStorage, updateChannelStorage } from '../channelStorage.js';

class GridSection extends Component {
  state = {
    hasEnteredViewport: false,
    isSectionCollapseToggled: false,
    algoliaSearchClient: undefined,
    totalMediaCardHeight: undefined,
  };

  handleToggleSectionCollapse = () => {
    const { isSectionCollapseToggled } = this.state;
    const {
      section: { numericId: sectionId },
      galleryData: { hashedId },
    } = this.props;

    updateChannelStorage(hashedId, (ls) => {
      ls.collapsednessOfSections = ls.collapsednessOfSections || {};
      ls.collapsednessOfSections[sectionId] = !isSectionCollapseToggled;
    });

    this.setState({
      isSectionCollapseToggled: !isSectionCollapseToggled,
    });
  };

  shouldDisplayMediaFromFilter(hashedId) {
    if (this.isFilteringFromSearch()) {
      return this.props.searchResultMediaIds.includes(hashedId);
    }
    return true;
  }

  shouldHideAllCardsInSection() {
    const { isSectionCollapseToggled } = this.state;
    return isSectionCollapseToggled && !this.isFilteringFromSearch();
  }

  cardWrapperStyle(hashedId) {
    const shouldHideMedia =
      !this.shouldDisplayMediaFromFilter(hashedId) || this.shouldHideAllCardsInSection();
    return {
      display: shouldHideMedia ? 'none' : 'inline-block',
      marginRight: `${marginWidth(this.props)}px`,
      verticalAlign: 'top',
    };
  }

  cardsContainerStyle() {
    return {
      display: 'block',
      paddingLeft: `${marginWidth(this.props)}px`,
    };
  }

  componentDidMount() {
    this.setUpFancyRevealAnimation();
    if (this.props.shouldShowSearch) {
      this.setUpAlgoliaSearchClient();
    }

    this.setInitialCollapsedness();
  }

  componentDidUpdate() {
    // Search has been enabled but algolia client is not yet loaded
    // Load client and then force update to render the search input
    if (!this.state.algoliaSearchClient && this.props.shouldShowSearch) {
      this.setUpAlgoliaSearchClient();
    }

    // Only update if there's a media card ref to read & its height is new and greater than zero
    // Avoids superfluous rerenders and avoids bad updates to this value when the media card is hidden from search (height is zero)
    const shouldUpdateTotalMediaCardHeight =
      this.firstMediaCardDiv &&
      this.firstMediaCardDiv.clientHeight > 0 &&
      this.firstMediaCardDiv.clientHeight !== this.state.totalMediaCardHeight;

    if (shouldUpdateTotalMediaCardHeight) {
      this.setState({ totalMediaCardHeight: this.firstMediaCardDiv.clientHeight });
    }
  }

  galleryViewWidth() {
    return this.props.galleryContext.galleryViewWidth;
  }

  searchInputFontSize() {
    return headerFontSizeGw(this.props, 1.1);
  }

  onIntersectViewport = (entries) => {
    if (entries[0].intersectionRatio > 0) {
      this.setState({
        hasEnteredViewport: true,
      });
      this._intersectionObserver.disconnect();
    }
  };

  rowStyle() {
    return {
      display: 'block',
      marginBottom: `${marginWidth(this.props) / 2}px`,
      whiteSpace: 'nowrap',
    };
  }

  sectionNameStyle() {
    const { headerFontFamily, initialPaintComplete } = this.props;
    const { hasEnteredViewport } = this.state;

    return {
      fontFamily: headerFontFamily,
      fontSize: `${headerFontSizeGw(this.props, 1.4)}px`,
      letterSpacing: `${headerFontSizeGw(this.props, 0.1, 1)}px`,
      opacity: initialPaintComplete && hasEnteredViewport ? 1 : 0,
    };
  }

  searchInputStyle() {
    const { headerFontFamily, initialPaintComplete } = this.props;
    const { hasEnteredViewport } = this.state;

    return {
      fontFamily: headerFontFamily,
      opacity: initialPaintComplete && hasEnteredViewport ? 1 : 0,
      transition: 'opacity 1s',
      width: Math.min(550, Math.max(250, this.galleryViewWidth() * 0.22)),
    };
  }

  sectionStyle() {
    return {
      display: this.shouldDisplaySection() ? '' : 'none',
      position: 'relative',
      paddingTop: `${marginWidth(this.props)}px`,
    };
  }

  shouldDisplaySection() {
    // Only hide section if actively filtering from search and
    // no video hashedIds in this section match the search results
    // Also, don't hide it if it contains the search input. That would be bad.
    return !this.hasSectionBeenFilteredOutFromSearch() || this.props.shouldShowSearchInSection;
  }

  hasSectionBeenFilteredOutFromSearch() {
    return this.isFilteringFromSearch() && this.getFilteredVideos().length === 0;
  }

  setUpFancyRevealAnimation() {
    this._intersectionObserver = new window.IntersectionObserver(this.onIntersectViewport);
    this._intersectionObserver.observe(this.sectionRef);
  }

  setUpAlgoliaSearchClient() {
    const { galleryData } = this.props;
    const { searchApiKey, searchApplicationId } = galleryData;

    dynamicImport('assets/external/channel/initAlgoliaSearchClient.js').then((mod) => {
      const { initAlgoliaSearchClient } = mod;
      this.setState({
        algoliaSearchClient: initAlgoliaSearchClient({
          apiKey: searchApiKey,
          applicationId: searchApplicationId,
        }),
      });
    });
  }

  setInitialCollapsedness() {
    const {
      section: { numericId: sectionId },
      galleryData: { hashedId },
    } = this.props;

    const collapsednessOfSections = getChannelStorage(hashedId).collapsednessOfSections;
    const shouldSectionBeCollapsed = collapsednessOfSections?.[sectionId] || false;
    this.setState({
      isSectionCollapseToggled: shouldSectionBeCollapsed,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return anyValuesChanged(this.props, nextProps) || anyValuesChanged(this.state, nextState);
  }

  sectionHeaderStyle() {
    const searchInputHeight = this.searchInputFontSize() * 2.5;
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `0 ${marginWidth(this.props)}px`,
      marginBottom: '.7em',
      minHeight: `${searchInputHeight}px`,
    };
  }

  sectionNameAndLockIconWrapperStyle() {
    return {
      alignItems: 'center',
      // We need to use display so media is still initialized, otherwise playlists/autoplay will skip filtered media
      display: this.hasSectionBeenFilteredOutFromSearch() ? 'none' : 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    };
  }

  isFilteringFromSearch() {
    return this.props.isFilteringFromSearch && this.props.searchResultMediaIds;
  }

  getFilteredVideos() {
    const { section, searchResultMediaIds } = this.props;
    const { videos } = section;

    return videos.filter(({ hashedId }) => searchResultMediaIds.includes(hashedId));
  }

  getHiddenVideos() {
    const { section, searchResultMediaIds } = this.props;
    const { videos } = section;

    return videos.filter(({ hashedId }) => !searchResultMediaIds.includes(hashedId));
  }

  getFilteredCardData() {
    const { section } = this.props;
    const filteredVideos = this.getFilteredVideos();

    return calculateCardData({
      ...this.props,
      section: {
        ...section,
        videos: filteredVideos,
        videoCount: filteredVideos.length,
      },
    });
  }

  getHiddenCardData() {
    const { section } = this.props;
    const hiddenVideos = this.getHiddenVideos();

    return calculateCardData({
      ...this.props,
      section: {
        ...section,
        videos: hiddenVideos,
        videoCount: hiddenVideos.length,
      },
    });
  }

  shouldDisplayNoResultsCard() {
    // Only display "no results" in the first section if actively filtering from search and
    // no video hashedIds in the entire channel match the search results
    if (this.isFilteringFromSearch() && this.props.shouldShowSearchInSection) {
      return this.props.searchResultMediaIds.length === 0;
    }
    return false;
  }

  renderCards() {
    // If cards have been filtered out, secretly append them
    // (and not display them) so they can still be played via video playlist
    const areCardsHiddenFromSearch =
      this.isFilteringFromSearch() && this.filteredCardData && this.hiddenCardData;

    const allCardRows = areCardsHiddenFromSearch
      ? this.filteredCardData.rows.concat(this.hiddenCardData.rows)
      : this.cardData.rows;

    const cardsPerRow = areCardsHiddenFromSearch
      ? this.filteredCardData.cardsPerRow
      : this.cardData.cardsPerRow;

    return allCardRows.map((cards, i) => {
      return (
        <div
          style={this.rowStyle(cards)}
          ref={
            i === 0
              ? (el) => {
                  this.firstMediaCardDiv = el;
                }
              : null
          }
        >
          {cards.map(({ cardHeight, cardWidth, index, video }) => {
            return (
              <div style={this.cardWrapperStyle(video.hashedId)}>
                <GridMediaCard
                  {...this.props}
                  episodeId={video.episodeNumericId}
                  cardsPerRow={cardsPerRow}
                  cardHeight={cardHeight}
                  cardWidth={cardWidth}
                  hashedId={video.hashedId}
                  index={index}
                  key={`${video.hashedId}_grid_card`}
                  name={video.name}
                  playerLanguage={'en-US'}
                  type={video.type}
                />
              </div>
            );
          })}
        </div>
      );
    });
  }

  renderSearchInput() {
    const { algoliaSearchClient } = this.state;
    if (!algoliaSearchClient) {
      return;
    }

    const { color, backgroundColor, contentTypeLabel, onUpdateMediaFilterFromSearch } = this.props;
    const { searchIndexName } = this.props.galleryData;
    return (
      <SearchProvider
        algoliaSearchClient={algoliaSearchClient}
        algoliaSearchIndexName={searchIndexName}
      >
        <SearchInput
          accentColor={color}
          backgroundColor={backgroundColor}
          contentTypeLabel={contentTypeLabel}
          fontSize={this.searchInputFontSize()}
          onUpdateMediaFilterFromSearch={onUpdateMediaFilterFromSearch}
          customSectionStyle={this.searchInputStyle()}
        />
      </SearchProvider>
    );
  }

  render() {
    const {
      onClickOpenSubscribe,
      subscribeIsRequired,
      section,
      viewerIsSubscribed,
      shouldShowSearchInSection,
      backgroundColor,
    } = this.props;

    const { name } = section;
    const shouldShowHeader = !!section.name || subscribeIsRequired;
    const shouldShowSubscribeButton = subscribeIsRequired && !viewerIsSubscribed;
    const sectionHeaderForegroundColor = backgroundColor === 'ffffff' ? '#000000' : '#ffffff';

    const { isSectionCollapseToggled } = this.state;
    this.cardData = calculateCardData(this.props);

    // We need to calculate card data when filtering from search so cards
    // correctly re-wrap themselves in rows in the grid view
    if (this.isFilteringFromSearch()) {
      this.filteredCardData = this.getFilteredCardData();
      this.hiddenCardData = this.getHiddenCardData();
    }

    return (
      <div
        class="w-gallery-view__section"
        ref={(el) => {
          this.sectionRef = el;
        }}
        style={this.sectionStyle()}
      >
        <div style={this.sectionHeaderStyle()}>
          {shouldShowHeader && (
            <div style={this.sectionNameAndLockIconWrapperStyle()}>
              <SectionName
                name={name}
                foregroundColor={sectionHeaderForegroundColor}
                isToggleCollapseEnabled={!this.isFilteringFromSearch()}
                isSectionCollapsed={isSectionCollapseToggled}
                onToggleSectionCollapse={this.handleToggleSectionCollapse}
                customNameStyle={this.sectionNameStyle()}
              />
              {shouldShowSubscribeButton && (
                <SubscribeButton
                  foregroundColor={sectionHeaderForegroundColor}
                  onClickOpenSubscribe={onClickOpenSubscribe}
                />
              )}
            </div>
          )}
          {shouldShowSearchInSection && this.renderSearchInput()}
        </div>
        <div class="w-gallery-view__video-cards" style={this.cardsContainerStyle()}>
          {this.renderCards()}
          {this.shouldDisplayNoResultsCard() && (
            <NoResultsCard
              backgroundColor={backgroundColor}
              cardWidth={this.cardData.rows[0][0].cardWidth}
              cardHeight={this.cardData.rows[0][0].cardHeight}
              fontSize={headerFontSizeGw(this.props, 1.4)}
              totalMediaCardHeight={this.state.totalMediaCardHeight}
            ></NoResultsCard>
          )}
        </div>
      </div>
    );
  }
}

export default GridSection;
