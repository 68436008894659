import { h, render, Component } from 'preact';
import { DEFAULT_FONT_FAMILY } from '../defaultFont.js';
import NarrowSection from './NarrowSection.jsx';
import { firstRowHeight, marginWidth } from './narrowMath.js';
import memoizedSections from '../memoizedSections.js';
import headerFontSizeGw from '../headerFontSizeGw.js';

class NarrowBody extends Component {
  bodyStyle() {
    return {
      color: '#fff',
      fontFamily: DEFAULT_FONT_FAMILY,
      fontSize: '14px',
      paddingBottom: `${headerFontSizeGw(this.props, 3.2) * 3 * 1.2 * 1.2}px`,
      position: 'relative',
    };
  }

  renderSections() {
    return memoizedSections(this.props.galleryData).map((section, index) => {
      const isFirstSection = index === 0;
      return (
        <NarrowSection
          section={section}
          shouldShowSearchInSection={this.props.shouldShowSearch && isFirstSection}
          {...this.props}
        />
      );
    });
  }

  render() {
    return (
      <div
        class="w-gallery-view__body w-gallery-view__narrow w-css-reset w-css-reset-tree"
        style={this.bodyStyle()}
      >
        {this.renderSections()}
      </div>
    );
  }
}

NarrowBody.firstRowHeight = firstRowHeight;
NarrowBody.marginLeft = marginWidth;
NarrowBody.leftContentOffset = NarrowBody.marginLeft;

export default NarrowBody;
