import { Component } from 'preact';
import { reportError } from 'utilities/sentryUtils.ts';

class GalleryViewErrorBoundary extends Component {
  // If an error occurs anywhere in the GalleryView, catch it here and increment the error metric
  componentDidCatch(error) {
    this.props.countMetric('error'); // Increment the total number of channel errors
    this.props.countMetric('runtime-error', error); // Increment the number of channel runtime errors
    reportError('channel', error);
    throw error; // Re-throw the original error
  }

  render() {
    return this.props.children;
  }
}

export default GalleryViewErrorBoundary;
