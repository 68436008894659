import { h, Component } from 'preact';
import { assign } from 'utilities/assign.js';
import { Color } from 'utilities/color.js';
import { unescapeHtml } from 'utilities/core.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { Wistia } from '../../wistia_namespace.ts';
import {
  maxWidthForHeroContent,
  MOBILE_WIDTH,
  shouldUseNarrowLayout,
  TABLET_WIDTH,
} from './galleryMath.js';
import { GalleryDescription } from './GalleryDescription.jsx';
import GalleryTitle from './GalleryTitle.jsx';
import BigButton from './BigButton.jsx';
import SubscribedBadge from './SubscribedBadge.jsx';
import { channelStorageHelpers } from './channelStorage.js';
import getBodyClass from './getBodyClass.js';
import headerFontSizeGw from './headerFontSizeGw.js';

class GalleryHeroContent extends Component {
  state = {
    isVisible: false,
    hasJustSubscribed: false,
    shouldShowResumableMediaTitle: false,
  };

  componentDidMount() {
    const { on } = this.props;
    on('pageloadcomplete', () => {
      this.maybeEmbedTrailerPopover();
    });

    on('beforesubscribe', () => {
      this.setState({ hasJustSubscribed: true });
    });

    this.fadeIn();
  }

  componentDidUpdate() {
    this.maybeEmbedTrailerPopover();
  }

  componentWillReceiveProps(nextProps) {
    const { trailerId: nextTrailerId } = nextProps;
    const { trailerId } = this.props;
    if (trailerId && !nextTrailerId) {
      Wistia.popover(trailerId).destroy();
    }
  }

  fadeIn() {
    // this timeout seems to help ensure that the Google Font has loaded and
    // kicked in on the channel title and description before we reveal them.
    setTimeout(() => {
      this.setState({
        isVisible: true,
      });
    }, 0);
  }

  getTrailerPopoverEmbedOptions() {
    const { description, getVideoEmbedOptions, headerFontFamily, title, shouldShowTranscript } =
      this.props;
    return assign(
      {
        channelTitle: title,
        container: this.trailerButton,
        description,
        fontFamily: headerFontFamily,
        isTrailer: true,
        resumable: false,
        shouldShowDescription: true,
        shouldShowTitle: true,
        shouldShowTranscript,
      },
      getVideoEmbedOptions(),
    );
  }

  maybeEmbedTrailerPopover() {
    const { trailerId } = this.props;
    if (!trailerId) {
      return;
    }
    dynamicImport('assets/external/popover-v3.js').then(() => {
      if (Wistia.popover(trailerId) && Wistia.popover(trailerId).container === this.trailerButton) {
        // don't initialize the trailer popover if it was already initialized
        return;
      }
      Wistia.embedPopover(trailerId, this.getTrailerPopoverEmbedOptions());
    });
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.getIsTweakModeEnabled && nextProps.getIsTweakModeEnabled()) {
      return false;
    }
  }

  shouldShowTitle() {
    // Never show both a logo and a title. If a logo exists, it wins.
    return this.props.title && !this.props.logoEnabled;
  }

  shouldShowSubscribeButton() {
    return (
      this.props.subscribeEnabled && !this.hasSubscribedBefore() && !this.state.hasJustSubscribed
    );
  }

  shouldShowSubscribedBadge() {
    return (
      (this.props.subscribeEnabled && this.hasSubscribedBefore()) || this.state.hasJustSubscribed
    );
  }

  bigButtonsContainerStyle() {
    const { heroAlignment, initialPaintComplete, galleryContext } = this.props;
    const { isVisible } = this.state;
    return {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: heroAlignment === 'left' ? 'left' : 'center',
      marginTop: '1rem',
      width: shouldUseNarrowLayout(galleryContext) ? '100%' : '',
      opacity: initialPaintComplete && isVisible ? 1 : 0,
      transition: 'opacity 1000ms 400ms',
    };
  }

  heroContentWrapperStyle() {
    const { contentOffsetTop, videoCardsLayout, galleryContext, heroAlignment } = this.props;
    const isListView = videoCardsLayout === 'list' && !shouldUseNarrowLayout(galleryContext);
    const alignItems = heroAlignment === 'left' ? 'flex-start' : 'center';

    if (isListView) {
      return {
        alignItems,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: `${contentOffsetTop || 0}px`,
        maxWidth: '808px',
        paddingLeft: '16px',
        paddingRight: '16px',
        position: 'relative',
        width: '100%',
      };
    }

    const { galleryViewWidth } = galleryContext;
    let minHeight = '70vh';

    if (galleryViewWidth < TABLET_WIDTH) {
      minHeight = '50vh';
    }

    if (galleryViewWidth < MOBILE_WIDTH) {
      minHeight = '40vh';
    }

    return {
      alignItems,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight,
      position: 'relative',
      width: '100%',
    };
  }

  scrollDummyStyle() {
    return {
      height: '1px',
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      top: `${-(this.props.pageFixedHeaderHeight || 0)}px`,
      width: '100%',
    };
  }

  heroContentStyle() {
    const { galleryContext, heroAlignment } = this.props;
    return {
      alignItems: heroAlignment === 'left' ? 'flex-start' : 'center',
      display: 'flex',
      flexDirection: 'column',
      margin: this.marginForHeroContent(),
      maxWidth: maxWidthForHeroContent(galleryContext),
      textAlign: heroAlignment === 'left' ? 'left' : 'center',
    };
  }

  marginForHeroContent() {
    const { galleryContext, heroAlignment, videoCardsLayout } = this.props;
    const isListView = videoCardsLayout === 'list';
    const BodyClass = getBodyClass(this.props);
    if (shouldUseNarrowLayout(galleryContext)) {
      return `60px ${BodyClass.marginLeft(this.props)}px 0`;
    }
    if (isListView) {
      return `120px ${BodyClass.marginLeft(this.props)}px 48px`;
    }
    if (heroAlignment === 'left') {
      return `48px ${BodyClass.marginLeft(this.props)}px`;
    }
    return '0';
  }

  hasSubscribedBefore() {
    return Boolean(
      channelStorageHelpers.getChannelStorage(this.props.galleryData.hashedId).subscribeInfo,
    );
  }

  resumableMediaTitleStyle() {
    const { shouldShowResumableMediaTitle } = this.state;
    const { backgroundColor, foregroundColor, galleryContext, heroAlignment } = this.props;
    const textShadowStyle = `1px 1px 3px ${new Color(backgroundColor).alpha(0.8).toRgba()}`;
    return {
      color: `#${foregroundColor}`,
      fontSize: `${headerFontSizeGw(this.props, 1.4)}px`,
      lineHeight: '1.5em',
      opacity: shouldShowResumableMediaTitle ? 1 : 0,
      textShadow:
        heroAlignment === 'left' || shouldUseNarrowLayout(galleryContext) ? textShadowStyle : '',
      transform: shouldShowResumableMediaTitle ? 'scale(1.1)' : 'scale(1.001)',
      transformOrigin: heroAlignment === 'left' ? '5em top' : 'center top',
      transition:
        'opacity 200ms cubic-bezier(0.15, 0.51, 0.25, 1), transform 200ms cubic-bezier(0.15, 0.51, 0.25, 1)',
    };
  }

  onMouseEnterResumeButton = () => {
    this.setState({ shouldShowResumableMediaTitle: true });
  };

  onMouseLeaveResumeButton = () => {
    this.setState({ shouldShowResumableMediaTitle: false });
  };

  onFocusResumeButton = () => {
    this.setState({ shouldShowResumableMediaTitle: true });
  };

  onBlurResumeButton = () => {
    this.setState({ shouldShowResumableMediaTitle: false });
  };

  descriptionContainerStyle() {
    const { isVisible } = this.state;
    return {
      opacity: isVisible ? 1 : 0,
      transition: 'opacity 1000ms 200ms',
    };
  }

  logoOrTitleContainerStyle() {
    const { isVisible } = this.state;
    return {
      opacity: isVisible ? 1 : 0,
      transition: 'opacity 1000ms',
    };
  }

  logoMaxWidth() {
    const { galleryContext, logoSizeMultiplier } = this.props;
    const { videoCardsLayout } = galleryContext;

    const maxWidthAsPercent = 100 * logoSizeMultiplier;
    if (shouldUseNarrowLayout(galleryContext) || videoCardsLayout === 'list') {
      // We allow for logoSizeMultiplier of up to 150.
      // On mobile that potential overflow goes off screen so
      // let's keep it >= 100% maxWidth on mobile and list layout.
      return Math.min(maxWidthAsPercent, 100);
    }

    return maxWidthAsPercent;
  }

  renderGalleryDescription() {
    const {
      description,
      backgroundColor,
      foregroundColor,
      galleryContext,
      headerFontFamily,
      headerFontSizeMultiplier,
      heroAlignment,
      videoCardsLayout,
    } = this.props;
    return (
      <div style={this.descriptionContainerStyle()}>
        <GalleryDescription
          description={description}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          galleryContext={galleryContext}
          headerFontFamily={headerFontFamily}
          headerFontSizeMultiplier={headerFontSizeMultiplier}
          heroAlignment={heroAlignment}
          videoCardsLayout={videoCardsLayout}
        />
      </div>
    );
  }

  render() {
    const {
      backgroundColor,
      color,
      description,
      disableStartWatching,
      foregroundColor,
      galleryContext,
      galleryEmbedOptions,
      galleryHeroContentRef,
      headerFontFamily,
      headerFontSizeMultiplier,
      heroAlignment,
      logoEnabled,
      logoSizeMultiplier,
      logoUrl,
      on,
      onClickOpenSubscribe,
      onClickResume,
      onClickStartWatching,
      onClickWatchTrailer,
      resumableMediaTitle,
      shouldShowResume,
      title,
      trailerId,
      videoCardsLayout,
    } = this.props;

    return (
      <div
        class="w-gallery-view__hero-content-wrapper w-css-reset"
        ref={galleryHeroContentRef}
        style={this.heroContentWrapperStyle()}
      >
        <div style={this.scrollDummyStyle()} />
        <div class="w-gallery-view__hero-content w-css-reset" style={this.heroContentStyle()}>
          <div style={this.logoOrTitleContainerStyle()}>
            {this.shouldShowTitle() && (
              <GalleryTitle
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
                galleryContext={galleryContext}
                headerFontFamily={headerFontFamily}
                headerFontSizeMultiplier={headerFontSizeMultiplier}
                title={title}
                heroAlignment={heroAlignment}
                videoCardsLayout={videoCardsLayout}
              />
            )}
            {logoEnabled && (
              <h1 class="w-css-reset w-css-reset-tree">
                <img
                  src={logoUrl}
                  alt={title}
                  style={{
                    maxWidth: `${this.logoMaxWidth()}%`,
                    maxHeight: `${250 * logoSizeMultiplier}px`,
                    height: 'auto',
                    width: 'auto',
                  }}
                />
              </h1>
            )}
          </div>
          {/* The description goes below the buttons in the narrow layout */}
          {description && !shouldUseNarrowLayout(galleryContext) && this.renderGalleryDescription()}
          <div style={this.bigButtonsContainerStyle()}>
            {!disableStartWatching && !shouldShowResume && (
              <BigButton
                alignment={heroAlignment}
                color={color}
                galleryContext={galleryContext}
                headerFontSizeMultiplier={headerFontSizeMultiplier}
                newRoundedIcons={galleryEmbedOptions.newRoundedIcons}
                on={on}
                onClickButton={() => onClickStartWatching(this.watchButton)}
                setButtonRef={(elem) => (this.watchButton = elem)}
              >
                Play
              </BigButton>
            )}
            {shouldShowResume && (
              <BigButton
                alignment={heroAlignment}
                color={color}
                galleryContext={galleryContext}
                headerFontSizeMultiplier={headerFontSizeMultiplier}
                newRoundedIcons={galleryEmbedOptions.newRoundedIcons}
                on={on}
                onClickButton={() => onClickResume(this.resumeButton)}
                onMouseEnterButton={this.onMouseEnterResumeButton}
                onMouseLeaveButton={this.onMouseLeaveResumeButton}
                onFocusButton={this.onFocusResumeButton}
                onBlurButton={this.onBlurResumeButton}
                setButtonRef={(elem) => (this.resumeButton = elem)}
              >
                Resume
              </BigButton>
            )}
            {trailerId && (
              <BigButton
                alignment={heroAlignment}
                buttonClass={'wistia_popover'}
                color={shouldShowResume ? '#333333' : color}
                galleryContext={galleryContext}
                headerFontSizeMultiplier={headerFontSizeMultiplier}
                newRoundedIcons={galleryEmbedOptions.newRoundedIcons}
                on={on}
                onClickButton={onClickWatchTrailer}
                setButtonRef={(elem) => (this.trailerButton = elem)}
              >
                Play trailer
              </BigButton>
            )}
            {this.shouldShowSubscribeButton() && (
              <BigButton
                alignment={heroAlignment}
                color={'#333333'}
                galleryContext={galleryContext}
                headerFontSizeMultiplier={headerFontSizeMultiplier}
                newRoundedIcons={galleryEmbedOptions.newRoundedIcons}
                onClickButton={onClickOpenSubscribe}
                removePlayButton={true}
              >
                Subscribe for updates
              </BigButton>
            )}
            {this.shouldShowSubscribedBadge() && (
              <SubscribedBadge
                alignment={heroAlignment}
                color={color}
                hasJustSubscribed={this.state.hasJustSubscribed}
                galleryContext={galleryContext}
                headerFontFamily={headerFontFamily}
                headerFontSizeMultiplier={headerFontSizeMultiplier}
                removePlayButton={true}
              >
                Subscribed
              </SubscribedBadge>
            )}
          </div>
          {/* Instead of pivoting on shouldShowResumableMediaTitle to determine if we
            should render the following markup at all, we instead use that only
            for transitioning the text's opacity. If a resumableMediaTitle does not
            exist, we render a nbsp instead. This avoids a little jitter as we
            make the request to get the last video's title. */}
          {!shouldUseNarrowLayout(galleryContext) && (
            <p style={this.resumableMediaTitleStyle()}>
              {resumableMediaTitle ? unescapeHtml(resumableMediaTitle) : <span>&nbsp;</span>}
            </p>
          )}
          {description && shouldUseNarrowLayout(galleryContext) && this.renderGalleryDescription()}
        </div>
      </div>
    );
  }
}

export default GalleryHeroContent;
