import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';
import { unescapeHtml } from 'utilities/core.js';
import { CaretDownIcon } from '../shared/CaretDownIcon.jsx';

/**
 * Label for divided out sections of media. Controls collapsing and re-opening of sections.
 *
 * @param {string | undefined} name - user input name of the section, can be undefined if no name has been set
 * @param {string} foregroundColor - either #ffffff or #000000 depending on background color
 * @param {boolean} isToggleCollapseEnabled - determines whether caret icon is displayed and if elememt is interactive
 * @param {boolean} isSectionCollapsed - determines state of the caret icon & accessible label
 * @param {function} onToggleSectionCollapse - called on interaction with the section name, sets the collapse state of the section
 * @param {object} customNameStyle - any custom name styles for each layout type, added to the styles in sectionNameStyle()
 * @returns {JSX.Element}
 */
function SectionName({
  name,
  foregroundColor,
  isToggleCollapseEnabled,
  isSectionCollapsed,
  onToggleSectionCollapse,
  customNameStyle,
}) {
  const [isCollapseButtonFocused, setIsCollapseButtonFocused] = useState(false);

  const toggleCollapseButtonStyle = () => {
    return {
      display: 'flex',
      alignItems: 'center',
    };
  };

  const caretIconStyle = () => {
    return {
      boxShadow: isCollapseButtonFocused ? `0 0 0 2px ${foregroundColor}` : 'none',
      cursor: 'pointer',
      marginRight: '8px',
      height: '16px',
      width: '16px',
      transform: isSectionCollapsed ? 'rotate(-90deg)' : 'rotate(0)',
      transition: 'transform 0.25s',
    };
  };

  const sectionNameStyle = () => {
    return {
      boxSizing: 'border-box',
      color: foregroundColor,
      cursor: isToggleCollapseEnabled ? 'pointer' : 'inherit',
      fontWeight: 400,
      margin: '0 8px 0 0',
      position: 'relative',
      textTransform: 'uppercase',
      transition: 'opacity 1s',
      ...customNameStyle,
    };
  };

  const renderInteractiveSectionName = () => {
    return (
      <button
        type="button"
        aria-label={isSectionCollapsed ? 'Show section' : 'Hide section'}
        style={toggleCollapseButtonStyle()}
        onBlur={() => setIsCollapseButtonFocused(false)}
        onFocus={() => setIsCollapseButtonFocused(true)}
        onClick={() => {
          onToggleSectionCollapse();
        }}
      >
        <div style={caretIconStyle()}>
          <CaretDownIcon color={foregroundColor}></CaretDownIcon>
        </div>
        {name && <h2 style={sectionNameStyle()}>{unescapeHtml(name, { cache: true })}</h2>}
      </button>
    );
  };

  const renderStaticSectionName = () => {
    return (
      <Fragment>
        {name && <h2 style={sectionNameStyle()}>{unescapeHtml(name, { cache: true })}</h2>}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isToggleCollapseEnabled ? renderInteractiveSectionName() : renderStaticSectionName()}
    </Fragment>
  );
}

export default SectionName;
