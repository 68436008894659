import { h } from 'preact';
import { QuestionMarkIcon } from '../shared/QuestionMarkIcon.jsx';

/**
 * Displays "no results" in a section which has been completely filtered out from search, list view only
 *
 * @param {string} backgroundColor - hex code usually either #ffffff or #000000, but could be set to any hex via embed options
 * @param {number} fontSize - scaled size of the text within the card
 * @param {number} totalMediaRowHeight - clientHeight of a video row grabbed after it has rendered - we need this to keep a consistent bottom margin
 * @returns {JSX.Element}
 */
function NoResultsRow({ backgroundColor, fontSize, totalMediaRowHeight }) {
  // Light style if foreground color is set to white, otherwise assume dark style for any other color
  const isDarkStyle = backgroundColor !== 'ffffff';

  const rowContainerStyle = () => {
    const rowHeight = totalMediaRowHeight ? totalMediaRowHeight - 32 : 0;
    return {
      boxSixing: 'content-box',
      mozBoxSizint: 'content-box',
      webkitBoxSizing: 'content-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: `${fontSize}px`,
      padding: '16px',
      width: '100%',
      height: `${rowHeight}px`,
    };
  };

  const fakeThumbnailStyle = () => {
    return {
      backgroundColor: isDarkStyle ? '#1f1f22' : '#dedee1',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '32px',
      width: '128px',
      height: '100%',
    };
  };

  const questionMarkIconStyle = () => {
    return {
      width: `20px`,
    };
  };

  return (
    <div style={rowContainerStyle()}>
      <div style={fakeThumbnailStyle()}>
        <div style={questionMarkIconStyle()}>
          <QuestionMarkIcon color={isDarkStyle ? '#7b7b87' : '#4a4a51'} />
        </div>
      </div>
      No Results
    </div>
  );
}

export default NoResultsRow;
