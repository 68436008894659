import { VIDEO_CARD_POSTER_ASPECT } from '../galleryMath.js';
import headerFontSizeGw from '../headerFontSizeGw.js';
import memoizedSections from '../memoizedSections.js';

export const MARGIN_AS_RATIO_OF_WIDTH = 0.08;
export const LIST_MOBILE_MARGIN = 16;

const galleryViewWidth = (props) => {
  return props.galleryContext.galleryViewWidth;
};

export const listAvailableWidth = (props) => {
  return galleryViewWidth(props) - LIST_MOBILE_MARGIN * 2;
};

export const marginWidth = (props) => {
  return MARGIN_AS_RATIO_OF_WIDTH * galleryViewWidth(props);
};

export const availableWidth = (props) => {
  return galleryViewWidth(props) - marginWidth(props) * 2;
};

export const calculateCardData = (props) => {
  const { section } = props;
  const { videos } = section;

  const rows = [];
  videos.forEach((video, index) => {
    let cardWidth;
    let cardHeight;
    const cardAspect = video.type === 'Video' ? VIDEO_CARD_POSTER_ASPECT : 1;
    cardWidth = availableWidth(props);
    cardHeight = cardWidth / cardAspect;
    rows.push([
      {
        cardHeight,
        cardWidth,
        index,
        video,
      },
    ]);
  });

  return {
    cardsPerRow: 1,
    rows,
  };
};

export const firstRowHeight = (props) => {
  const section = memoizedSections(props.galleryData)[0];
  if (!section) {
    return 0;
  }
  const { galleryContext } = props;
  const { firstSectionHasName } = galleryContext;
  const cardData = calculateCardData({ ...props, section });
  const sectionNameHeight = firstSectionHasName
    ? headerFontSizeGw(props, 4) * 1.2 + headerFontSizeGw(props, 4 * 0.7)
    : 0;
  const cardHeight = cardData.rows[0][0].cardHeight;
  const nameHeight = headerFontSizeGw(props, 1.4) * 1.2 * 3;
  return sectionNameHeight + cardHeight + nameHeight;
};
