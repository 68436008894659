import { h, Component } from 'preact';
import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { Url } from 'utilities/url.js';
import { eV1HostWithPort } from 'utilities/hosts.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { Wistia } from '../../wistia_namespace.ts';
import { ProgressiveThumbnail } from '../shared/ProgressiveThumbnail.jsx';
import { RawHTMLStub } from '../shared/RawHTMLStub.jsx';
import { MAX_GALLERY_HEIGHT, shouldUseNarrowLayout } from './galleryMath.js';

const HERO_IMAGE_WIDTHS = [320, 640, 960, 1280, 1920];
const DEFAULT_ASPECT_RATIO = 16 / 9;
const DEFAULT_HERO_IMAGE_URL = `https://${eV1HostWithPort()}/assets/images/blank.gif`;

class GalleryHeroBackground extends Component {
  state = {
    readyToShow: false,
  };

  embedPoster() {
    const { galleryEmbedOptions, videoBackgroundHashedId } = this.props;
    if (!videoBackgroundHashedId) {
      return;
    }

    this._posterPromise = dynamicImport('assets/external/poster.js')
      .then(() => {
        return Wistia.embedPoster(videoBackgroundHashedId, {
          autoPlay: true,
          container: this._posterRef,
          deliveryCdn: galleryEmbedOptions.deliveryCdn,
          embedHost: galleryEmbedOptions.embedHost,
          fadeInDelay: 1000,
          fadeInTime: 1000,
          fitStrategy: 'cover',
          monitor: false,
          newRoundedIcons: galleryEmbedOptions.newRoundedIcons,
          playButton: false,
          progressIndicator: false,
          showDuration: false,
          thumbnailFitStrategy: 'cover',
          translateTime: 0,
          videoFeature: true,
          videoQualityMax: 1080,
          videoQualityMin: 360,
        });
      })
      .then((p) => {
        this._poster = p;
        this._poster.on('embedded', () => {
          this.onBackgroundPosterEmbedded(this._poster);
        });
      });
  }

  componentDidMount() {
    this.embedPoster();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.videoBackgroundHashedId !== this.props.videoBackgroundHashedId &&
      this.props.videoBackgroundHashedId
    ) {
      this._poster?.destroy();
      this.embedPoster();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.getIsTweakModeEnabled && nextProps.getIsTweakModeEnabled()) {
      return false;
    }

    return anyValuesChanged(this.props, nextProps) || anyValuesChanged(this.state, nextState);
  }

  getImages() {
    const { heroImageUrl, heroImageAspectRatio } = this.props;
    const memoKey = `${heroImageUrl} ${heroImageAspectRatio}`;
    if (this._imagesMemo !== memoKey) {
      this._imagesMemo = memoKey;
      this._images = undefined;
    }
    if (this._images) {
      return this._images;
    }
    if (!heroImageUrl) {
      return [
        {
          url: DEFAULT_HERO_IMAGE_URL,
          width: 0,
          height: 0,
        },
      ];
    }
    const stillAspect = heroImageAspectRatio || DEFAULT_ASPECT_RATIO;
    this._images = HERO_IMAGE_WIDTHS.map((width) => {
      const height = Math.round(width / stillAspect);
      const url = new Url(heroImageUrl);
      url.ext('jpg');
      url.params.image_crop_resized = `${width}x${height}`;
      return {
        url: url.absolute(),
        width,
        height,
      };
    });
    return this._images;
  }

  onDisplay = () => {
    this.setState({ readyToShow: true });
    if (this.props.onDisplay) {
      this.props.onDisplay();
    }
  };

  onBackgroundPosterEmbedded = (poster) => {
    this.setState({ readyToShow: true });

    if (this.props.onBackgroundPosterEmbedded) {
      this.props.onBackgroundPosterEmbedded(poster);
    }
    if (this.props.onDisplay) {
      this.props.onDisplay();
    }
  };

  cardStyle() {
    const { galleryContext, galleryHeroContentElem, isPopoverCardBackground } = this.props;
    const isListView =
      galleryContext?.videoCardsLayout === 'list' && !shouldUseNarrowLayout(galleryContext);

    const nonListViewCardHeight = isPopoverCardBackground ? '100%' : '100vh';
    const cardHeight = isListView ? galleryHeroContentElem?.clientHeight : nonListViewCardHeight;

    return {
      alignItems: 'center',
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      height: cardHeight,
      justifyContent: 'center',
      maxHeight: `${MAX_GALLERY_HEIGHT}px`,
      opacity: this.state.readyToShow ? 1 : 0,
      overflow: 'hidden',
      position: 'absolute',
      resize: 'both',
      transition: `opacity ${this.props.fadeInTime || 2000}ms`,
      width: '100%',
    };
  }

  progressiveThumbnailWrapperStyle() {
    return {
      height: '100%',
      left: 0,
      position: this.props.imageFitStrategy === 'naturalHeight' ? 'relative' : 'absolute',
      top: 0,
      width: '100%',
    };
  }

  videoBackgroundStyle() {
    return {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    };
  }

  overlayStyle() {
    const { backgroundColor } = this.props;
    return {
      backgroundColor: backgroundColor === '000000' ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.5)',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
  }

  render() {
    return (
      <div class="w-hero-background w-css-reset w-css-reset-tree" style={this.cardStyle()}>
        {this.props.videoBackgroundHashedId && (
          <RawHTMLStub
            class="wistia_poster"
            stubRef={(el) => (this._posterRef = el)}
            style={this.videoBackgroundStyle()}
          />
        )}
        {!this.props.videoBackgroundHashedId && (
          <div style={this.progressiveThumbnailWrapperStyle()}>
            <ProgressiveThumbnail
              images={this.getImages()}
              isVisible={true}
              onDisplay={this.onDisplay}
              hashedId={'xnr2mwtzck'}
              swatchEnabled={false}
              uiHasRendered={true}
              fitStrategy={this.props.imageFitStrategy || 'cover'}
            />
            {this.props.videoCardsLayout === 'list' &&
              !shouldUseNarrowLayout(this.props.galleryContext) && (
                <div style={this.overlayStyle()}></div>
              )}
          </div>
        )}
      </div>
    );
  }
}

export default GalleryHeroBackground;
