import { Fragment, h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { getChannelContentTypeLabel } from '../../utilities/channelContentTypeLabel.ts';
import { useSearch } from './SearchProvider.jsx';
import { CloseIcon } from '../shared/CloseIcon.jsx';
import { SearchIcon } from '../shared/SearchIcon.jsx';

/**
 * Input to search episodes within a channel
 *
 * @param {string} accentColor - color used for button and player, set by the user within channel styles
 * @param {string} backgroundColor - hex code usually either #ffffff or #000000, but could be set to any hex via embed options
 * @param {string} contentTypeLabel - label for the type of content being searched
 * @param {number} fontSize - scaled size of the text within the search input, will also affect icon size
 * @param {boolean} [isFullWidth=false] - whether the search bar should take up as much horizontal space as it can
 * @param {function} onUpdateMediaFilterFromSearch - called when a result is returned from a search
 * @param {object} customSectionStyle - any custom styles for each layout type, added to the styles in searchBoxContainerStyle()
 * @returns {JSX.Element}
 */
function SearchInput({
  accentColor,
  backgroundColor,
  contentTypeLabel,
  fontSize,
  isFullWidth = false,
  onUpdateMediaFilterFromSearch,
  customSectionStyle,
}) {
  const { search, cancelPendingSearch } = useSearch();

  const searchInputElement = useRef(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchResultTotal, setSearchResultTotal] = useState(0);

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isClearSearchButtonFocused, setIsClearSearchButtonFocused] = useState(false);

  // Light style if foreground color is set to white, otherwise assume dark style for any other color
  const isDarkStyle = backgroundColor !== 'ffffff';
  const searchInputForegroundColor = isDarkStyle ? 'ffffff' : '000000';

  const iconSize = fontSize * 0.75;

  const searchBoxContainerStyle = () => {
    const backgroundColor = isDarkStyle ? '000000' : 'ffffff';
    const blurBorderColor = isDarkStyle ? '1f1f22' : 'ebebed';
    const topBottomPadding = fontSize * 0.5;
    return {
      backgroundColor: `#${backgroundColor}E6`, // 90% opacity
      border: `1px solid ${
        isInputFocused || isClearSearchButtonFocused
          ? `#${searchInputForegroundColor}`
          : `#${blurBorderColor}`
      }`,
      borderRadius: '8px',
      display: 'inline-flex',
      alignItems: 'baseline',
      fontSize: `${fontSize}px`,
      outline: 'none',
      padding: `${topBottomPadding}px ${fontSize}px`,
      position: 'relative',
      // Make sure the search input stays stuck to the left
      // if there's nothing else in the header (name, etc)
      marginLeft: isFullWidth ? '' : 'auto',
      marginBottom: isFullWidth ? '24px' : '',
      ...customSectionStyle,
    };
  };

  // From https://css-tricks.com/inclusively-hidden/
  const visuallyHiddenStyle = () => {
    return {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    };
  };

  const searchIconStyle = () => {
    return {
      display: 'flex',
      alignSelf: 'baseline',
      flexShrink: '0',
      marginRight: '8px',
      width: `${iconSize}px`,
    };
  };

  const searchInputStyle = () => {
    return {
      border: 'none',
      backgroundColor: 'transparent',
      color: `#${searchInputForegroundColor}`,
      fontFamily: 'inherit',
      fontSize: 'inherit',
      outline: 'none',
      width: '100%',
    };
  };

  const resultTotalStyle = () => {
    // Doesn't feel great but we need this to properly
    // scale along with font size
    const resultTotalFontSize = fontSize * 0.75;
    const resultTotalPadding = fontSize * 0.5;
    return {
      backgroundColor: `#${accentColor}`,
      borderRadius: '30px',
      fontWeight: 400,
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      flexShrink: '0',
      fontSize: `${resultTotalFontSize}px`,
      padding: `2px ${resultTotalPadding}px`,
      marginLeft: '8px',
    };
  };

  const closeIconStyle = () => {
    return {
      cursor: 'pointer',
      display: 'flex',
      alignSelf: 'baseline',
      flexShrink: '0',
      marginLeft: '8px',
      width: `${iconSize}px`,
      boxShadow: isClearSearchButtonFocused ? `0 0 0 2px #${searchInputForegroundColor}` : 'none',
    };
  };

  // On unmount, clear search and stop filtering in gallery data
  useEffect(() => {
    return () =>
      onUpdateMediaFilterFromSearch({
        isFilteringFromSearch: false,
        searchResultMediaIds: [],
        shouldRerender: false,
      });
  }, []);

  const handleSearchInputUpdate = async (e) => {
    const { value: query } = e.target;
    cancelPendingSearch();
    setSearchInputValue(query);

    if (query.length <= 0) {
      handleEmptySearchQuery();
      return;
    }

    const { hits, nbHits } = await search(query, { analyticsTags: ['Channels Search'] });
    setSearchResultTotal(nbHits);
    const searchResultMediaIds = hits.map(({ mediaHashedId }) => mediaHashedId);
    onUpdateMediaFilterFromSearch({ isFilteringFromSearch: true, searchResultMediaIds });
  };

  const handleEmptySearchQuery = () => {
    cancelPendingSearch();
    setSearchResultTotal(0);
    onUpdateMediaFilterFromSearch({ isFilteringFromSearch: false, searchResultMediaIds: [] });
  };

  const searchContentTypeLabel = getChannelContentTypeLabel(contentTypeLabel, true).toLowerCase();
  const searchPlaceholder = `Search ${searchContentTypeLabel}`;

  return (
    <form role="search" style={searchBoxContainerStyle()} onSubmit={(e) => e.preventDefault()}>
      {/* Visually hidden label */}
      <label htmlFor="search-input" style={visuallyHiddenStyle()}>
        Search episodes
      </label>
      {/* Search icon */}
      <div style={searchIconStyle()}>
        <SearchIcon color={`#${searchInputForegroundColor}`} />
      </div>
      {/* oof */}
      {/* We need this to hide the browser's default search icon and cancel button */}
      <style
        dangerouslySetInnerHTML={{
          __html: `.w-channel-episodes--search-input::-webkit-search-decoration {
                    display: none;
                  }
                  .w-channel-episodes--search-input::-webkit-search-cancel-button {
                    display: none;
                  }`,
        }}
      ></style>
      {/* Actual search input */}
      <input
        id="search-input"
        ref={searchInputElement}
        type="search"
        class="w-channel-episodes--search-input"
        style={searchInputStyle()}
        placeholder={isInputFocused ? ' ' : searchPlaceholder}
        onBlur={() => setIsInputFocused(false)}
        onFocus={() => setIsInputFocused(true)}
        onInput={(e) => handleSearchInputUpdate(e)}
        value={searchInputValue}
      />
      {searchInputValue.length > 0 && (
        <Fragment>
          {/* Result total indicator */}
          <div role="status" style={resultTotalStyle()}>
            {searchResultTotal}
            <span style={visuallyHiddenStyle()}>
              {`result${searchResultTotal === 1 ? '' : 's'} returned`}
            </span>
          </div>
          {/* Clear search button */}
          <button
            type="button"
            aria-label="Clear search input"
            style={closeIconStyle()}
            onBlur={() => setIsClearSearchButtonFocused(false)}
            onFocus={() => setIsClearSearchButtonFocused(true)}
            onClick={() => {
              setSearchInputValue('');
              handleEmptySearchQuery();
              searchInputElement.current.focus();
            }}
          >
            <CloseIcon color={`#${searchInputForegroundColor}`} />
          </button>
        </Fragment>
      )}
    </form>
  );
}

export default SearchInput;
