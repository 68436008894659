import { h, render } from 'preact';
import { standardSvgAttrs } from 'utilities/svg_boilerplate.ts';

export const LockIcon = ({ color }) => {
  return (
    <svg {...standardSvgAttrs({ width: 24, height: 24, ariaHidden: true })}>
      <g stroke="none" strokeWidth="1" fill={`${color}` || '#fff'} fillRule="evenodd">
        <path d="m12.6862 0c4.34 0 6.9552 3.4605 7.5138 7.86 1.5806.6465 2.8 2.223 2.8 4.14v7.5c0 2.481-.4844 4.5-2.8 4.5h-14c-2.3142 0-4.2-2.019-4.2-4.5v-7.5c0-1.917 1.1312-3.546 2.7104-4.194.56-4.398 2.4178-7.806 7.9758-7.806zm6.1138 10.5h-12.6c-.7728 0-1.4.672-1.4 1.5v7.5c0 .828.6272 1.5 1.4 1.5h12.6c.7742 0 1.4-.672 1.4-1.5v-7.5c0-.828-.6258-1.5-1.4-1.5zm-6.5434 1.5c1.5232 0 2.7566 1.2403125 2.7566 2.7689062 0 .9042188-.4382 1.7001563-1.1046 2.205-.0098.91125-.7434 1.6467188-1.652 1.6467188-.9072 0-1.6422-.7354688-1.6492-1.6467188-.6678-.5048437-1.1074-1.3007812-1.1074-2.205 0-1.5285937 1.2348-2.7689062 2.7566-2.7689062zm.5934-9.0015c-3.5868-.093-4.697 2.217-5.341 4.7985h9.9722c-.6454-2.5815-1.9488-4.728-4.6312-4.7985z" />
      </g>
    </svg>
  );
};
