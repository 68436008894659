import { h } from 'preact';
import { useState } from 'preact/hooks';
import { LockIcon } from '../shared/LockIcon.jsx';

/**
 * Shows that a channel section is locked and opens a form to subscribe and unlock content
 *
 * @param {string} foregroundColor - either #ffffff or #000000 depending on background color
 * @param {function} onClickOpenSubscribe - called when a viewer interacts with the lock icon, opens subscribe form
 * @returns {JSX.Element}
 */
function SubscribeButton({ foregroundColor, onClickOpenSubscribe }) {
  const [isSubscribeButtonFocused, setIsClearSearchButtonFocused] = useState(false);

  const lockIconStyle = () => {
    return {
      cursor: 'pointer',
      height: '16px',
      width: '16px',
      boxShadow: isSubscribeButtonFocused ? `0 0 0 2px ${foregroundColor}` : 'none',
    };
  };

  return (
    <button
      type="button"
      aria-label="Open subscribe form"
      onClick={onClickOpenSubscribe}
      style={lockIconStyle()}
      onBlur={() => setIsClearSearchButtonFocused(false)}
      onFocus={() => setIsClearSearchButtonFocused(true)}
      title="Subscribe to access"
    >
      <LockIcon color={foregroundColor} />
    </button>
  );
}

export default SubscribeButton;
