import { h, render, Component } from 'preact';
import { DEFAULT_FONT_FAMILY } from '../defaultFont.js';
import CarouselSection from './CarouselSection.jsx';
import { firstRowHeight } from './carouselMath.js';
import memoizedSections from '../memoizedSections.js';

class CarouselBody extends Component {
  bodyStyle() {
    return {
      color: '#fff',
      fontFamily: DEFAULT_FONT_FAMILY,
      fontSize: '14px',
      position: 'relative',
    };
  }

  renderSections() {
    return memoizedSections(this.props.galleryData).map((section, index) => {
      const isFirstSection = index === 0;
      return (
        <CarouselSection
          section={section}
          shouldShowSearchInSection={this.props.shouldShowSearch && isFirstSection}
          {...this.props}
        />
      );
    });
  }

  render() {
    return (
      <div class="w-gallery-view__body w-gallery-view__carousel" style={this.bodyStyle()}>
        {this.renderSections()}
      </div>
    );
  }
}

CarouselBody.firstRowHeight = firstRowHeight;
CarouselBody.marginLeft = (props) => {
  return props.galleryContext.galleryViewWidth * 0.08;
};
CarouselBody.leftContentOffset = CarouselBody.marginLeft;

export default CarouselBody;
