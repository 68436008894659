const cache = {};

// Get the first line of text from the description html without any formatting
// Same function is used in wistia:
// https://github.com/wistia/wistia/blob/master/src/libjs/utilities/getShortDescription.js
export const getShortDescription = (descriptionHtml) => {
  if (!descriptionHtml) {
    return undefined;
  }

  if (cache[descriptionHtml] !== undefined) {
    return cache[descriptionHtml];
  }

  const div = document.createElement('div');
  div.innerHTML = descriptionHtml;

  if (div.childNodes[0].nodeType === Node.ELEMENT_NODE) {
    const shortDescription = div.childNodes[0].innerText || '';
    cache[descriptionHtml] = shortDescription;
    return shortDescription;
  }

  if (div.childNodes[0].nodeType === Node.TEXT_NODE) {
    const shortDescription = div.childNodes[0].textContent || '';
    cache[descriptionHtml] = shortDescription;
    return shortDescription;
  }

  return undefined;
};
