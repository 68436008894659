import { Wistia } from '../../wistia_namespace.ts';

if (!Wistia._channelSectionCache) {
  Wistia._channelSectionCache = {};
}

const cache = Wistia._channelSectionCache;

const memoizedSections = (galleryData) => {
  const { hashedId } = galleryData;
  const cacheEntry = cache[hashedId];

  if (!Wistia._disableChannelSectionCache && cacheEntry) {
    return cacheEntry;
  }

  const filteredSections = galleryData.series[0].sections.filter(
    (section) => section.videos.length > 0,
  );

  cache[hashedId] = filteredSections;

  return filteredSections;
};

export default memoizedSections;
