import { Wistia } from '../wistia_namespace.ts';
import { cdnFastWistiaNetHost } from './hosts.js';

export const loadEv1 = () => {
  if (Wistia._loadEv1Promise) {
    return Wistia._loadEv1Promise;
  }

  if (Wistia.embed) {
    // It's already loaded.
    Wistia._loadEv1Promise = Promise.resolve(Wistia);
    return Wistia._loadEv1Promise;
  }

  // It's not loaded yet; load it.
  Wistia._loadEv1Promise = execEv1();
  return Wistia._loadEv1Promise;
};

const execEv1 = () => {
  if (Wistia.embed) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    const s = document.createElement('script');
    s.src = `https://${cdnFastWistiaNetHost()}/assets/external/E-v1.js`;
    let done = false;
    const onSuccess = () => {
      const state = s.readyState;
      if (!done && (!state || /loaded|complete/.test(state))) {
        done = true;
        Wistia.watchForInit();
        resolve();
      }
    };
    s.onreadystatechange = onSuccess;
    s.onload = onSuccess;
    (document.body || document.head).appendChild(s);
  });
};
