import { h, render, Component } from 'preact';

export class ArrowSvg extends Component {
  render() {
    const { onMouseEnter, onMouseLeave, style } = this.props;
    const svgStyle = { enableBackground: 'new 0 0 70 70', ...style };
    return (
      <svg viewBox="0 0 70 70" style={svgStyle}>
        <polyline
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={this.lineStyle()}
          points="30,24 42.6,34.8 30,45.6 "
        />
      </svg>
    );
  }

  lineStyle() {
    return {
      fill: 'none',
      stroke: '#FFFFFF',
      strokeWidth: 4,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: '10',
    };
  }
}
