import { h, render } from 'preact';
import { standardSvgAttrs } from 'utilities/svg_boilerplate.ts';

export const QuestionMarkIcon = ({ color }) => {
  return (
    <svg {...standardSvgAttrs({ width: 24, height: 24, ariaHidden: true })}>
      <g stroke="none" strokeWidth="1" fill={`${color}` || '#fff'} fillRule="evenodd">
        <path d="M21.9984 12.2074C22.1575 0.830249 9.48615 -0.402074 4.31698 5.71041C0.658216 10.0369 0.52306 17.3351 4.97721 20.3797C9.43136 23.4242 21.8393 23.5845 21.9984 12.2074ZM12.9442 13.4497H10.5742V13.2997C10.5742 12.3697 10.7542 11.8297 11.6542 11.2297L12.3592 10.7647C12.9442 10.3747 13.1992 9.98467 13.1992 9.35467C13.1992 8.63468 12.7192 8.18468 12.0292 8.18468C11.1892 8.18468 10.5742 8.64968 10.5742 9.74467H8.17423C8.17423 7.83968 9.41923 6.35468 12.1042 6.35468C14.1742 6.35468 15.6292 7.37468 15.6292 9.24967C15.6292 10.5547 14.9242 11.2897 14.1442 11.8747L13.5292 12.3247C13.0792 12.6697 12.9442 12.8797 12.9442 13.2697V13.4497ZM13.3192 15.7147C13.3192 16.5547 12.6292 17.2597 11.7592 17.2597C10.8742 17.2597 10.1992 16.5547 10.1992 15.7147C10.1992 14.8747 10.8742 14.1847 11.7592 14.1847C12.6292 14.1847 13.3192 14.8747 13.3192 15.7147Z" />
      </g>
    </svg>
  );
};
