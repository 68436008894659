import { VIDEO_CARD_POSTER_ASPECT } from '../galleryMath.js';
import headerFontSizeGw from '../headerFontSizeGw.js';
import memoizedSections from '../memoizedSections.js';

export const MARGIN_AS_RATIO_OF_WIDTH = 0.04;
export const MIN_VIDEO_CARD_WIDTH_IN_PX = 200;

export const galleryViewWidth = (props) => {
  return props.galleryContext.galleryViewWidth;
};

export const marginWidth = (props) => {
  return MARGIN_AS_RATIO_OF_WIDTH * galleryViewWidth(props);
};

const cardAreaWidth = (props) => {
  return galleryViewWidth(props) - marginWidth(props);
};

export const calculateCardData = (props) => {
  const { section } = props;
  const { videos } = section;
  if (!section) {
    return 0;
  }

  const mediaTypes = {};
  const isMixedMedia = videos.some((video) => {
    mediaTypes[video.type] = true;
    return Object.keys(mediaTypes).length > 1;
  });
  let cardsPerRow = galleryViewWidth(props) < 1080 ? 3 : 4;
  const isAllAudio = !isMixedMedia && mediaTypes.Audio;
  if (isAllAudio) {
    cardsPerRow += 1;
  }
  const availableWidth = galleryViewWidth(props) - (cardsPerRow + 1) * marginWidth(props);
  const rows = [[]];
  videos.forEach((video, index) => {
    let cardWidth;
    let cardHeight;
    if (isMixedMedia) {
      // When we have mixed media, figure out what the width and height of
      // this card would be if it was a video. For audio, the width is equal
      // to that height. This kinda breaks cardsPerRow logic, but the
      // cardsPerRow value doesn't make much sense to use for mixed media.
      const videoCardWidth = Math.round(
        Math.max(MIN_VIDEO_CARD_WIDTH_IN_PX, availableWidth / cardsPerRow),
      );
      const videoCardHeight = Math.round(videoCardWidth / VIDEO_CARD_POSTER_ASPECT);
      if (video.type === 'Audio') {
        cardHeight = videoCardHeight;
        cardWidth = videoCardHeight;
      } else {
        cardWidth = videoCardWidth;
        cardHeight = videoCardHeight;
      }
    } else {
      // For video-only or audio-only sections, derive the width from
      // cardsPerRow, then set the height based on the desired aspect ratio.
      // This'll make audio-only sections kinda tall, but that's what we're
      // going for.
      const cardAspect = video.type === 'Video' ? VIDEO_CARD_POSTER_ASPECT : 1;
      cardWidth = Math.round(Math.max(MIN_VIDEO_CARD_WIDTH_IN_PX, availableWidth / cardsPerRow));
      cardHeight = Math.round(cardWidth / cardAspect);
    }
    const row = rows[rows.length - 1];
    row.push({
      cardHeight,
      cardWidth,
      index: row.length,
      video,
    });

    const totalMarginWidth = row.length * marginWidth(props);
    const totalCardWidth = row.reduce((acc, { cardWidth }) => {
      return acc + cardWidth;
    }, 0);
    const cardSpaceWithoutMargins = cardAreaWidth(props) - totalMarginWidth;
    const cardOverflow = totalCardWidth - cardSpaceWithoutMargins;
    const lastCard = row[row.length - 1];
    if (cardOverflow > 0) {
      if (cardOverflow <= lastCard.cardWidth / 2) {
        // less than half of the last card was overflowing; resize all cards to
        // fit in row.
        const cardWidthMultiplier = cardSpaceWithoutMargins / totalCardWidth;
        row.forEach((card) => {
          const aspect = card.cardWidth / card.cardHeight;
          card.cardWidth = Math.round(cardWidthMultiplier * card.cardWidth);
          card.cardHeight = Math.round(card.cardWidth / aspect);
        });
        if (index < videos.length - 1) {
          rows.push([]);
        }
      } else {
        // more than half of the last card was overflowing; move the last card
        // onto a new row and scale this row up.
        let poppedCard = row.pop();
        const cardWidthMultiplier =
          (cardSpaceWithoutMargins + marginWidth(props)) / (totalCardWidth - poppedCard.cardWidth);
        row.forEach((card) => {
          const aspect = card.cardWidth / card.cardHeight;
          card.cardWidth = Math.round(cardWidthMultiplier * card.cardWidth);
          card.cardHeight = Math.round(card.cardWidth / aspect);
        });
        poppedCard.index = 0;
        rows.push([poppedCard]);
      }
    } else if (rows.length === 1 && index === videos.length - 1) {
      if (isMixedMedia) {
        // If the section is short, embiggen the cards to go all the way to the
        // edge (as long as there's more than 1)
        const cardWidthMultiplier = cardSpaceWithoutMargins / totalCardWidth;
        let minHeight = cardSpaceWithoutMargins;
        row.forEach((card) => {
          const aspect = card.cardWidth / card.cardHeight;
          const maxWidth =
            card.video.type === 'Audio' ? cardAreaWidth(props) * 0.2 : cardAreaWidth(props) * 0.4;
          card.cardWidth = Math.min(maxWidth, Math.round(cardWidthMultiplier * card.cardWidth));
          card.cardHeight = Math.round(card.cardWidth / aspect);
          if (card.cardHeight < minHeight) {
            minHeight = card.cardHeight;
          }
        });

        // For mixed media in a row, if they hit the max size restrictions,
        // they probably won't be aligned on the bottom. We fix that here. This
        // may result in extra space on the right, but we prefer that to the
        // titles not aligning.
        row.forEach((card) => {
          if (card.cardHeight > minHeight) {
            const cardWidthMultiplier = minHeight / card.cardHeight;
            card.cardWidth = Math.round(cardWidthMultiplier * card.cardWidth);
            card.cardHeight = Math.round(cardWidthMultiplier * card.cardHeight);
          }
        });
      } else {
        // If this is not a mixed media and there's only one row, we can make
        // this align perfectly, so let's do that.
        const maxWidth = isAllAudio
          ? cardSpaceWithoutMargins * 0.25
          : cardSpaceWithoutMargins * 0.5;
        const cardWidth = Math.min(maxWidth, Math.round(cardSpaceWithoutMargins / row.length));
        const aspect = isAllAudio ? 1 : VIDEO_CARD_POSTER_ASPECT;
        const cardHeight = Math.round(cardWidth / aspect);
        row.forEach((card) => {
          card.cardWidth = cardWidth;
          card.cardHeight = cardHeight;
        });
      }
    }
  });

  return {
    cardsPerRow,
    rows,
  };
};

export const firstRowHeight = (props) => {
  const section = memoizedSections(props.galleryData)[0];
  if (!section) {
    return 0;
  }

  const { galleryContext } = props;
  const { firstSectionHasName } = galleryContext;
  const cardData = calculateCardData({ ...props, section });

  const sectionNameHeight = firstSectionHasName
    ? headerFontSizeGw(props, 1.4) * 1.2 + headerFontSizeGw(props, 1.4 * 0.7)
    : 0;
  const cardHeight = cardData.rows[0][0].cardHeight;
  const nameHeight = headerFontSizeGw(props, 1.4) * 1.2 * 3;
  return sectionNameHeight + cardHeight + nameHeight;
};
