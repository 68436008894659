import CarouselBody from './carousel/CarouselBody.jsx';
import GridBody from './grid/GridBody.jsx';
import ListBody from './list/ListBody.jsx';
import NarrowBody from './narrow/NarrowBody.jsx';
import { shouldUseNarrowLayout } from './galleryMath.js';

const getBodyClass = (props) => {
  const { videoCardsLayout } = props;

  // List body takes precendence over NarrowBody
  // since it includes it's own tablet/mobile layout
  if (videoCardsLayout === 'list') {
    return ListBody;
  }

  if (shouldUseNarrowLayout(props.galleryContext)) {
    return NarrowBody;
  }

  if (videoCardsLayout === 'grid') {
    return GridBody;
  }

  return CarouselBody;
};

export default getBodyClass;
