import { h, render, Component } from 'preact';
import { Color } from 'utilities/color.js';
import { shouldUseNarrowLayout } from './galleryMath.js';
import CheckIcon from './CheckIcon.jsx';
import ScaleableWrapper from './ScaleableWrapper.jsx';
import headerFontSizeGw from './headerFontSizeGw.js';

class SubscribedBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasJustSubscribed: props.hasJustSubscribed,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ hasJustSubscribed: false });
    }, 1400);
  }

  badgeStyle() {
    const { hasJustSubscribed } = this.state;
    const { color, galleryContext, headerFontFamily } = this.props;
    const backgroundColor = new Color(color);
    return {
      background: hasJustSubscribed ? backgroundColor : '#333333',
      border: 0,
      borderRadius: '4px',
      color: '#fff',
      cursor: 'default',
      display: 'flex',
      fontFamily: headerFontFamily,
      flex: shouldUseNarrowLayout(galleryContext) ? '1 0 auto' : '0 0 auto',
      fontSize: headerFontSizeGw(this.props, shouldUseNarrowLayout(galleryContext) ? 4 : 1.4),
      justifyContent: 'center',
      lineHeight: '1.2em',
      outline: 'none',
      padding: shouldUseNarrowLayout(galleryContext)
        ? `${headerFontSizeGw(this.props, 3)}px`
        : `${headerFontSizeGw(this.props, 1.2)}px ${headerFontSizeGw(
            this.props,
            2,
          )}px ${headerFontSizeGw(this.props, 1.3)}px ${headerFontSizeGw(this.props, 2)}px`,
      transitionDuration: '400ms',
      transitionProperty: 'background',
      userSelect: 'none',
      whiteSpace: 'nowrap',
      width: 'auto',
    };
  }

  iconStyle() {
    const { galleryContext } = this.props;
    return {
      boxSizing: 'content-box',
      display: 'inline-block',
      height: headerFontSizeGw(this.props, shouldUseNarrowLayout(galleryContext) ? 4 : 1.4),
      marginLeft: headerFontSizeGw(
        this.props,
        shouldUseNarrowLayout(galleryContext) ? -0.4 : -0.1,
        0,
      ),
      padding: `0 ${headerFontSizeGw(
        this.props,
        shouldUseNarrowLayout(galleryContext) ? 2.29 : 0.8,
        0,
      )}px 0 0 `,
      position: 'relative',
      top: headerFontSizeGw(this.props, shouldUseNarrowLayout(galleryContext) ? 0.4 : 0.1, 0),
      transform: 'scale(0.9)',
      transformOrigin: '38% center',
      verticalAlign: 'top',
      width: headerFontSizeGw(this.props, shouldUseNarrowLayout(galleryContext) ? 4 : 1.4),
    };
  }

  textStyle() {
    return {
      display: 'inline-block',
      verticalAlign: 'top',
    };
  }

  render() {
    const { alignment, galleryContext } = this.props;
    return (
      <ScaleableWrapper alignment={alignment} galleryContext={galleryContext}>
        <div style={this.badgeStyle()} id="w-subscribed-badge">
          <div style={this.iconStyle()}>
            <CheckIcon color="#fff" />
          </div>
          <span style={this.textStyle()} data-subscribed-badge="Subscribed">
            Subscribed
          </span>
        </div>
      </ScaleableWrapper>
    );
  }
}

export default SubscribedBadge;
