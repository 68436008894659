import { Wistia } from '../../../wistia_namespace.ts';
import QueueFlusher from '../../shared/embedding/QueueFlusher.js';
import galleryQueueObjectToFunction from './galleryQueueObjectToFunction.js';
import galleryRegistry from '../init/galleryRegistry.js';

if (!Wistia._galleryQueueFlusher) {
  Wistia._galleryQueueFlusher = new QueueFlusher({
    embedRegistry: galleryRegistry,
    type: 'gallery',
    queueNames: ['_wgq', '_wcq', 'wistiaChannelApiQueue'],
    translateObjectToFunction: galleryQueueObjectToFunction,
  });
}

export default Wistia._galleryQueueFlusher;
