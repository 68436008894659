import { CssReset } from 'utilities/CssReset.jsx';
import { isMouseDown } from 'utilities/isMouseDown.js';
import { cachedDetect } from 'utilities/detect.js';
import { h, render, Component } from 'preact';
import { unescapeHtml } from 'utilities/core.js';
import GalleryHeroBackground from './GalleryHeroBackground.jsx';
import { DEFAULT_FONT_FAMILY } from './defaultFont.js';

const detect = cachedDetect();

const TRANSITION_DURATION = 150;

class GalleryPopoverCard extends Component {
  state = {
    gaveTimeForFontLoad: false,
    textScale: 1,
    isHovering: false,
    isKeyboardFocused: false,
    readyToShow: false,
  };

  arrowStyle() {
    return {
      fill: 'none',
      height: this.headerFontSize(13),
      marginLeft: this.headerFontSize(8),
      stroke: 'currentColor',
      strokeLinecap: 'round',
      strokeMiterlimit: 10,
      strokeWidth: 5,
      width: this.headerFontSize(8),
    };
  }

  backgroundStyle() {
    return {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    };
  }

  backgroundWrapperStyle() {
    return {
      height: '100%',
      transform: this.state.isHovering || this.state.isKeyboardFocused ? 'scale(1.1)' : 'scale(1)',
      transition: 'transform 400ms',
    };
  }

  colorOverlayStyle() {
    return {
      background: `#${this.props.color}`,
      height: '100%',
      left: 0,
      opacity: this.state.isHovering || this.state.isKeyboardFocused ? 0.5 : 0,
      position: 'absolute',
      top: 0,
      transition: 'opacity 400ms',
      width: '100%',
    };
  }

  componentDidMount() {
    this.fitText();
    setTimeout(() => {
      this.setState({ gaveTimeForFontLoad: true });
    }, 500);
  }

  componentDidUpdate() {
    this.maybeFitText();
  }

  cssResetStyle() {
    return {
      fontFamily: DEFAULT_FONT_FAMILY,
      height: '100%',
      position: 'relative',
      width: '100%',
    };
  }

  focusRingStyle() {
    return {
      border: '2px solid #5E9ED6',
      boxSizing: 'border-box',
      display: this.state.isKeyboardFocused ? 'block' : 'none',
      height: '100%',
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      top: 0,
      width: '100%',
    };
  }

  maybeFitText() {
    if (
      this.rootWidth === this.rootEl.clientWidth &&
      this.rootHeight === this.rootEl.clientHeight &&
      this.props.headerFontSizeMultiplier === this.headerFontSizeMultiplier
    ) {
      return;
    }
    this.rootWidth = this.rootEl.clientWidth;
    this.rootHeight = this.rootEl.clientHeight;
    this.headerFontSizeMultiplier = this.props.headerFontSizeMultiplier;

    // Set scale to 1 quickly and invisibly so we can get the new value for how
    // much it should scale.
    this.setState({ fitting: true, textScale: 1 }, () => {
      // The measurable width/height doesn't always change synchronously, so
      // wait for next tick to fit.
      setTimeout(() => {
        this.fitText();
        this.setState({ fitting: false });
      }, 0);
    });
  }

  fitText() {
    if (!this.textWrapperEl) {
      return;
    }

    // const rootWidth = this.rootEl.clientWidth;
    // const textWrapperWidth = this.textWrapperEl.clientWidth;
    const rootHeight = this.rootHeight - this.state.textScale * 60;
    const rootWidth = this.rootWidth - this.state.textScale * 60;

    const textWrapperHeight = this.textWrapperEl.clientHeight;
    const textWrapperWidth = this.textWrapperEl.clientWidth;
    this.textWrapperHeight = textWrapperHeight;

    const textWrapperAspect = textWrapperWidth / textWrapperHeight;
    const rootAspect = rootWidth / rootHeight;

    let idealInteriorScale;

    if (textWrapperAspect > rootAspect) {
      // modifying based on text width will have the most impact
      idealInteriorScale =
        textWrapperWidth > rootWidth ? rootWidth / textWrapperWidth : this.state.textScale;
    } else {
      // modifying based on text height will have the most impact
      idealInteriorScale =
        textWrapperHeight > rootHeight ? rootHeight / textWrapperHeight : this.state.textScale;
    }

    if (Math.abs(this.state.textScale - idealInteriorScale) > 0.001) {
      this.setState({ textScale: idealInteriorScale });
    }
  }

  headerFontSize(baseSize) {
    return this.scaledPx(this.props.headerFontSizeMultiplier * baseSize);
  }

  textWrapperStyle() {
    return {
      left: '50%',
      cursor: 'pointer',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      visibility: this.state.fitting ? 'hidden' : 'visible',
    };
  }

  lineStyle() {
    return {
      display: 'inline-block',
      margin: 0,
      textShadow:
        this.state.isHovering || this.state.isKeyboardFocused
          ? '1px 1px 3px rgba(0,0,0,0)'
          : '1px 1px 3px rgba(0,0,0,.6)',
      transition: 'text-shadow 200ms',
      verticalAlign: 'top',
    };
  }

  lines() {
    return this.props.title.split('\n');
  }

  onBlur = () => {
    if (this.state.isKeyboardFocused) {
      this.setState({ isKeyboardFocused: false });
    }
  };

  onFocus = () => {
    if (!isMouseDown()) {
      this.setState({ isKeyboardFocused: true });
    }
  };

  onMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  rootStyle() {
    return {
      cursor: 'pointer',
      height: '100%',
      opacity: this.state.gaveTimeForFontLoad && this.state.readyToShow ? 1 : 0,
      overflow: 'hidden',
      position: 'relative',
      textAlign: 'center',
      transition: 'opacity 1000ms',
      width: '100%',
    };
  }

  scaled(val) {
    return Math.max(0.3, this.state.textScale) * val;
  }

  scaledPx(val) {
    return `${this.scaled(val)}px`;
  }

  textStyle() {
    return {
      color: '#fff',
      fontFamily: this.props.headerFontFamily,
      fontSize: this.headerFontSize(30),
      marginBottom: this.scaledPx(20),
    };
  }

  openPopoverChannelButtonStyle() {
    return {
      alignItems: 'center',
      background: `#${this.props.color}`,
      borderRadius: this.scaledPx(5),
      bottom: this.scaledPx(30),
      color: '#fff',
      display: 'inline-block',
      fontFamily: this.props.headerFontFamily,
      fontSize: this.headerFontSize(18),
      margin: 'auto',
      padding: `${this.scaledPx(8)} ${this.scaledPx(12)}`,
      transition: `background-color ${TRANSITION_DURATION}ms, color ${TRANSITION_DURATION}ms`,
    };
  }

  render() {
    const { elemRef } = this.props;
    return (
      <div class="w-gallery-popover-card" ref={elemRef} style={{ height: '100%' }}>
        <CssReset style={this.cssResetStyle()}>
          <button
            class="w-css-reset w-css-reset-tree"
            onClick={this.props.onClickCard}
            onBlur={detect.touchScreen ? undefined : this.onBlur}
            onFocus={detect.touchScreen ? undefined : this.onFocus}
            onMouseEnter={detect.touchScreen ? undefined : this.onMouseEnter}
            onMouseLeave={detect.touchScreen ? undefined : this.onMouseLeave}
            style={this.rootStyle()}
            ref={(el) => (this.rootEl = el)}
            type="button"
          >
            <div style={this.backgroundWrapperStyle()}>
              <GalleryHeroBackground
                galleryEmbedOptions={this.props.galleryEmbedOptions}
                color={this.props.color}
                headerFontSizeMultiplier={this.props.headerFontSizeMultiplier}
                headerFontFamily={this.props.headerFontFamily}
                heroImageUrl={this.props.heroImageUrl}
                heroImageAspectRatio={this.props.heroImageAspectRatio}
                imageFitStrategy={'cover'}
                onClickCard={this.props.onClickCard}
                onDisplay={() => this.setState({ readyToShow: true })}
                style={this.backgroundStyle()}
                videoBackgroundHashedId={this.props.videoBackgroundHashedId}
                isPopoverCardBackground={true}
              />
            </div>
            <div class="w-color-overlay" style={this.colorOverlayStyle()}></div>
            <div class="w-focus-ring" style={this.focusRingStyle()}></div>
            <div style={this.textWrapperStyle()} ref={(el) => (this.textWrapperEl = el)}>
              <div style={this.textStyle()}>
                {this.lines().map((l) => (
                  <p style={this.lineStyle()}>{unescapeHtml(l)}</p>
                ))}
              </div>
              <div class="open-popover-channel-button" style={this.openPopoverChannelButtonStyle()}>
                Open
                <svg viewBox="0 0 28 44" style={this.arrowStyle()}>
                  <polyline points="6.8,5 23.2,21.5 6.8,38 " />
                </svg>
              </div>
            </div>
          </button>
        </CssReset>
      </div>
    );
  }
}

export default GalleryPopoverCard;
