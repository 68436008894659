import { h, render, Component } from 'preact';
import { DEFAULT_FONT_FAMILY } from '../defaultFont.js';
import memoizedSections from '../memoizedSections.js';
import ListSection from './ListSection.jsx';

class ListBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRow: null,
    };
  }

  bodyStyle = {
    color: '#fff',
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: '14px',
    position: 'relative',
    maxWidth: '808px',
    margin: 'auto',
    width: '100%',
    paddingBottom: '120px',
  };

  setExpandedRow = (rowId) => {
    this.setState({
      expandedRow: rowId === this.state.expandedRow ? null : rowId,
    });
  };

  renderSections() {
    return memoizedSections(this.props.galleryData).map((section, index) => {
      const isFirstSection = index === 0;
      return (
        <ListSection
          section={section}
          expandedRow={this.state.expandedRow}
          onExpandRow={this.setExpandedRow}
          shouldShowSearchInSection={this.props.shouldShowSearch && isFirstSection}
          {...this.props}
        />
      );
    });
  }

  render() {
    return (
      <div
        class="w-list-view__body w-list-view__grid w-css-reset w-css-reset-tree"
        style={this.bodyStyle}
      >
        {this.renderSections()}
      </div>
    );
  }
}

ListBody.firstRowHeight = () => {
  return 200;
};
ListBody.marginLeft = () => {
  return 0;
};
ListBody.leftContentOffset = () => {
  return 16;
};

export default ListBody;
