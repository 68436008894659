import { h, render, Component } from 'preact';
import WistiaVideo from 'external/wistia-video-react-component.js';
import { batchFetchData } from 'utilities/batchFetchMediaData.js';
import { unescapeHtml } from 'utilities/core.js';
import {
  isMobileWidth,
  isTabletWidth,
  isDesktopWidth,
  shouldUseNarrowLayout,
} from './galleryMath.js';
import {
  availableWidth as narrowAvailableWidth,
  marginWidth as nonListViewMarginWidth,
  listAvailableWidth,
  LIST_MOBILE_MARGIN,
} from './narrow/narrowMath.js';
import headerFontSizeGw from './headerFontSizeGw.js';

const DEFAULT_ASPECT_RATIO = 640 / 360;

const DEFAULT_AUDIO_DESKTOP_EMBED_HEIGHT = 156;

class Featured extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      embedOptions: {},
      isAudio: undefined,
      mediaHeight: DEFAULT_AUDIO_DESKTOP_EMBED_HEIGHT,
      mediaWidth: undefined,
      readyToShow: false,
      videoAspectRatio: undefined,
    };
    this.unbinds = [];
  }

  componentDidMount() {
    const { featuredMediaId, on, setFeaturedBlockHeight } = this.props;
    this.getDataForMedia(featuredMediaId);

    setFeaturedBlockHeight(DEFAULT_AUDIO_DESKTOP_EMBED_HEIGHT);

    if (on) {
      this.unbinds.push(
        on('colorchange', (color) => {
          this.setState({ color });
        }),
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { featuredMediaId, galleryContext } = this.props;
    const { isAudio, videoAspectRatio } = this.state;

    if (prevProps.featuredMediaId !== featuredMediaId) {
      this.getDataForMedia(featuredMediaId);
    } else if (
      prevProps.galleryContext.galleryViewWidth !== galleryContext.galleryViewWidth ||
      prevState.videoAspectRatio !== videoAspectRatio
    ) {
      this.setMediaDimensions(isAudio);
    }
  }

  componentWillUnmount() {
    const { setFeaturedBlockHeight } = this.props;
    setFeaturedBlockHeight(0);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.color !== this.state.color) {
      this.setState({ color: nextProps.color });
    }
  }

  aspectRatio() {
    const { videoAspectRatio } = this.state;
    return videoAspectRatio || DEFAULT_ASPECT_RATIO;
  }

  audioEmbedHeight() {
    const { galleryContext } = this.props;
    if (isMobileWidth(galleryContext)) {
      return 72;
    }

    return DEFAULT_AUDIO_DESKTOP_EMBED_HEIGHT;
  }

  descriptionStyles() {
    const { galleryContext, headerFontSizeMultiplier } = this.props;
    const headerFontSize = headerFontSizeGw(this.props, 1, 14, 18);

    return {
      display: '-webkit-box',
      fontSize: `${headerFontSize}px`,
      lineHeight: `${headerFontSize * 1.33}px`,
      // override margin-block-end which results in some browsers adding additional bottom-margin
      // based on directionality, resulting in misaligned audioEmbedHeight
      marginBlockEnd: 0,
      marginRight: isMobileWidth(galleryContext) ? '0' : '24px',
      marginTop: `${9 * headerFontSizeMultiplier}px`,
      overflow: 'hidden',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: 6,
    };
  }

  featuredOuterContainer() {
    // this is necessary to get IE 11 List to respect margin and alignment
    if (!this.isListView()) {
      return {};
    }
    return {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    };
  }

  featuredWrapper(marginLeft) {
    const { readyToShow } = this.state;
    const { galleryContext } = this.props;
    const isMobile = isMobileWidth(galleryContext);
    const isDesktop = isDesktopWidth(galleryContext);

    const defaultStyles = {
      alignItems: isMobile ? '' : 'center',
      display: 'flex',
      flexDirection: isDesktop ? 'row' : 'column',
      justifyItems: 'center',
      marginLeft: isDesktop ? marginLeft : '',
      opacity: readyToShow ? 1 : 0,
      paddingBottom: this.hasContentBelow() ? '25px' : '',
      transition: 'opacity 300ms ease 0s',
    };

    if (isMobile && !this.isListView()) {
      return {
        ...defaultStyles,
        paddingLeft: nonListViewMarginWidth(this.props),
        paddingRight: nonListViewMarginWidth(this.props),
      };
    }

    if (!this.isListView()) {
      return defaultStyles;
    }

    // maxWidth from ListBody minus padding on both sides
    const listWidth = 808 - 16 * 2;

    return {
      ...defaultStyles,
      maxWidth: `${listWidth}px`,
      marginLeft: isMobile ? LIST_MOBILE_MARGIN : '',
      marginRight: isMobile ? LIST_MOBILE_MARGIN : '',
    };
  }

  getDataForMedia(hashedId) {
    if (!hashedId) {
      return;
    }

    const { galleryEmbedOptions } = this.props;

    batchFetchData(
      hashedId,
      { embedHost: galleryEmbedOptions.embedHost },
      {
        basic: true,
        embedOptions: true,
      },
    ).then(({ embedOptions, basic: { type } }) => {
      const isAudio = type === 'Audio';
      this.setState({
        embedOptions,
        isAudio,
      });

      // because setState is async, we need to pass isAudio as a param.
      this.setMediaDimensions(isAudio);
    });
  }

  hasContentBelow() {
    const { allVideoIds } = this.props;
    return allVideoIds.length > 0;
  }

  isListView() {
    const { videoCardsLayout } = this.props;
    return videoCardsLayout === 'list';
  }

  mediaEmbedWidth() {
    const { galleryContext } = this.props;

    if (isMobileWidth(galleryContext) && this.isListView()) {
      return listAvailableWidth(this.props);
    }
    if (isMobileWidth(galleryContext)) {
      return Math.min(550, narrowAvailableWidth(this.props));
    }
    if (isTabletWidth(galleryContext)) {
      return 523;
    }

    return 453;
  }

  onEmbedded = (media) => {
    const { setFeaturedMediaRef } = this.props;
    const { isAudio } = this.state;

    // give handle to Gallery so we can pause Featured if a Popover opens
    setFeaturedMediaRef(media);

    if (isAudio) {
      // we only need the media's aspect ratio.
      this.setState({
        readyToShow: true,
      });
      return;
    }

    this.setState({
      videoAspectRatio: media.aspect(),
      readyToShow: true,
    });
  };

  sectionHeadlineStyles() {
    const { headerFontSizeMultiplier } = this.props;
    return {
      fontSize: this.sectionHeadlineFontSize(),
      lineHeight: `${this.sectionHeadlineFontSize() * 1.25}px`,
      marginTop: `${9 * headerFontSizeMultiplier}px`,
    };
  }

  sectionHeadlineFontSize() {
    const { galleryContext, videoCardsLayout } = this.props;
    const isListView = videoCardsLayout === 'list';
    const baseFontSize = shouldUseNarrowLayout(galleryContext) ? 4 : 1.4;

    return isListView
      ? `${headerFontSizeGw(this.props, baseFontSize, 18, 22.5)}px`
      : `${headerFontSizeGw(this.props, baseFontSize, 16)}px`;
  }

  sectionTitleStyles() {
    return {
      fontSize: `${headerFontSizeGw(this.props, 0.6, 12)}px`,
      fontWeight: 'bold',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      zIndex: '1',
    };
  }

  mobileSectionTitleStyles(foregroundColor) {
    const { headerFontFamily } = this.props;
    return {
      ...this.sectionTitleStyles(),
      fontFamily: headerFontFamily,
      color: `#${foregroundColor}`,
      marginTop: '12px',
      marginBottom: '12px',
      zIndex: '1',
    };
  }

  setMediaDimensions(isAudio) {
    const { galleryContext, setFeaturedBlockHeight } = this.props;
    const isMobile = isMobileWidth(galleryContext);
    const isDesktop = isDesktopWidth(galleryContext);

    const mediaWidth = this.mediaEmbedWidth();
    let mediaHeight = this.mediaEmbedWidth() / this.aspectRatio();
    if (isAudio) {
      if (!isMobile && this.textContainerRef?.clientHeight > DEFAULT_AUDIO_DESKTOP_EMBED_HEIGHT) {
        mediaHeight = this.textContainerRef.clientHeight;
      } else {
        mediaHeight = this.audioEmbedHeight();
      }
    }

    if (this.isListView() && isDesktop) {
      // List view has a set maxWidth, so scaling the embed would cut too much into the
      // space available for text content.
      this.setState({
        mediaHeight,
        mediaWidth,
      });
    } else {
      // account for font scaling and viewport scaling
      const multiplier = headerFontSizeGw(this.props, 0.05, 1, 1.4);

      this.setState({
        mediaHeight: mediaHeight * multiplier,
        mediaWidth: mediaWidth * multiplier,
      });
    }

    setFeaturedBlockHeight(mediaHeight);
  }

  textWrapperMarginTop() {
    const { galleryContext } = this.props;
    const isDesktop = isDesktopWidth(galleryContext);

    if (isDesktop) {
      return '0';
    }

    return '12px';
  }

  textWrapperWidth() {
    const { galleryContext } = this.props;
    if (isDesktopWidth(galleryContext)) {
      return headerFontSizeGw(this.props, 26, 390, 480);
    }

    return this.mediaEmbedWidth();
  }

  textWrapper(foregroundColor) {
    const { galleryContext, headerFontFamily } = this.props;
    const isDesktop = isDesktopWidth(galleryContext);

    return {
      color: `#${foregroundColor}`,
      display: 'flex',
      flexDirection: 'column',
      fontFamily: headerFontFamily,
      marginLeft: isDesktop ? '58px' : '0',
      marginTop: this.textWrapperMarginTop(),
      width: `${this.textWrapperWidth()}px`,
      zIndex: '1',
    };
  }

  getEmbedOptionsForFeatured() {
    const { featuredMediaEpisodeId, getVideoEmbedOptions } = this.props;
    const videoEmbedOptionsForNonFeatured = getVideoEmbedOptions();
    if (
      this.cachedVideoEmbedOptions &&
      this.lastVideoEmbedOptionsForNonFeatured === videoEmbedOptionsForNonFeatured
    ) {
      return this.cachedVideoEmbedOptions;
    }

    this.cachedVideoEmbedOptions = {
      channel: videoEmbedOptionsForNonFeatured.channel,
      channelId: videoEmbedOptionsForNonFeatured.channelId,
      channelPreferences: videoEmbedOptionsForNonFeatured.channelPreferences,
      deliveryCdn: videoEmbedOptionsForNonFeatured.deliveryCdn,
      email: videoEmbedOptionsForNonFeatured.email,
      embedHost: videoEmbedOptionsForNonFeatured.embedHost,
      episodeId: featuredMediaEpisodeId,
      playerColor: videoEmbedOptionsForNonFeatured.playerColor,
    };

    // Provide GA4 plugin options only when we have them.
    if (videoEmbedOptionsForNonFeatured.plugin?.googleAnalytics4) {
      this.cachedVideoEmbedOptions.plugin = {
        googleAnalytics4: videoEmbedOptionsForNonFeatured.plugin.googleAnalytics4,
      };
    }

    this.lastVideoEmbedOptionsForNonFeatured = videoEmbedOptionsForNonFeatured;
    return this.cachedVideoEmbedOptions;
  }

  render() {
    const {
      featuredDescription,
      featuredMediaId,
      featuredSectionHeadline,
      featuredSectionTitle,
      foregroundColor,
      galleryContext,
      marginLeft,
    } = this.props;

    const { mediaWidth, mediaHeight } = this.state;

    return (
      <div style={this.featuredOuterContainer()}>
        <div className="w-css-reset" style={this.featuredWrapper(marginLeft)}>
          {isMobileWidth(galleryContext) && (
            <span className="w-css-reset" style={this.mobileSectionTitleStyles(foregroundColor)}>
              {unescapeHtml(featuredSectionTitle)}
            </span>
          )}
          {featuredMediaId && (
            <WistiaVideo
              embedOptions={this.getEmbedOptionsForFeatured()}
              hashedId={featuredMediaId}
              height={mediaHeight}
              onEmbedded={this.onEmbedded}
              shouldIncludeSwatch={false}
              videoFoam={false}
              width={mediaWidth}
            />
          )}
          <div
            className="w-css-reset w-css-reset-tree"
            ref={(elem) => (this.textContainerRef = elem)}
            style={this.textWrapper(foregroundColor)}
          >
            {!isMobileWidth(galleryContext) && (
              <span style={this.sectionTitleStyles()}>{unescapeHtml(featuredSectionTitle)}</span>
            )}
            <span style={this.sectionHeadlineStyles()}>
              {unescapeHtml(featuredSectionHeadline)}
            </span>
            <p style={this.descriptionStyles()}>{unescapeHtml(featuredDescription)}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Featured;
