import { h, render, Component } from 'preact';
import { ArrowSvg } from '../../shared/ArrowSvg.jsx';

class CarouselArrow extends Component {
  state = {
    isHovering: false,
    isMouseDown: false,
  };

  buttonStyle() {
    const { dir, isVisible, style } = this.props;
    const { isHovering, isMouseDown } = this.state;
    const transforms = [
      'translateY(-50%)',
      `scale(${isMouseDown ? 1.2 : isHovering ? 1.1 : 1})`,
      `rotateZ(${dir === 'left' ? 180 : 0}deg)`,
    ];
    return {
      background: `rgba(0,0,0,${isHovering ? 0.9 : 0.7})`,
      borderRadius: '50%',
      cursor: 'pointer',
      height: '50px',
      left: isVisible ? '5px' : '-99999em',
      opacity: isVisible ? 1 : 0,
      position: 'relative',
      transform: transforms.join(' '),
      transition: `opacity 200ms ease 0ms, left 0ms ease ${isVisible ? 0 : 200}ms, transform 200ms`,
      width: '50px',
      ...style,
    };
  }

  onMouseDown = () => {
    this.setState({ isMouseDown: true });
  };

  onMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  onMouseUp = () => {
    this.setState({ isMouseDown: false });
  };

  render() {
    const { onClick, tabbable } = this.props;
    return (
      <button
        onClick={onClick}
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseUp={this.onMouseUp}
        style={this.buttonStyle()}
        tabindex={tabbable === false ? -1 : undefined}
        type="button"
      >
        <ArrowSvg />
      </button>
    );
  }
}

export default CarouselArrow;
