import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { h, render, Component } from 'preact';
import { cachedDetect } from 'utilities/detect.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import NarrowMediaCard from './NarrowMediaCard.jsx';
import headerFontSizeGw from '../headerFontSizeGw.js';
import { calculateCardData, marginWidth } from './narrowMath.js';
import SectionName from '../SectionName.jsx';
import SubscribeButton from '../SubscribeButton.jsx';
import SearchInput from '../SearchInput.jsx';
import { SearchProvider } from '../SearchProvider.jsx';
import NoResultsCard from '../NoResultsCard.jsx';

const detect = cachedDetect();

class NarrowSection extends Component {
  state = {
    hasEnteredViewport: false,
    isHovering: false,
    isSectionCollapseToggled: false,
    algoliaSearchClient: undefined,
    totalMediaCardHeight: undefined,
  };

  isFilteringFromSearch() {
    return this.props.isFilteringFromSearch && this.props.searchResultMediaIds;
  }

  shouldDisplayMediaFromFilter(hashedId) {
    if (this.isFilteringFromSearch()) {
      return this.props.searchResultMediaIds.includes(hashedId);
    }
    return true;
  }

  handleToggleSectionCollapse = () => {
    this.setState((prevState) => ({
      isSectionCollapseToggled: !prevState.isSectionCollapseToggled,
    }));
  };

  shouldHideAllCardsInSection() {
    const { isSectionCollapseToggled } = this.state;
    return isSectionCollapseToggled && !this.isFilteringFromSearch();
  }

  cardWrapperStyle(hashedId) {
    const shouldHideMedia =
      !this.shouldDisplayMediaFromFilter(hashedId) || this.shouldHideAllCardsInSection();
    return {
      display: shouldHideMedia ? 'none' : 'block',
      marginBottom: `${marginWidth(this.props) / 2}px`,
    };
  }

  cardsContainerStyle() {
    return {
      display: 'block',
      paddingLeft: `${marginWidth(this.props)}px`,
    };
  }

  componentDidMount() {
    this.setUpFancyRevealAnimation();
    if (this.props.shouldShowSearch) {
      this.setUpAlgoliaSearchClient();
    }
  }

  componentDidUpdate() {
    // Search has been enabled but algolia client is not yet loaded
    // Load client and then force update to render the search input
    if (!this.state.algoliaSearchClient && this.props.shouldShowSearch) {
      this.setUpAlgoliaSearchClient();
    }

    // Only update if there's a media card ref to read & its height is new and greater than zero
    // Avoids superfluous rerenders and avoids bad updates to this value when the media card is hidden from search (height is zero)
    const shouldUpdateTotalMediaCardHeight =
      this.firstMediaCardDiv &&
      this.firstMediaCardDiv.clientHeight > 0 &&
      this.firstMediaCardDiv.clientHeight !== this.state.totalMediaCardHeight;

    if (shouldUpdateTotalMediaCardHeight) {
      this.setState({ totalMediaCardHeight: this.firstMediaCardDiv.clientHeight });
    }
  }

  onIntersectViewport = (entries) => {
    if (entries[0].intersectionRatio > 0) {
      this.setState({
        hasEnteredViewport: true,
      });
      this._intersectionObserver.disconnect();
    }
  };

  onMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  sectionNameStyle() {
    const { headerFontFamily, initialPaintComplete } = this.props;
    const { hasEnteredViewport } = this.state;

    return {
      fontFamily: headerFontFamily,
      fontSize: `${headerFontSizeGw(this.props, 4)}px`,
      letterSpacing: `${headerFontSizeGw(this.props, 0.1, 1)}px`,
      opacity: initialPaintComplete && hasEnteredViewport ? 1 : 0,
    };
  }

  searchInputStyle() {
    const { headerFontFamily, initialPaintComplete } = this.props;
    const { hasEnteredViewport } = this.state;

    return {
      fontFamily: headerFontFamily,
      opacity: initialPaintComplete && hasEnteredViewport ? 1 : 0,
      transition: 'opacity 1s',
    };
  }

  sectionStyle() {
    return {
      display: this.shouldDisplaySection() ? '' : 'none',
      position: 'relative',
    };
  }

  shouldDisplaySection() {
    // Only hide section if actively filtering from search and
    // no video hashedIds in this section match the search results
    // Also, don't hide it if it contains the search input. That would be bad.
    return !this.hasSectionBeenFilteredOutFromSearch() || this.props.shouldShowSearchInSection;
  }

  hasSectionBeenFilteredOutFromSearch() {
    return (
      this.isFilteringFromSearch() &&
      this.cardData.rows.filter(([{ video }]) =>
        this.props.searchResultMediaIds.includes(video.hashedId),
      ).length === 0
    );
  }

  setUpFancyRevealAnimation() {
    this._intersectionObserver = new window.IntersectionObserver(this.onIntersectViewport);
    this._intersectionObserver.observe(this.sectionRef);
  }

  setUpAlgoliaSearchClient() {
    const { galleryData } = this.props;
    const { searchApiKey, searchApplicationId } = galleryData;

    dynamicImport('assets/external/channel/initAlgoliaSearchClient.js').then((mod) => {
      const { initAlgoliaSearchClient } = mod;
      this.setState({
        algoliaSearchClient: initAlgoliaSearchClient({
          apiKey: searchApiKey,
          applicationId: searchApplicationId,
        }),
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return anyValuesChanged(this.props, nextProps) || anyValuesChanged(this.state, nextState);
  }

  sectionHeaderStyle() {
    return {
      display: 'flex',
      flexDirection: 'column',
      padding: `0 ${marginWidth(this.props)}px`,
      marginBottom: '.7em',
    };
  }

  sectionNameAndLockIconWrapperStyle() {
    return {
      alignItems: 'center',
      // We need to use display so media is still initialized, otherwise playlists/autoplay will skip filtered media
      display: this.hasSectionBeenFilteredOutFromSearch() ? 'none' : 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    };
  }

  shouldDisplayNoResultsCard() {
    // Only display "no results" in the first section if actively filtering from search and
    // no video hashedIds in the entire channel match the search results
    if (this.isFilteringFromSearch() && this.props.shouldShowSearchInSection) {
      return this.props.searchResultMediaIds.length === 0;
    }
    return false;
  }

  renderCards() {
    return this.cardData.rows.map(([{ cardHeight, cardWidth, video }], i) => {
      return (
        <div
          style={this.cardWrapperStyle(video.hashedId)}
          ref={
            i === 0
              ? (el) => {
                  this.firstMediaCardDiv = el;
                }
              : null
          }
        >
          <NarrowMediaCard
            {...this.props}
            cardsPerRow={1}
            cardHeight={cardHeight}
            cardWidth={cardWidth}
            episodeId={video.episodeNumericId}
            hashedId={video.hashedId}
            key={`${video.hashedId}_grid_card`}
            name={video.name}
            playerLanguage={'en-US'}
            type={video.type}
          />
        </div>
      );
    });
  }

  renderSearchInput() {
    const { algoliaSearchClient } = this.state;
    if (!algoliaSearchClient) {
      return;
    }

    const { color, backgroundColor, contentTypeLabel, onUpdateMediaFilterFromSearch } = this.props;
    const { searchIndexName } = this.props.galleryData;
    return (
      <SearchProvider
        algoliaSearchClient={algoliaSearchClient}
        algoliaSearchIndexName={searchIndexName}
      >
        <SearchInput
          accentColor={color}
          backgroundColor={backgroundColor}
          contentTypeLabel={contentTypeLabel}
          fontSize={headerFontSizeGw(this.props, 3.5)}
          isFullWidth
          onUpdateMediaFilterFromSearch={onUpdateMediaFilterFromSearch}
          customSectionStyle={this.searchInputStyle()}
        />
      </SearchProvider>
    );
  }

  render() {
    const {
      onClickOpenSubscribe,
      subscribeIsRequired,
      section,
      viewerIsSubscribed,
      shouldShowSearchInSection,
      backgroundColor,
    } = this.props;

    const { name } = section;
    const shouldShowHeader = !!section.name || subscribeIsRequired;
    const shouldShowSubscribeButton = subscribeIsRequired && !viewerIsSubscribed;
    const sectionHeaderForegroundColor = backgroundColor === 'ffffff' ? '#000000' : '#ffffff';

    const { isSectionCollapseToggled } = this.state;
    this.cardData = calculateCardData(this.props);

    return (
      <div
        class="w-gallery-view__section"
        onMouseEnter={detect.hoverIsNatural ? this.onMouseEnter : null}
        onMouseLeave={detect.hoverIsNatural ? this.onMouseLeave : null}
        ref={(el) => {
          this.sectionRef = el;
        }}
        style={this.sectionStyle()}
      >
        <div style={this.sectionHeaderStyle()}>
          {shouldShowSearchInSection && this.renderSearchInput()}
          {shouldShowHeader && (
            <div style={this.sectionNameAndLockIconWrapperStyle()}>
              <SectionName
                name={name}
                foregroundColor={sectionHeaderForegroundColor}
                isToggleCollapseEnabled={!this.isFilteringFromSearch()}
                isSectionCollapsed={isSectionCollapseToggled}
                onToggleSectionCollapse={this.handleToggleSectionCollapse}
                customNameStyle={this.sectionNameStyle()}
              />
              {shouldShowSubscribeButton && (
                <SubscribeButton
                  foregroundColor={sectionHeaderForegroundColor}
                  onClickOpenSubscribe={onClickOpenSubscribe}
                />
              )}
            </div>
          )}
        </div>
        <div class="w-gallery-view__video-cards" style={this.cardsContainerStyle()}>
          {this.renderCards()}
          {this.shouldDisplayNoResultsCard() && (
            <NoResultsCard
              backgroundColor={backgroundColor}
              cardWidth={this.cardData.rows[0][0].cardWidth}
              cardHeight={this.cardData.rows[0][0].cardHeight}
              fontSize={headerFontSizeGw(this.props, 4)}
              totalMediaCardHeight={this.state.totalMediaCardHeight}
            ></NoResultsCard>
          )}
        </div>
      </div>
    );
  }
}

export default NarrowSection;
