export const MOBILE_WIDTH = 600;
export const TABLET_WIDTH = 800;
export const MAX_GALLERY_HEIGHT = 960;
export const VIDEO_CARD_POSTER_ASPECT = 16 / 9;

export const fontScalingIndependentOfGalleryWidth = (
  props,
  baseSize,
  minSize = 13,
  maxSize = 100,
) => {
  const { headerFontSizeMultiplier } = props;

  return Math.min(maxSize, Math.max(minSize, headerFontSizeMultiplier * baseSize * 10));
};

export const isMobileWidth = (context) => {
  const { galleryViewWidth } = context;
  return galleryViewWidth <= MOBILE_WIDTH;
};

export const isTabletWidth = (context) => {
  const { galleryViewWidth } = context;
  return galleryViewWidth > MOBILE_WIDTH && galleryViewWidth < TABLET_WIDTH;
};

export const isDesktopWidth = (context) => {
  const { galleryViewWidth } = context;
  return galleryViewWidth >= TABLET_WIDTH;
};

export const maxWidthForHeroContent = (context) => {
  const { galleryViewWidth, videoCardsLayout } = context;
  if (shouldUseNarrowLayout(context) || videoCardsLayout === 'list') {
    return `${galleryViewWidth}px`;
  }
  return `${galleryViewWidth * 0.5}px`;
};

export const shouldUseNarrowLayout = (context) => {
  const { videoCardsLayout } = context;
  return context.galleryViewWidth <= MOBILE_WIDTH && videoCardsLayout !== 'list';
};

export const spaceBetweenBigButtons = (context) => {
  const { galleryViewWidth } = context;
  return galleryViewWidth * 0.02;
};
