import { createApiSugar } from '../../shared/embedding/createApiSugar.js';
import galleryMatcher from '../init/galleryMatcher.js';
import galleryRegistry from '../init/galleryRegistry.js';
import { Wistia } from '../../../wistia_namespace.ts';

if (!Wistia.channel) {
  createApiSugar({
    apiSugarName: 'channel',
    embedMatcher: galleryMatcher,
    embedRegistry: galleryRegistry,
  });
  Wistia.gallery = Wistia.channel;
}

export default Wistia.channel;
