import { h, render, Component } from 'preact';
import { isMobileWidth, shouldUseNarrowLayout, spaceBetweenBigButtons } from './galleryMath.js';

class ScaleableWrapper extends Component {
  buttonWrapperPadding() {
    // make the buttons or badges always have 16px of space between them, be flush with
    // all other hero content if left-aligned, and fill the full width of the
    // narrow layout
    const { alignment, galleryContext } = this.props;
    if (shouldUseNarrowLayout(galleryContext)) {
      return '8px 0px';
    }
    if (alignment === 'left') {
      return `8px ${spaceBetweenBigButtons(galleryContext)}px 8px 0px`;
    }
    return '8px';
  }

  buttonWrapperStyle() {
    const { alignment, galleryContext } = this.props;
    return {
      display: 'flex',
      justifyContent: alignment === 'left' ? 'left' : 'center',
      padding: this.buttonWrapperPadding(),
      width: shouldUseNarrowLayout(galleryContext) || isMobileWidth(galleryContext) ? '100%' : '',
    };
  }

  render() {
    return <div style={this.buttonWrapperStyle()}>{this.props.children}</div>;
  }
}

export default ScaleableWrapper;
