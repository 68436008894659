import galleryRegistry from '../init/galleryRegistry.js';
import galleryMatcher from '../init/galleryMatcher.js';

const CALLBACK_MAPPINGS = {
  onFind: 'find', // not actually called, but must be truthy for existence check.
  onHasData: 'hasData',
  onEmbedded: 'embedded',
  onReady: 'ready',
};

const galleryQueueObjectToFunction = (obj, revokeCallback) => {
  if (revokeCallback && obj.revoke) {
    revokeCallback(obj.revoke);
  }

  if (!obj.id) {
    // Must specify an ID to be a valid object. id: '_all' to operate on
    // everything.
    return;
  }

  if (!Object.keys(CALLBACK_MAPPINGS).some((k) => obj[k]) && !obj.options) {
    // No need to do any translation if there are no callbacks or options
    // provided.
    return;
  }

  const resultFn = () => {
    let revoked = false;

    const unbindOnFind = galleryRegistry.onFind((gallery) => {
      if (obj.id !== '_all' && !galleryMatcher.matches(gallery, obj.id)) {
        // This is not the gallery we're looking for.
        return;
      }

      if (obj.options) {
        gallery._applyOptions(obj.options);
      }

      if (obj.channelData) {
        gallery._applyChannelData(obj.channelData);
      }

      Object.keys(CALLBACK_MAPPINGS).forEach((lifecycleCallbackName) => {
        const callback = obj[lifecycleCallbackName];
        if (callback) {
          if (lifecycleCallbackName === 'onFind') {
            callback(gallery);
          } else {
            const cancelableFn = () => {
              // It's possible to revoke the onFind after it has queued a method
              // using a downstream function. Since there's no way to remove from
              // a StopGo/Promise queue, we just only execute it if not revoked.
              if (!revoked) {
                return callback(gallery);
              }
            };
            cancelableFn.originalFn = callback;
            const fnName = CALLBACK_MAPPINGS[lifecycleCallbackName];
            gallery[fnName]().then(cancelableFn);
          }
        }
      });
    });

    const unbind = () => {
      revoked = true;
      unbindOnFind();
    };

    // The QueueFlusher looks for the __unbind property in the return value so
    // it knows whether the returned function should be executed on revocation.
    unbind.__unbind = unbind;

    return unbind;
  };

  return resultFn;
};

export default galleryQueueObjectToFunction;
