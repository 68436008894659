import { h, render, Component, Fragment } from 'preact';
import { batchFetchData } from 'utilities/batchFetchMediaData.js';
import { assign } from 'utilities/assign.js';
import { cachedDetect } from 'utilities/detect.js';
import { unescapeHtml } from 'utilities/core.js';
import { getShortDescription } from 'utilities/getShortDescription.js';
import { isMouseDownRecently } from 'utilities/isMouseDown.js';
import { interFontFamily } from 'utilities/interFontFamily.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { Wistia } from '../../../wistia_namespace.ts';
import { humanReadableDuration } from '../../../utilities/duration.ts';
import { LIST_MOBILE_MARGIN } from '../narrow/narrowMath.js';
import { HeadphonesIcon } from './HeadphonesIcon.tsx';
import { VideoIcon } from './VideoIcon.tsx';
import { RawHTMLStub } from '../../shared/RawHTMLStub.jsx';
import {
  isMobileWidth,
  isTabletWidth,
  fontScalingIndependentOfGalleryWidth,
} from '../galleryMath.js';
import { setRouteDataOnUri } from '../uriStateTransformers.js';

const detect = cachedDetect();

class ListMobileMediaRow extends Component {
  constructor(props) {
    super(props);
    const { color, media } = props;
    this.unescapedName = unescapeHtml(media.name);
    this.unbinds = [];
    const { galleryData, hashedId, routeStrategyOptions } = this.props;
    const routeData = {
      wchannelid: galleryData.hashedId,
      wmediaid: hashedId,
    };
    this.videoUrl = setRouteDataOnUri(location.href, routeData, routeStrategyOptions);
    this.state = {
      color,
      isKeyboardFocused: false,
      isPlayKeyboardFocused: false,
    };
  }

  get isExpanded() {
    const { expandedRow, media } = this.props;
    return expandedRow === media.hashedId;
  }

  get isLightTheme() {
    const { backgroundColor } = this.props;
    return backgroundColor === 'ffffff';
  }

  get boxShadowFocusColor() {
    return this.isLightTheme ? '0 0 0 2px #161616 inset' : '0 0 0 2px #fff inset';
  }

  get playerColor() {
    const { color, galleryEmbedOptions } = this.props;
    return this.state.color || color || galleryEmbedOptions.color;
  }

  componentDidMount() {
    const { on } = this.props;

    this.maybeInitPopover();
    this.fetchDescription();

    this.unbinds.push(
      on('colorchange', (color) => {
        this.setState({ color });

        if (this.popover) {
          this.popover._poster.updateEmbedOptions({ color });
        }
      }),
      on('subscribechange', (subscribe) => {
        if (this.popover && subscribe?.on) {
          this.popover.setOpeningIsDisabled(subscribe?.required);
        }
      }),
    );
  }

  componentDidUpdate() {
    this.maybeInitPopover();

    const { contentTypeLabel, posterRef } = this.props;

    if (this.popover && this.popover._poster) {
      const popoverEmbedOptions = this.getPopoverEmbedOptions();

      this.popover.setContentTypeLabel(contentTypeLabel);
      this.popover.setNavigation(popoverEmbedOptions.navigation);

      if (posterRef) {
        posterRef(this.popover._poster);
      }

      if (this.isExpanded) {
        this.popover._poster.showPlayButton();
      } else {
        this.popover._poster.hidePlayButton();
      }
    }
  }

  fetchDescription() {
    const { media } = this.props;

    batchFetchData(media.hashedId, this.getPopoverEmbedOptions(), {
      basic: true,
      episodeData: true,
    }).then((data) => {
      const shortDescription = getShortDescription(unescapeHtml(data.basic?.description));
      const notes = unescapeHtml(data.episodeData?.episodeNotes);
      const duration = humanReadableDuration(data.basic?.duration);
      this.setState({ duration, shortDescription, notes });
    });
  }

  maybeInitPopover() {
    const { suppressPopover } = this.props;

    if (!this.didInit && !suppressPopover) {
      this.didInit = true;
      dynamicImport('assets/external/poster.js');
      dynamicImport('assets/external/popover-v3.js').then(() => {
        Wistia.embedPopover(this.props.media.hashedId, this.getPopoverEmbedOptions()).then((p) => {
          this.popover = p;
          p.setPosterPlayButtonCover(false);
        });
      });
    }
  }

  getPopoverEmbedOptions() {
    const {
      galleryData,
      galleryEmbedOptions,
      headerFontFamily,
      media,
      subscribeIsRequired,
      title,
      viewerIsSubscribed,
    } = this.props;
    const shouldShowTranscript =
      galleryEmbedOptions.shouldShowTranscript == null
        ? true
        : galleryEmbedOptions.shouldShowTranscript;

    return assign(
      {
        channel: galleryData.hashedId,
        channelId: galleryData.numericId,
        channelTitle: title,
        container: this.popoverContainer,
        episodeId: media.episodeNumericId,
        fontFamily: headerFontFamily,
        openingIsDisabled: subscribeIsRequired && !viewerIsSubscribed,
        playerColor: this.playerColor,
        podcastLinks: galleryData.podcastLinks,
        popoverContent: 'poster',
        posterOptions: {
          backgroundColor: 'transparent',
          bpbCoverOnHover: true,
          channel: galleryData.hashedId,
          color: this.playerColor,
          deliveryCdn: galleryEmbedOptions.deliveryCdn,
          embedHost: galleryEmbedOptions.embedHost,
          playButton: true,
          playButtonWidth: 70,
          playButtonOnCoverOnly: true,
        },
        shouldShowTranscript,
      },
      this.props.getVideoEmbedOptions(),
    );
  }

  onClickListEpisodeRow = (event) => {
    event.preventDefault();

    // if we click on the thumbnail then bail, let its own click handler
    // open the episode (or the subscribe modal first, if channel is gated)
    if (this.thumbnailLinkRef.contains(event.target) || event.target === this.thumbnailLinkRef) {
      return;
    }

    // if we click on any of the buttons in the accordian content then bail
    if (event.target.classList.contains('w-video-row-button')) {
      return;
    }

    this.props.onExpandRow(this.props.media.hashedId);
  };

  onClickThumbnailLink = () => {
    const { media, onClickEpisodePlay } = this.props;
    event?.preventDefault();
    onClickEpisodePlay(media.hashedId, this.thumbnailLinkRef);
  };

  openEpisode = () => {
    const { media, onClickEpisodeMoreDetails } = this.props;
    onClickEpisodeMoreDetails(media.hashedId, this.moreDetailsButtonRef);
  };

  openEpisodeAndPlay = () => {
    const { media, onClickEpisodePlay } = this.props;
    onClickEpisodePlay(media.hashedId, this.playButtonRef);
  };

  onBlur = () => {
    this.setState({ isKeyboardFocused: false });
  };

  onBlurPlayButton = () => {
    this.setState({ isPlayKeyboardFocused: false });
  };

  onFocus = () => {
    this.setState({
      isKeyboardFocused: !isMouseDownRecently(),
    });
  };

  onFocusPlayButton = () => {
    this.setState({
      isPlayKeyboardFocused: !isMouseDownRecently(),
    });
  };

  onMouseLeave = () => {
    this.setState({ isKeyboardFocused: false });
    this.popover.setPosterPlayButtonCover(false);
  };

  onMouseEnter = () => {
    this.popover.setPosterPlayButtonCover(true);
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onClickListEpisodeRow(event);
    }
  };

  onKeyDownPlayButton = (event) => {
    if (event.key === 'Enter') {
      this.openEpisodeAndPlay();
    }
  };

  setListMediaRowRef = (elem) => {
    const { elemRef } = this.props;
    if (elemRef) {
      elemRef(elem);
    }
    this.listMediaRowRef = elem;
  };

  setPlayButtonRef = (elem) => {
    this.playButtonRef = elem;
  };

  textStyle() {
    const { foregroundColor, headerFontFamily } = this.props;
    return {
      color: `#${foregroundColor}`,
      fontFamily: headerFontFamily,
    };
  }

  renderDescriptionSection() {
    const { galleryContext, media } = this.props;
    const { isPlayKeyboardFocused, shortDescription, notes } = this.state;
    const tabletFontSize = `${fontScalingIndependentOfGalleryWidth(this.props, 1.3, 14, 17)}px`;
    const headerStyle = {
      ...this.textStyle(),
      marginBottom: 0,
    };

    const descriptionStyle = {
      ...this.textStyle(),
      display: '-webkit-box',
      fontSize: isTabletWidth(galleryContext)
        ? tabletFontSize
        : `${fontScalingIndependentOfGalleryWidth(this.props, 1.1, 14, 16)}px`,
      fontWeight: '500',
      lineHeight: '1.25em',
      marginBottom: '24px',
      marginTop: '8px',
      overflow: 'hidden',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: isMobileWidth(galleryContext) ? 6 : 4,
    };

    return (
      <div
        style={{
          height: this.isExpanded ? 'auto' : '0',
          overflow: 'hidden',
          transition: 'height 300ms ease, opacity 300ms ease',
          width: '100%',
          opacity: this.isExpanded ? '1' : '0',
        }}
      >
        <div style={{ margin: '10px 0' }}>
          {shortDescription && (
            <Fragment>
              <h4 style={headerStyle}>SUMMARY</h4>
              <p style={descriptionStyle}>{shortDescription}</p>
            </Fragment>
          )}
          {notes && (
            <Fragment>
              {/* notes are html from a WYSIWYG editor and unless it is a <li>, it defaults to a <p> tag
              which has margin by default so negative margin is needed on the header */}
              <h4
                style={{
                  ...headerStyle,
                  marginBottom: notes.startsWith('<p') ? '-16px' : '6px',
                }}
              >
                NOTES
              </h4>
              <div
                dangerouslySetInnerHTML={{ __html: notes }}
                style={{ ...this.textStyle(), marginTop: 0 }}
              />
            </Fragment>
          )}
        </div>
        <div ref={(elem) => (this.buttonsContainer = elem)} style={{ display: 'flex' }}>
          <button
            aria-label={`Open and play ${media.type}: ${media.name}`}
            class="w-video-row-button"
            disabled={this.isExpanded !== true}
            onBlur={this.onBlurPlayButton}
            onClick={this.openEpisodeAndPlay}
            onFocus={this.onFocusPlayButton}
            onKeyDown={this.onKeyDownPlayButton}
            ref={this.setPlayButtonRef}
            style={{
              background: `#${this.playerColor}`,
              boxShadow: isPlayKeyboardFocused ? this.boxShadowFocusColor : '',
              borderRadius: '5px',
              fontFamily: interFontFamily,
              fontSize: isTabletWidth(galleryContext)
                ? tabletFontSize
                : `${fontScalingIndependentOfGalleryWidth(this.props, 0.9, 14, 16)}px`,
              height: '40px',
              textAlign: 'center',
              width: '100%',
            }}
            type="button"
          >
            Play
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { galleryContext, media, foregroundColor } = this.props;
    const { duration, isKeyboardFocused } = this.state;
    const { aspectRatio, name, type } = media;
    const inverseAspectPercent = ((1 / aspectRatio) * 100).toFixed(2);
    const iconColor = `#${foregroundColor}`;

    const nameStyle = {
      ...this.textStyle(),
      display: '-webkit-box',
      fontSize: isTabletWidth(galleryContext)
        ? `${fontScalingIndependentOfGalleryWidth(this.props, 1.6, 18, 22.5)}px`
        : `${fontScalingIndependentOfGalleryWidth(this.props, 1.4, 14, 18)}px`,
      fontWeight: '500',
      lineHeight: '1.25em',
      overflow: 'hidden',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: isMobileWidth(galleryContext) ? 3 : 2,
    };

    const mediaDurationStyles = {
      color: `#${this.playerColor}`,
      fontSize: isTabletWidth(galleryContext)
        ? `${fontScalingIndependentOfGalleryWidth(this.props, 1.1, 10, 14)}px`
        : `${fontScalingIndependentOfGalleryWidth(this.props, 0.8, 10, 14)}px`,
      fontWeight: 'bold',
      letterSpacing: '0.1em',
      marginLeft: '8px',
      textTransform: 'uppercase',
    };

    const bgColor = this.isLightTheme ? 'rgba(24, 24, 24, 0.15)' : 'rgba(216, 216, 216, 0.15)';
    const mediaRowStyles = {
      backgroundColor: this.isExpanded ? bgColor : '',
      transition: 'background-color 300ms ease',
      borderRadius: isTabletWidth(galleryContext) ? '8px' : '',
      boxShadow: isKeyboardFocused ? this.boxShadowFocusColor : '',
      display: 'flex',
      outline: 'none',
      padding: `16px ${LIST_MOBILE_MARGIN}px 16px ${LIST_MOBILE_MARGIN}px`,
      position: 'relative',
      textAlign: 'left',
      width: '100%',
      boxSizing: 'border-box',
      flexDirection: 'column',
    };

    return (
      <button
        aria-expanded={this.isExpanded}
        aria-label={`${this.isExpanded ? 'Collapse' : 'Expand'} details for ${type}: ${name}`}
        class="w-video-row"
        onBlur={this.onBlur}
        onClick={this.onClickListEpisodeRow}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        style={mediaRowStyles}
        ref={this.setListMediaRowRef}
      >
        <div style={{ width: '100%' }}>
          <div style={{ flexGrow: '1' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <a
                  href={this.videoUrl}
                  onClick={this.onClickThumbnailLink}
                  ref={(elem) => (this.thumbnailLinkRef = elem)}
                  style={{ minWidth: '120px', marginRight: '1em' }}
                >
                  <div
                    class="wistia_responsive_padding"
                    onMouseEnter={detect.hoverIsNatural ? this.onMouseEnter : null}
                    onMouseLeave={detect.hoverIsNatural ? this.onMouseLeave : null}
                    style={`padding:${inverseAspectPercent}% 0 0 0;position:relative;`}
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style={{
                        height: '100%',
                        left: '0',
                        position: 'absolute',
                        top: '0',
                        width: '100%',
                      }}
                    >
                      <RawHTMLStub
                        class="wistia_popover"
                        stubRef={(el) => (this.popoverContainer = el)}
                        style={{
                          height: '100%',
                          position: 'relative',
                          width: '100%',
                        }}
                      />
                    </div>
                  </div>
                </a>
                <div>
                  <h3 style={nameStyle} title={this.unescapedName}>
                    {this.unescapedName}
                  </h3>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    {type === 'Audio' ? (
                      <HeadphonesIcon fill={iconColor} />
                    ) : (
                      <VideoIcon fill={iconColor} />
                    )}
                    <div style={mediaDurationStyles}>{duration}</div>
                  </div>
                </div>
              </div>

              <div style={{ flex: 'auto 0 0' }}>
                <div style={{ transform: this.isExpanded ? 'rotate(180deg)' : '' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    style={{
                      color: this.isLightTheme ? 'rgb(24, 24, 24)' : 'rgb(179, 179, 179)',
                      marginTop: '3px',
                    }}
                  >
                    <path
                      fill="currentColor"
                      d="M11.912 14.124 21.451 5 24 7.438 11.912 19 0 7.607l2.549-2.438z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            {isTabletWidth(galleryContext) && this.renderDescriptionSection()}

            {/* bottom border separator */}
            <div
              style={{
                backgroundColor: '#62626c',
                bottom: '0',
                height: '1px',
                left: '16px',
                position: 'absolute',
                right: '16px',
              }}
            />
          </div>
        </div>
        {isMobileWidth(galleryContext) && this.renderDescriptionSection()}
      </button>
    );
  }
}

export default ListMobileMediaRow;
