import { createContext, h } from 'preact';
import { useCallback, useContext, useState } from 'preact/hooks';
import { asyncDebounce } from 'utilities/debounce.js';
import { clearTimeouts } from 'utilities/timeout-utils.js';

const TIMEOUT_KEY = 'channel_search';

const SearchContext = createContext();

/**
 * Takes in an algolia search client and provides some functions for searching
 *
 * @param {object} algoliaSearchClient
 * @param {string} algoliaSearchIndexName
 * @param {Node} children
 * @returns {JSX.Element}
 */

export const SearchProvider = ({ algoliaSearchClient, algoliaSearchIndexName, children }) => {
  const search = useCallback(
    asyncDebounce(
      TIMEOUT_KEY,
      async (query, { analyticsTags }) => {
        if (!algoliaSearchClient) {
          return {};
        }

        const { results } = await algoliaSearchClient.search(
          {
            requests: [
              {
                indexName: algoliaSearchIndexName,
                query,
              },
            ],
          },

          {
            analyticsTags,
            attributesToRetrieve: ['mediaHashedId'],
          },
        );

        return results[0] ?? { hits: [], nbHits: 0 };
      },
      300,
    ),
    [algoliaSearchClient, algoliaSearchIndexName],
  );

  const cancelPendingSearch = () => {
    clearTimeouts(TIMEOUT_KEY);
  };

  return (
    <SearchContext.Provider value={{ search, cancelPendingSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const searchContext = useContext(SearchContext);
  if (searchContext === undefined) {
    throw new Error(
      'Search Context Provider is missing. Make sure to only use useSearch within a SearchProvider wrapper.',
    );
  }

  return searchContext;
};
