import { assign } from 'utilities/assign.js';
import { h, render, Component } from 'preact';
import { cdnFastWistiaNetHost } from '../utilities/hosts.js';

const DEFAULT_ASPECT_RATIO = 640 / 360;

const SUPPORTED_EVENT_TYPES = [
  'AfterReplace',
  'BeforeRemove',
  'BeforeReplace',
  'CancelFullscreen',
  'CancelWatchNext',
  'CaptionsChange',
  'Conversion',
  'Down',
  'End',
  'EnterFullscreen',
  'Error',
  'HeightChange',
  'MuteChange',
  'Pause',
  'PercentWatchedChange',
  'Play',
  'PlaybackRateChange',
  'Progress',
  'Seek',
  'SecondChange',
  'SilentPlaybackModeChange',
  'StateChange',
  'TimeChange',
  'TransitionDone',
  'VolumeChange',
  'Waiting',
  'WidthChange',
  'Up',
];

const bindingExistsOnVideo = (video, eventType, callback) => {
  return Boolean(
    video._bindings[eventType.toLowerCase()] &&
      video._bindings[eventType.toLowerCase()].indexOf(callback) > -1,
  );
};

const isSupportedEventType = (eventType) => {
  return SUPPORTED_EVENT_TYPES.indexOf(eventType) >= 0;
};

const isNumber = (s) => {
  return /^\d+(?:\.\d+)?$/.test(s);
};

let loadEv1Promise;

class WistiaVideo extends Component {
  constructor(props) {
    super(props);
    if (!props.hashedId) {
      throw new Error('Must specify a hashedId prop.');
    }

    this.videoBindingsFromProps = {};
    this.state = { swatchOpacity: 0 };

    this.loadEv1();
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props;

    if (this.container) {
      if (nextProps.containerClassName !== props.containerClassName) {
        this.container.className = `wistia_embed ${nextProps.containerClassName}`;
      }

      if (
        (props.containerStyle || nextProps.containerStyle) &&
        JSON.stringify(nextProps.containerStyle || {}) !==
          JSON.stringify(props.containerStyle || {})
      ) {
        // Kill previous styles set this way.
        for (let stylePropName in props.containerStyle) {
          this.container.style[stylePropName] = '';
        }
        // Set new styles.
        for (let stylePropName in nextProps.containerStyle) {
          this.container.style[stylePropName] = nextProps.containerStyle[stylePropName];
        }
      }
    }

    this.initVideo().then((video) => {
      if (props.hashedId !== nextProps.hashedId) {
        const resetDimensionsAndBindings = () => {
          this.setVideoDimensions(nextProps.width, nextProps.height);
          if (nextProps.onEmbedded) {
            video.embedded(() => nextProps.onEmbedded(video));
          }
          if (nextProps.onReady) {
            video.ready(() => nextProps.onReady(this.video));
          }
          this.videoBindingsFromProps = {};
          this.setupVideoBindingsFromProps(nextProps);
        };

        if (video.hashedId() === nextProps.hashedId) {
          // If the video's hashed ID is already the new hashed ID, then it may
          // have changed from an external replaceWith, like if it's in a
          // playlist. In that case, we don't need to wait until the
          // replacement has finished to run these.
          resetDimensionsAndBindings();
        } else {
          const unbindAfterReplace = video.on('afterreplace', () => {
            resetDimensionsAndBindings();
            unbindAfterReplace();
          });
          video.replaceWith(nextProps.hashedId, this.embedOptionsFromProps(nextProps));
        }
      } else {
        this.setVideoDimensions(nextProps.width, nextProps.height);

        // if a media card gets opened before the channel color changes in the channel editor
        // we need to make sure the updated prop color gets displayed
        if (props.embedOptions.playerColor !== nextProps.embedOptions.playerColor) {
          this.video.playerColor(nextProps.embedOptions.playerColor);
        }
        this.setupVideoBindingsFromProps(nextProps);
      }
    });
  }

  render() {
    if (this.props.videoFoam) {
      return this.renderResponsive();
    }
    const { width, height } = this.deriveWidthAndHeight(this.props);
    return this.renderFixedSize(width, height);
  }

  componentWillUnmount() {
    if (this._initVideoPromise) {
      if (this.video) {
        this.video.remove();
      } else {
        this.initVideo().then((video) => video.remove());
      }
    }
  }

  renderFixedSize(width, height) {
    return (
      <div
        id={this.props.containerId}
        class={`wistia_embed ${this.props.containerClassName || ''}`}
        ref={(e) => (this.container = e)}
        style={this.containerStyle(width, height)}
      >
        {this.shouldIncludeSwatch() ? this.renderSwatch() : undefined}
        {this.isPopoverWithDynamicThumbnail() ? undefined : this.props.children}
      </div>
    );
  }

  setVideoDimensions(width, height) {
    if (width && height) {
      if (isNumber(width) && isNumber(height)) {
        this.video.width(width);
        this.video.height(height);
      } else {
        this.video.container.style.width = width;
        this.video.container.style.height = height;
      }
    } else if (width) {
      if (isNumber(width)) {
        this.video.width(width, { constrain: true });
      } else {
        this.video.container.style.width = width;
      }
    } else if (height) {
      if (isNumber(height)) {
        this.video.height(height, { constrain: true });
      } else {
        this.video.container.style.height = height;
      }
    }
  }

  renderResponsive() {
    return (
      <div class="wistia_responsive_padding" style={this.responsivePaddingStyle()}>
        <div class="wistia_responsive_wrapper" style={this.responsiveWrapperStyle()}>
          {this.renderFixedSize('100%', '100%')}
        </div>
      </div>
    );
  }

  renderSwatch() {
    return (
      <div class="wistia_swatch" style={this.swatchRootStyle()} ref={(e) => (this.swatchRoot = e)}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          src={this.swatchSrc()}
          style={this.swatchImgStyle()}
          alt=""
          onload={this.onLoadSwatch}
        />
      </div>
    );
  }

  componentDidMount() {
    const props = this.props;
    this.initVideo().then((video) => {
      if (props.onInit) {
        props.onInit(video);
      }
      this.setupVideoBindingsFromProps(props);
    });
  }

  setupVideoBindingsFromProps(props) {
    this.initVideo().then((video) => {
      // Only allow one binding at a time that's setup via props. If the
      // function of a prop changes, unbind the old callback and bind the new
      // one.
      const callbacksByEventType = this.getPropCallbacksByEventType(props);
      for (let eventType in callbacksByEventType) {
        const existingCallback = this.videoBindingsFromProps[eventType];
        const newCallback = callbacksByEventType[eventType];
        if (
          !existingCallback ||
          existingCallback.origFn !== newCallback ||
          // this last check is useful for when replaceWith is called with the
          // same hashedId, as we do in passwordprotect
          !bindingExistsOnVideo(video, eventType, existingCallback)
        ) {
          if (existingCallback) {
            video.off(eventType.toLowerCase(), existingCallback.origFn);
            delete this.videoBindingsFromProps[eventType];
          }
          if (newCallback) {
            const newCallbackWrapper = (...args) => {
              return newCallback({
                video,
                type: eventType,
                data: args,
              });
            };
            newCallbackWrapper.origFn = newCallback;
            this.videoBindingsFromProps[eventType] = newCallbackWrapper;
            video.on(eventType.toLowerCase(), newCallbackWrapper);
          }
        }
      }

      // If we render with an on{EventType} prop, then if that prop is no
      // longer provided, we should unbind it. For example:
      //
      // render(<WistiaVideo hashedId="abc" onPlay={fn} />, elem);
      // render(<WistiaVideo hashedId="abc" />, elem);
      //
      // This should unbind the previous onPlay.
      for (let existingEventType in this.videoBindingsFromProps) {
        if (!this.hasBindingForEventType(props, existingEventType)) {
          video.off(
            existingEventType.toLowerCase(),
            this.videoBindingsFromProps[existingEventType],
          );
          delete this.videoBindingsFromProps[existingEventType];
        }
      }
    });
  }

  hasBindingForEventType(props, needleEventType) {
    for (let propName in props) {
      const match = propName.match(/on(.+)/);
      const eventType = match && match[1];
      if (eventType === needleEventType) {
        return true;
      }
    }
    return false;
  }

  getPropCallbacksByEventType(props) {
    const result = {};
    for (let propName in props) {
      const callback = props[propName];
      const match = propName.match(/on(.+)/);
      const eventType = match && match[1];
      if (eventType && isSupportedEventType(eventType)) {
        result[eventType] = callback;
      }
    }
    return result;
  }

  initVideo() {
    if (this._initVideoPromise) {
      return this._initVideoPromise;
    }

    const props = this.props;
    return (this._initVideoPromise = new Promise((resolve) => {
      this.loadEv1().then((Wistia) => {
        this.video = Wistia.embed(
          props.mediaData || props.hashedId,
          this.embedOptionsFromProps(props),
        );
        // XXX: We should probably be using props here instead of this.props,
        // but I don't want to deal with breaking potential downstream
        // dependencies on that at the moment.
        if (this.props.onHasData) {
          this.video.hasData(() => this.props.onHasData(this.video));
        }
        if (this.props.onEmbedded) {
          this.video.embedded(() => this.props.onEmbedded(this.video));
        }
        if (this.props.onReady) {
          this.video.ready(() => this.props.onReady(this.video));
        }
        resolve(this.video);
      });
    }));
  }

  embedOptionsFromProps(props = this.props) {
    const result = props.embedOptions ? props.embedOptions : assign({}, props);
    delete result.children;
    result.container = result.containerId || this.container;
    return result;
  }

  loadEv1() {
    if (loadEv1Promise) {
      return loadEv1Promise;
    }
    if (window.Wistia && window.Wistia.embed) {
      return (loadEv1Promise = Promise.resolve(window.Wistia));
    }
    return (loadEv1Promise = new Promise((resolve) => {
      window._wq = window._wq || [];
      window._wq.push((Wistia) => resolve(Wistia));

      const s = document.createElement('script');
      s.src = `https://${cdnFastWistiaNetHost()}/assets/external/E-v1.js`;
      let done = false;
      const onSuccess = () => {
        const state = s.readyState;
        if (!done && (!state || /loaded|complete/.test(state))) {
          done = true;
          window.Wistia.watchForInit();
        }
      };
      s.onreadystatechange = onSuccess;
      s.onload = onSuccess;
      (document.body || document.head).appendChild(s);
    }));
  }

  containerStyle(width, height) {
    return assign(
      {
        height,
        position: 'relative',
        width,
      },
      this.props.containerStyle,
    );
  }

  responsivePaddingStyle() {
    return {
      paddingBottom: this.extraHeightPx(),
      paddingLeft: 0,
      paddingTop: this.inverseAspectPercent(),
      paddingRight: 0,
      position: 'relative',
    };
  }

  responsiveWrapperStyle() {
    return {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    };
  }

  swatchRootStyle() {
    return {
      height: '100%',
      left: 0,
      opacity: this.state.swatchOpacity,
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      transition: 'opacity 200ms',
      width: '100%',
    };
  }

  swatchSrc() {
    return `https://${cdnFastWistiaNetHost()}/embed/medias/${this.props.hashedId}/swatch`;
  }

  swatchImgStyle() {
    return {
      filter: 'blur(5px)',
      height: '100%',
      objectFit: 'contain',
      width: '100%',
    };
  }

  onLoadSwatch = () => {
    this.setState({ swatchOpacity: 1 });
  };

  shouldIncludeSwatch() {
    return (
      (this.props.popover !== true && this.props.shouldIncludeSwatch == null) ||
      this.props.shouldIncludeSwatch === true
    );
  }

  isPopoverWithDynamicThumbnail() {
    return this.props.popover === true && this.props.popoverContent === 'thumbnail';
  }

  aspectRatio(props = this.props) {
    if (props.aspectRatio != null) {
      // The implementer knows the exact aspect ratio the video should embed at
      // all the time.
      return props.aspectRatio;
    }
    if (this.video && this.video.hasData()) {
      // We have ideal aspect ratio from the video data; let's use that.
      return this.video.aspect();
    }
    if (props.aspectRatioBeforeHasData != null) {
      // If the component initializes a size before we have aspect ratio data,
      // use this until we have more info.
      return props.aspectRatioBeforeHasData;
    }
    // The implementer hasn't specified aspectRatioBeforeHasData, so use
    // this.
    return DEFAULT_ASPECT_RATIO;
  }

  inverseAspectPercent() {
    return `${(1 / this.aspectRatio()) * 100}%`;
  }

  // This would be set if the video rectangle includes things above or below
  // the <video> itself that do not contribute to its aspect ratio. We don't
  // have anything like this anymore in the product, but the prime historical
  // example is the socialbar, which would always add 25px.
  extraHeightPx() {
    const extraHeight = this.props.extraHeight != null ? this.props.extraHeight : 0;
    return `${extraHeight}px`;
  }

  // Allow any combo of width and height to be passed or omitted. If omitted,
  // use aspect ratio and default values to determine omitted value.
  deriveWidthAndHeight(props = this.props) {
    let width;
    let height;
    if (props.width != null && props.height != null) {
      width = props.width;
      height = props.height;
    } else if (props.width != null && props.height == null) {
      width = props.width;
      height = width / this.aspectRatio(props);
    } else if (props.width == null && props.height != null) {
      height = props.height;
      width = height * this.aspectRatio(props);
    } else {
      width = 640;
      height = width / this.aspectRatio(props);
    }
    return { width, height };
  }
}

export default WistiaVideo;
