import { root as globalRoot } from 'utilities/root.js';
import { globalEventLoop } from 'utilities/event_loop.js';
import { Wistia } from '../../../wistia_namespace.ts';

if (!Wistia.QueueFlusher) {
  const safeExec = (fn) => {
    try {
      return fn();
    } catch (e) {
      setTimeout(() => {
        throw e;
      }, 0);
    }
  };

  class QueueFlusher {
    constructor(options = {}) {
      this._type = options.type;
      this._queueNames = options.queueNames;
      this._translateObjectToFunction = options.translateObjectToFunction;
      this._pollInterval = options.pollInterval != null ? options.pollInterval : 500;
      this._callbacksOnRevoke = [];
      this._embedRegistry = options.embedRegistry;
      this.flushEvery(this._pollInterval);

      // flushing the queue immediately on an embed registration introduces some
      // consistency to the registration and queueflush flow instead of potentionally
      // waiting for the pollInterval to catch the new embed
      if (this._embedRegistry) {
        this._embedRegistry.on('registered', () => {
          this.flush();
        });
      }
    }

    pollLoopName() {
      return `flush-${this._type}-queue`;
    }

    flushEvery(interval = this._pollInterval()) {
      globalEventLoop.add(this.pollLoopName(), interval, () => this.flush());
    }

    stop() {
      globalEventLoop.remove(this.pollLoopName());
    }

    flush() {
      this.queues().forEach((queue) => this.flushOne(queue));
    }

    flushOne(inputQueue) {
      const fnQueue = [];

      while (inputQueue.length > 0) {
        const el = inputQueue.shift();
        ((el) => {
          if (typeof el === 'function') {
            const fn = () => el();
            fn.el = el;
            fnQueue.push(fn);
          } else {
            const fn =
              this._translateObjectToFunction &&
              this._translateObjectToFunction(el, (elToRevoke) => this.revoke(elToRevoke));
            if (typeof fn === 'function') {
              fn.el = el;
              fnQueue.push(fn);
            }
          }
        })(el);
      }

      fnQueue.forEach((fn) => {
        const retVal = safeExec(fn);
        if (retVal && retVal.__unbind) {
          this._callbacksOnRevoke.push([fn.el, retVal.__unbind]);
        }
      });

      // It's important to empty the queue this way because it preserves the
      // reference to the array. So if the user has created a local reference
      // to the same window[queueName], it will continue to work.
      inputQueue.length = 0;
    }

    queues() {
      return this._queueNames.map((name) => globalRoot[name]).filter((q) => Array.isArray(q));
    }

    revoke(el) {
      this.queues().forEach((queue) => this.revokeOne(queue, el));
    }

    revokeOne(inputQueue, elToRevoke) {
      // remove from the queue to be executed, in case we're revoking something
      // that hasn't run yet.
      for (let i = inputQueue.length - 1; i >= 0; i--) {
        if (inputQueue[i] === elToRevoke) {
          inputQueue.splice(i, 1);
        }
      }

      // run any callbacks associated with that element and remove the entry
      // from our state.
      this._callbacksOnRevoke = this._callbacksOnRevoke.filter(([el, unbind]) => {
        if (el === elToRevoke) {
          safeExec(unbind);
          return false;
        }
        return true;
      });
    }
  }

  Wistia.QueueFlusher = QueueFlusher;
}

export default Wistia.QueueFlusher;
