import { elemBind } from 'utilities/elem.js';

class GalleryHistory {
  constructor() {
    this.channels = {};
    this.history = window.history;
    this.stateHistory = [];
    this._statePointer = this.defaultStatePointer();

    this.setupBindings();
  }

  back() {
    this.history.back();
  }

  defaultStatePointer() {
    return this.history.state?.pointer || 0;
  }

  increaseStatePointer() {
    this._statePointer += 1;
  }

  mostRecentGalleryId() {
    let channelId;
    for (let i = this.stateHistory.length - 1; i >= 0; i--) {
      if (this.stateHistory[i] && this.stateHistory[i].wchannelid) {
        channelId = this.stateHistory[i].wchannelid;
        break;
      }
    }

    return channelId;
  }

  get previousState() {
    return this.stateHistory[this._statePointer - 1];
  }

  pushState(routeData, url) {
    this.removeFutureStates();

    this.increaseStatePointer();

    const entry = {
      ...routeData,
      pointer: this.statePointer,
    };

    this.stateHistory.push(entry);
    this.history.pushState(entry, '', url);
  }

  register(channel, options = {}) {
    const hashedId = channel.hashedId();

    if (this.channels[hashedId]) {
      return;
    }

    this.channels[hashedId] = channel;

    if (options.history) {
      this.history = options.history;
    }
  }

  // remove all future states, by changing the state history array to that of the
  // state pointer.
  removeFutureStates() {
    this.stateHistory.length = this._statePointer;
  }

  replaceHistoryState(stateEntry, url) {
    this.history.replaceState(stateEntry, '', url);
  }

  setupBindings() {
    elemBind(window, 'popstate', (e) => {
      // Ensure that _galleryStateHistory stays in sync with the browser's
      // record of history, by populating each slot with the state snapshot.
      // This is important for when we're navigating forward after having
      // navigated back out of the page where the gallery is embedded (and
      // when we're navigating backwards into the page where the gallery is
      // embedded, after having clicked an external link). In those
      // situations, past/future states are only known to the browser, so we
      // must repopulate this._galleryStateHistory as we nav forward and back.
      this._statePointer = this.defaultStatePointer();
      this.stateHistory[this._statePointer] = this.history.state;

      // if the popstate event has an associated channel use that,
      // otherwise look through state for the most recent channel Id
      // lastly, just grab any channel if there's nothing else
      let channelId;
      if (e.state && e.state.wchannelid) {
        channelId = e.state.wchannelid;
      } else if (this.mostRecentGalleryId()) {
        channelId = this.mostRecentGalleryId();
      } else {
        channelId = Object.keys(this.channels)[0];
      }

      if (channelId) {
        this.channels[channelId].setViewFromUri({ inPopState: true });
      }
    });
  }

  get statePointer() {
    return this._statePointer;
  }

  set statePointer(val) {
    this._statePointer = val;
  }

  unregister(hashedId) {
    delete this.channels[hashedId];
  }
}

export default GalleryHistory;
