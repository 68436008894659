import { h, render, Component } from 'preact';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import ListMediaRow from './ListMediaRow.jsx';
import ListMobileMediaRow from './ListMobileMediaRow.jsx';
import { fontScalingIndependentOfGalleryWidth } from '../galleryMath.js';
import SectionName from '../SectionName.jsx';
import SubscribeButton from '../SubscribeButton.jsx';
import SearchInput from '../SearchInput.jsx';
import { SearchProvider } from '../SearchProvider.jsx';
import NoResultsRow from '../NoResultsRow.jsx';
import { getChannelStorage, updateChannelStorage } from '../channelStorage.js';

class ListSection extends Component {
  state = {
    isSectionCollapseToggled: false,
    algoliaSearchClient: undefined,
    totalMediaRowHeight: undefined,
  };

  componentDidMount() {
    if (this.props.shouldShowSearch) {
      this.setUpAlgoliaSearchClient();
    }

    this.setInitialCollapsedness();
  }

  componentDidUpdate() {
    // Search has been enabled but algolia client is not yet loaded
    // Load client and then force update to render the search input
    if (!this.state.algoliaSearchClient && this.props.shouldShowSearch) {
      this.setUpAlgoliaSearchClient();
    }

    // Only update if there's a media row ref to read & its height is new and greater than zero
    // Avoids superfluous rerenders and avoids bad updates to this value when the media row is hidden from search (height is zero)
    const shouldUpdateTotalMediaRowHeight =
      this.firstMediaRowDiv &&
      this.firstMediaRowDiv.clientHeight > 0 &&
      this.firstMediaRowDiv.clientHeight !== this.state.totalMediaRowHeight;

    if (shouldUpdateTotalMediaRowHeight) {
      this.setState({ totalMediaRowHeight: this.firstMediaRowDiv.clientHeight });
    }
  }

  setInitialCollapsedness() {
    const {
      section: { numericId: sectionId },
      galleryData: { hashedId },
    } = this.props;

    const collapsednessOfSections = getChannelStorage(hashedId).collapsednessOfSections;
    const shouldSectionBeCollapsed = collapsednessOfSections?.[sectionId] || false;
    this.setState({
      isSectionCollapseToggled: shouldSectionBeCollapsed,
    });
  }

  setUpAlgoliaSearchClient() {
    const { galleryData } = this.props;
    const { searchApiKey, searchApplicationId } = galleryData;

    dynamicImport('assets/external/channel/initAlgoliaSearchClient.js').then((mod) => {
      const { initAlgoliaSearchClient } = mod;
      this.setState({
        algoliaSearchClient: initAlgoliaSearchClient({
          apiKey: searchApiKey,
          applicationId: searchApplicationId,
        }),
      });
    });
  }

  galleryViewWidth() {
    return this.props.galleryContext.galleryViewWidth;
  }

  searchInputFontSize() {
    return fontScalingIndependentOfGalleryWidth(this.props, 1.4, 16, 22.5);
  }

  sectionStyle() {
    return { display: this.shouldDisplaySection() ? '' : 'none', marginBottom: '26px' };
  }

  shouldDisplaySection() {
    // Only hide section if actively filtering from search and
    // no video hashedIds in this section match the search results
    // Also, don't hide it if it contains the search input. That would be bad.
    return !this.hasSectionBeenFilteredOutFromSearch() || this.props.shouldShowSearchInSection;
  }

  shouldHideAllCardsInSection() {
    const { isSectionCollapseToggled } = this.state;
    return isSectionCollapseToggled && !this.isFilteringFromSearch();
  }

  handleToggleSectionCollapse = () => {
    const { isSectionCollapseToggled } = this.state;
    const {
      section: { numericId: sectionId },
      galleryData: { hashedId },
    } = this.props;

    updateChannelStorage(hashedId, (ls) => {
      ls.collapsednessOfSections = ls.collapsednessOfSections || {};
      ls.collapsednessOfSections[sectionId] = !isSectionCollapseToggled;
    });

    this.setState({
      isSectionCollapseToggled: !isSectionCollapseToggled,
    });
  };

  hasSectionBeenFilteredOutFromSearch() {
    const { searchResultMediaIds, section } = this.props;
    return (
      this.isFilteringFromSearch() &&
      section.videos.filter(({ hashedId }) => searchResultMediaIds.includes(hashedId)).length === 0
    );
  }

  sectionNameStyle() {
    const { headerFontFamily } = this.props;

    return {
      fontFamily: headerFontFamily,
      fontSize: `${fontScalingIndependentOfGalleryWidth(this.props, 1.6, 18, 22.5)}px`,
      height: '26px',
      letterSpacing: `0.05em`,
      lineHeight: '26px',
    };
  }

  searchInputStyle() {
    const { headerFontFamily } = this.props;

    return {
      fontFamily: headerFontFamily,
      width: Math.min(400, Math.max(250, this.galleryViewWidth() * 0.2)),
    };
  }

  isFilteringFromSearch() {
    return this.props.isFilteringFromSearch && this.props.searchResultMediaIds;
  }

  shouldDisplayMediaFromFilter(hashedId) {
    if (this.isFilteringFromSearch()) {
      return this.props.searchResultMediaIds.includes(hashedId);
    }
    return true;
  }

  renderMediaRow(media, index) {
    const shouldHideMedia =
      !this.shouldDisplayMediaFromFilter(media.hashedId) || this.shouldHideAllCardsInSection();
    return (
      <div
        style={{ display: shouldHideMedia ? 'none' : '' }}
        ref={
          index === 0
            ? (el) => {
                this.firstMediaRowDiv = el;
              }
            : null
        }
      >
        {this.galleryViewWidth() < 800 ? (
          <ListMobileMediaRow key={media.hashedId} media={media} {...this.props} />
        ) : (
          <ListMediaRow key={media.hashedId} media={media} {...this.props} />
        )}
      </div>
    );
  }

  shouldDisplayNoResultsRow() {
    // Only display "no results" in the first section if actively filtering from search and
    // no video hashedIds in the entire channel match the search results
    if (this.isFilteringFromSearch() && this.props.shouldShowSearchInSection) {
      return this.props.searchResultMediaIds.length === 0;
    }
    return false;
  }

  sectionHeaderStyle() {
    const searchInputHeight = this.searchInputFontSize() * 2.5;
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '.7em',
      paddingLeft: '16px',
      paddingRight: '16px',
      minHeight: `${searchInputHeight}px`,
    };
  }

  sectionNameAndLockIconWrapperStyle() {
    return {
      alignItems: 'center',
      // We need to use display so media is still initialized, otherwise playlists/autoplay will skip filtered media
      display: this.hasSectionBeenFilteredOutFromSearch() ? 'none' : 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    };
  }

  renderSearchInput() {
    const { algoliaSearchClient } = this.state;
    if (!algoliaSearchClient) {
      return;
    }

    const { color, backgroundColor, contentTypeLabel, onUpdateMediaFilterFromSearch } = this.props;
    const { searchIndexName } = this.props.galleryData;
    return (
      <SearchProvider
        algoliaSearchClient={algoliaSearchClient}
        algoliaSearchIndexName={searchIndexName}
      >
        <SearchInput
          accentColor={color}
          backgroundColor={backgroundColor}
          contentTypeLabel={contentTypeLabel}
          fontSize={this.searchInputFontSize()}
          onUpdateMediaFilterFromSearch={onUpdateMediaFilterFromSearch}
          customSectionStyle={this.searchInputStyle()}
        />
      </SearchProvider>
    );
  }

  render() {
    const {
      onClickOpenSubscribe,
      subscribeIsRequired,
      section,
      viewerIsSubscribed,
      shouldShowSearchInSection,
      backgroundColor,
    } = this.props;

    const { name } = section;
    const shouldShowHeader = !!section.name || subscribeIsRequired;
    const shouldShowSubscribeButton = subscribeIsRequired && !viewerIsSubscribed;
    const sectionHeaderForegroundColor = backgroundColor === 'ffffff' ? '#000000' : '#ffffff';

    const { isSectionCollapseToggled } = this.state;

    return (
      // Section block
      <section style={this.sectionStyle()}>
        {/* Section title */}
        <div style={this.sectionHeaderStyle()}>
          {shouldShowHeader && (
            <div style={this.sectionNameAndLockIconWrapperStyle()}>
              <SectionName
                name={name}
                foregroundColor={sectionHeaderForegroundColor}
                isToggleCollapseEnabled={!this.isFilteringFromSearch()}
                isSectionCollapsed={isSectionCollapseToggled}
                onToggleSectionCollapse={this.handleToggleSectionCollapse}
                customNameStyle={this.sectionNameStyle()}
              />
              {shouldShowSubscribeButton && (
                <SubscribeButton
                  foregroundColor={sectionHeaderForegroundColor}
                  onClickOpenSubscribe={onClickOpenSubscribe}
                />
              )}
            </div>
          )}
          {shouldShowSearchInSection && this.renderSearchInput()}
        </div>

        {/* Section rows */}
        <div class="row-list">
          {section.videos.map((media, i) => this.renderMediaRow(media, i))}
          {this.shouldDisplayNoResultsRow() && (
            <NoResultsRow
              backgroundColor={backgroundColor}
              fontSize={fontScalingIndependentOfGalleryWidth(this.props, 1.6, 18, 22.5)}
              totalMediaRowHeight={this.state.totalMediaRowHeight}
            ></NoResultsRow>
          )}
        </div>
      </section>
    );
  }
}

export default ListSection;
