import { h, render, Component } from 'preact';
import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { Color } from 'utilities/color.js';
import { unescapeHtml } from 'utilities/core.js';
import { shouldUseNarrowLayout } from './galleryMath.js';

class GalleryTitle extends Component {
  baseFontSize() {
    return shouldUseNarrowLayout(this.props.galleryContext) ? 36 : 48;
  }

  headerFontSize(baseSize) {
    const { galleryContext, headerFontSizeMultiplier, videoCardsLayout } = this.props;

    if (videoCardsLayout === 'list' && !shouldUseNarrowLayout(galleryContext)) {
      return this.listViewFontSize(baseSize);
    }

    return `${headerFontSizeMultiplier * baseSize}px`;
  }

  listViewFontSize(baseSize) {
    const fontSize = Math.min(45, Math.max(36, baseSize * this.props.headerFontSizeMultiplier));
    return `${fontSize}px`;
  }

  renderTextLines() {
    return this.props.title.split('\n').map((textLine, lineNumber) => {
      return (
        <div key={lineNumber} style={this.titleLineStyle()}>
          {unescapeHtml(textLine, { cache: true }) || '\u00A0'}
        </div>
      );
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.getIsTweakModeEnabled && nextProps.getIsTweakModeEnabled()) {
      return false;
    }

    return anyValuesChanged(this.props, nextProps) || anyValuesChanged(this.state, nextState);
  }

  titleTextStyle() {
    const { backgroundColor, foregroundColor, headerFontFamily, heroAlignment, galleryContext } =
      this.props;
    const textShadowStyle = `1px 1px 3px ${new Color(backgroundColor).alpha(0.8).toRgba()}`;

    return {
      color: `#${foregroundColor}`,
      fontFamily: headerFontFamily,
      fontSize: this.headerFontSize(this.baseFontSize()),
      fontWeight: 'normal',
      margin: 0,
      maxWidth: '760px',
      position: 'relative',
      resize: 'both',
      textAlign: heroAlignment === 'left' ? 'left' : 'center',
      textShadow: shouldUseNarrowLayout(galleryContext) ? textShadowStyle : '',
    };
  }

  titleLineStyle() {
    return {
      boxSizing: 'border-box',
      display: 'block',
      margin: 0,
      lineHeight: '1.3em',
    };
  }

  render() {
    return <h1 style={this.titleTextStyle()}>{this.renderTextLines()}</h1>;
  }
}

export default GalleryTitle;
