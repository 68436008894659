const performance = window.performance;

export const isPageLoaded = () => {
  // loadEventEnd will be 0 if it's not loaded and a positive integer if it is
  // loaded.
  return Boolean(performance.timing.loadEventEnd);
};

export const pageLoadTime = () => {
  if (!isPageLoaded()) {
    return undefined;
  }
  return performance.timing.loadEventEnd - performance.timing.navigationStart;
};

let pageLoadPromise;
export const pageLoaded = (timeout = 10000) => {
  if (pageLoadPromise) {
    return pageLoadPromise;
  }

  if (isPageLoaded()) {
    pageLoadPromise = Promise.resolve();
    return pageLoadPromise;
  }

  pageLoadPromise = Promise.race([
    waitPromise(timeout),
    new Promise((resolve) => {
      const onLoad = () => {
        resolve();
        window.removeEventListener('load', onLoad, false);
      };
      window.addEventListener('load', onLoad, false);
    }),
  ]);

  return pageLoadPromise;
};

const waitPromise = (timeout = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

// In Chrome's Performance Tools, it appears that things that start _on_ the
// "load" event are included in the browser's metrics _before_ load.  Adding a
// little timeout seems to avoid that.
export const afterPageLoaded = (timeout = 1) => {
  return pageLoaded()
    .then(() => waitPromise(timeout))
    .then(() => pageLoadTime());
};

export const pageIdle = (timeout) => {
  if (!window.requestIdleCallback) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    window.requestIdleCallback(resolve, { timeout });
  });
};

export const pageLoadedAndIdle = (timeout) => {
  return afterPageLoaded().then(() => pageIdle(timeout));
};
