import { h, FunctionComponent } from 'preact';

interface Props {
  fill: string;
}

export const HeadphonesIcon: FunctionComponent<Props> = ({ fill }) => {
  return (
    <svg width="16" height="14">
      <g fill={fill}>
        <path d="M1.296 11.678V7.763c0-.398.014-.794.071-1.19a6.909 6.909 0 0 1 .517-1.692c-.02.048-.043.095-.063.142.253-.558.58-1.086.976-1.567l-.1.12c.38-.46.82-.874 1.314-1.232l-.129.094c.47-.337.981-.619 1.525-.835l-.152.06a7.14 7.14 0 0 1 1.705-.438 7.276 7.276 0 0 1 1.46 0 7.14 7.14 0 0 1 1.705.437l-.153-.059a7.163 7.163 0 0 1 1.525.835l-.128-.094a7.062 7.062 0 0 1 1.213 1.112c.397.482.723 1.009.977 1.567-.02-.047-.043-.094-.064-.142.267.592.446 1.214.54 1.849a5.352 5.352 0 0 1-.023-.157c.095.663.071 1.333.071 2v3.106c0 .31.292.608.634.593.344-.015.634-.26.634-.593V7.684c0-.454-.036-.902-.106-1.35a7.52 7.52 0 0 0-1.702-3.643 7.794 7.794 0 0 0-1.5-1.344C10.923.564 9.564.11 8.174.017A7.767 7.767 0 0 0 4.1.879a7.695 7.695 0 0 0-2.928 2.71A7.524 7.524 0 0 0 .028 7.501c-.005.59 0 1.178 0 1.766v2.411c0 .31.292.608.634.593.342-.015.634-.261.634-.593z" />
        <path d="M5.134 8.785c0-.71-.654-1.285-1.46-1.285-.806 0-1.459.575-1.459 1.285v3.566c0 .71.655 1.285 1.46 1.285.807 0 1.46-.575 1.46-1.285zm8.028 0c0-.71-.655-1.285-1.46-1.285-.807 0-1.46.575-1.46 1.285v3.566c0 .71.655 1.285 1.46 1.285.807 0 1.46-.575 1.46-1.285z" />
      </g>
    </svg>
  );
};
