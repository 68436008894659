import { h, render, Component } from 'preact';
import { CssReset } from 'utilities/CssReset.jsx';
import { seqId } from 'utilities/seqid.js';
import { afterPageLoaded } from 'utilities/page-loaded.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { DEFAULT_FONT_FAMILY } from './defaultFont.js';
import Featured from './Featured.jsx';
import GalleryCardCover from './GalleryCardCover.jsx';
import GalleryHeroBackground from './GalleryHeroBackground.jsx';
import GalleryHeroContent from './GalleryHeroContent.jsx';
import getBodyClass from './getBodyClass.js';

let SubscribeModal = () => '';

class GalleryView extends Component {
  constructor(props) {
    super(props);
    this._uuid = seqId();
    this.galleryHeroContentElem = null;
    this.state = {
      featuredBlockHeight: 0,
      isFocusedOnStartWatching: false,
      isHoveringInHeadArea: false,
      isHoveringOnStartWatching: false,
      subscribeFormLoaded: false,
      transcriptIsOpen: false,
      videoBackgroundIsEmbedded: false,
    };
  }

  componentDidMount() {
    this.maybeScrollToSectionFromProps(this.props);
    if (this.props.subscribeEnabled) {
      this.loadSubscribeForm();
    }
  }

  componentDidUpdate(prevProps) {
    this.maybeScrollToSectionFromProps(this.props, prevProps);
    if (this.props.subscribeEnabled && !prevProps.subscribeEnabled) {
      this.loadSubscribeForm();
    }
  }

  loadSubscribeForm() {
    afterPageLoaded().then(() => {
      dynamicImport('assets/external/channel/SubscribeModal.js').then((mod) => {
        SubscribeModal = mod.SubscribeModal;
        this.setState({ subscribeFormLoaded: true });
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.galleryData.series[0].sections !== this.props.galleryData.series[0].sections) {
      this._cachedSections = null;
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.getIsTweakModeEnabled && nextProps.getIsTweakModeEnabled()) {
      return false;
    }

    const sectionRefToScrollTo =
      nextProps.visibleSectionId && this.sectionRefs[`section_${nextProps.visibleSectionId}`];
    if (sectionRefToScrollTo) {
      return true;
    }
  }

  cssResetStyle() {
    return {
      height: '100%',
      position: 'relative',
      width: '100%',
    };
  }

  extraCssRules() {
    return [
      '.w-text-highlight-allowed.w-css-reset-tree>* {user-select:text;-webkit-user-select:text;}',
      '.w-text-highlight-allowed.w-css-reset-tree>* * {user-select:text;-webkit-user-select:text;}',
    ];
  }

  galleryViewStyle() {
    return {
      background: `#${this.props.backgroundColor}`,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: DEFAULT_FONT_FAMILY,
      lineHeight: 'normal',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
    };
  }

  maybeScrollToSectionFromProps(props, prevProps = {}) {
    const sectionRefToScrollTo =
      props.visibleSectionId && this.sectionRefs[`section_${props.visibleSectionId}`];
    if (
      // we have a section to scroll to, and either the visibleSectionId prop
      // changed, or same visibleSectionId prop was passed in again but the user
      // manually scrolled away since the previous time it was passed in
      sectionRefToScrollTo &&
      (props.visibleSectionId !== prevProps.visibleSectionId ||
        this.sectionsContainer.scrollTop !== sectionRefToScrollTo.offsetTop)
    ) {
      this.sectionsContainer.scrollTop = sectionRefToScrollTo.offsetTop;
    }
  }

  onBackgroundPosterEmbedded = (poster) => {
    this._backgroundPoster = poster;
    const { currentVideoId, setBackgroundPosterRef } = this.props;

    if (setBackgroundPosterRef) {
      setBackgroundPosterRef(this._backgroundPoster);
    }
    // if another video has started playing before the background, pause the
    // background video once it's up.
    if (currentVideoId) {
      this._backgroundPoster.whenControlMounted('video').then((video) => video.pause());
    }
  };

  setFeaturedBlockHeight = (featuredBlockHeight) => {
    this.setState({
      featuredBlockHeight,
    });
  };

  render() {
    const { color, elemRef, pageLoadComplete, shouldShowFeatured, shouldShowHeader } = this.props;
    const { featuredBlockHeight } = this.state;
    const BodyClass = getBodyClass(this.props);
    return (
      <div ref={elemRef}>
        <CssReset
          extraRules={this.extraCssRules()}
          selectionColor={color}
          style={this.cssResetStyle()}
        >
          <div
            class="w-gallery-view w-css-reset w-text-highlight-allowed"
            style={this.galleryViewStyle()}
          >
            {pageLoadComplete && shouldShowHeader && (
              <GalleryHeroBackground
                fadeInTime={1000}
                imageFitStrategy={'cover'}
                onBackgroundPosterEmbedded={this.onBackgroundPosterEmbedded}
                galleryHeroContentElem={this.galleryHeroContentElem}
                {...this.props}
              />
            )}
            <GalleryCardCover
              galleryHeroContentElem={this.galleryHeroContentElem}
              {...this.props}
            />
            {shouldShowHeader && (
              <GalleryHeroContent
                featuredBlockHeight={featuredBlockHeight}
                galleryHeroContentRef={(elem) => (this.galleryHeroContentElem = elem)}
                {...this.props}
              />
            )}
            {shouldShowFeatured && (
              <Featured
                {...this.props}
                marginLeft={BodyClass.marginLeft(this.props)}
                setFeaturedBlockHeight={this.setFeaturedBlockHeight}
              />
            )}
            <div class="w-css-reset w-css-reset-tree">
              <BodyClass {...this.props} />
              {this.props.subscribeEnabled && <SubscribeModal {...this.props} />}
            </div>
          </div>
        </CssReset>
      </div>
    );
  }
}

export default GalleryView;
