import { bindify } from 'utilities/bindify.js';
import { Wistia } from '../../../wistia_namespace.ts';

if (!Wistia.EmbedRegistry) {
  class EmbedRegistry {
    constructor() {
      this._embeds = {};
    }

    containers() {
      return this.entries().map((entry) => entry.container);
    }

    apiHandles() {
      return this.entries().map((entry) => entry.apiHandle);
    }

    entries() {
      // Can't use Object.values here, because IE 11
      return Object.keys(this._embeds).map((key) => this._embeds[key]);
    }

    register(id, container, apiHandle) {
      this._embeds[id] = {
        id,
        container,
        apiHandle,
      };
      this.trigger('registered', this._embeds[id]);
    }

    unregister(id) {
      const value = this._embeds[id];
      if (value) {
        delete this._embeds[id];
        this.trigger('unregistered', value);
      }
    }

    onFind(callback) {
      // run callback for each existing embed.
      const fn = (entry) => callback(entry.apiHandle);

      // Can't use Object.values here, because IE 11
      Object.keys(this._embeds)
        .map((key) => this._embeds[key])
        .forEach(fn);

      // run on any future embeds and return an unbinding function.
      return this.on('registered', fn);
    }

    destroy() {
      this._bindings = {};
      this._embeds = {};
    }
  }
  bindify(EmbedRegistry.prototype);
  Wistia.EmbedRegistry = EmbedRegistry;
}

export default Wistia.EmbedRegistry;
