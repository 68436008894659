import { h, render, Component } from 'preact';
import { batchFetchData } from 'utilities/batchFetchMediaData.js';
import { assign } from 'utilities/assign.js';
import { cachedDetect } from 'utilities/detect.js';
import { unescapeHtml } from 'utilities/core.js';
import { getShortDescription } from 'utilities/getShortDescription.js';
import { isMouseDownRecently } from 'utilities/isMouseDown.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { Wistia } from '../../../wistia_namespace.ts';
import { humanReadableDuration } from '../../../utilities/duration.ts';
import { fontScalingIndependentOfGalleryWidth } from '../galleryMath.js';
import { VideoIcon } from './VideoIcon.tsx';
import { HeadphonesIcon } from './HeadphonesIcon.tsx';
import { RawHTMLStub } from '../../shared/RawHTMLStub.jsx';
import { setRouteDataOnUri } from '../uriStateTransformers.js';

const detect = cachedDetect();

class ListMediaRow extends Component {
  constructor(props) {
    super(props);
    const { color, media } = props;
    this.unescapedName = unescapeHtml(media.name);
    this.unbinds = [];
    const { galleryData, hashedId, routeStrategyOptions } = this.props;
    const routeData = {
      wchannelid: galleryData.hashedId,
      wmediaid: hashedId,
    };
    this.videoUrl = setRouteDataOnUri(location.href, routeData, routeStrategyOptions);
    this.state = {
      color,
      isHovering: false,
      isKeyboardFocused: false,
    };
  }

  get playerColor() {
    const { color, galleryEmbedOptions } = this.props;
    return this.state.color || color || galleryEmbedOptions.color;
  }

  componentDidMount() {
    const { on } = this.props;

    this.maybeInitPopover();
    this.fetchDescription();

    this.unbinds.push(
      on('colorchange', (color) => {
        this.setState({ color });

        if (this.popover) {
          this.popover._poster.updateEmbedOptions({ color });
        }
      }),
      on('subscribechange', (subscribe) => {
        if (this.popover && subscribe?.on) {
          this.popover.setOpeningIsDisabled(subscribe?.required);
        }
      }),
    );
  }

  componentDidUpdate() {
    this.maybeInitPopover();

    if (this.popover && this.popover._poster) {
      const popoverEmbedOptions = this.getPopoverEmbedOptions();
      const { contentTypeLabel, posterRef } = this.props;

      this.popover.setContentTypeLabel(contentTypeLabel);
      this.popover.setNavigation(popoverEmbedOptions.navigation);

      if (posterRef) {
        posterRef(this.popover._poster);
      }
    }
  }

  descriptionStyle() {
    return {
      ...this.textStyle(),
      display: '-webkit-box',
      fontSize: `${fontScalingIndependentOfGalleryWidth(this.props, 1.3, 14, 17)}px`,
      fontWeight: '500',
      lineHeight: '1.25em',
      marginTop: '8px',
      overflow: 'hidden',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: 3,
    };
  }

  fetchDescription() {
    const { media } = this.props;

    batchFetchData(media.hashedId, this.getPopoverEmbedOptions()).then(
      ({ basic: { description, duration } }) => {
        this.setState({
          duration: humanReadableDuration(duration),
          shortDescription: getShortDescription(unescapeHtml(description)),
        });
      },
    );
  }

  maybeInitPopover() {
    const { suppressPopover } = this.props;

    if (!this.didInit && !suppressPopover) {
      this.didInit = true;
      dynamicImport('assets/external/poster.js');
      dynamicImport('assets/external/popover-v3.js').then(() => {
        Wistia.embedPopover(this.props.media.hashedId, this.getPopoverEmbedOptions()).then((p) => {
          this.popover = p;
          p.setPosterPlayButtonCover(false);
        });
      });
    }
  }

  getPopoverEmbedOptions() {
    const {
      galleryData,
      galleryEmbedOptions,
      headerFontFamily,
      media,
      subscribeIsRequired,
      title,
      viewerIsSubscribed,
    } = this.props;
    const shouldShowTranscript =
      galleryEmbedOptions.shouldShowTranscript == null
        ? true
        : galleryEmbedOptions.shouldShowTranscript;

    return assign(
      {
        channel: galleryData.hashedId,
        channelId: galleryData.numericId,
        channelTitle: title,
        container: this.popoverContainer,
        episodeId: media.episodeNumericId,
        fontFamily: headerFontFamily,
        openingIsDisabled: subscribeIsRequired && !viewerIsSubscribed,
        playerColor: this.playerColor,
        podcastLinks: galleryData.podcastLinks,
        popoverContent: 'poster',
        posterOptions: {
          backgroundColor: 'transparent',
          bpbCoverOnHover: true,
          channel: galleryData.hashedId,
          color: this.playerColor,
          deliveryCdn: galleryEmbedOptions.deliveryCdn,
          embedHost: galleryEmbedOptions.embedHost,
          playButton: true,
          playButtonWidth: 70,
          playButtonOnCoverOnly: true,
        },
        shouldShowTranscript,
      },
      this.props.getVideoEmbedOptions(),
    );
  }

  mediaDurationStyles() {
    return {
      color: `#${this.playerColor}`,
      fontSize: `${fontScalingIndependentOfGalleryWidth(this.props, 1.1, 12, 15)}px`,
      fontWeight: 'bold',
      letterSpacing: '1.5px',
      marginLeft: '8px',
      textTransform: 'uppercase',
    };
  }

  mediaRowStyles() {
    const { backgroundColor } = this.props;
    const { isHovering, isKeyboardFocused } = this.state;
    const bgColor =
      backgroundColor === 'ffffff' ? 'rgba(24, 24, 24, 0.15)' : 'rgba(216, 216, 216, 0.15)';

    return {
      display: 'flex',
      padding: '16px',
      backgroundColor: isHovering ? bgColor : '',
      borderRadius: '8px',
      boxShadow: isKeyboardFocused ? '0 0 0 2px #fff inset' : 'none',
      outline: 'none',
      transition: 'background-color 300ms',
    };
  }

  nameStyle() {
    return {
      ...this.textStyle(),
      fontSize: `${fontScalingIndependentOfGalleryWidth(this.props, 1.6, 18, 22.5)}px`,
      fontWeight: '500',
      lineHeight: '1.25em',
      marginTop: '8px',
    };
  }

  onClickListMediaRow = (event) => {
    const { media, onClickVideoCard } = this.props;
    event?.preventDefault();
    onClickVideoCard(media.hashedId, this.listMediaRowRef);
  };

  onBlur = () => {
    this.setState({ isKeyboardFocused: false });
  };

  onFocus = () => {
    this.setState({
      isKeyboardFocused: !isMouseDownRecently(),
    });
  };

  onMouseLeave = () => {
    this.setState({ isHovering: false });
    this.popover.setPosterPlayButtonCover(false);
  };

  onMouseEnter = () => {
    this.setState({
      isHovering: true,
    });
    this.popover.setPosterPlayButtonCover(true);
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onClickListMediaRow();
    }
  };

  setListMediaRowRef = (elem) => {
    const { elemRef } = this.props;
    if (elemRef) {
      elemRef(elem);
    }
    this.listMediaRowRef = elem;
  };

  thumbnailStyles() {
    return { width: '128px', flexShrink: '0', position: 'relative' };
  }

  textStyle() {
    const { foregroundColor, headerFontFamily } = this.props;
    return {
      color: `#${foregroundColor}`,
      fontFamily: headerFontFamily,
    };
  }

  render() {
    const { media, foregroundColor } = this.props;
    const { duration, shortDescription } = this.state;
    const { aspectRatio } = media;
    const inverseAspectPercent = ((1 / aspectRatio) * 100).toFixed(2);
    const iconColor = `#${foregroundColor}`;

    return (
      <a
        class="w-video-row"
        href={this.videoUrl}
        onBlur={this.onBlur}
        onClick={this.onClickListMediaRow}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        onMouseEnter={detect.hoverIsNatural ? this.onMouseEnter : null}
        onMouseLeave={detect.hoverIsNatural ? this.onMouseLeave : null}
        style={this.mediaRowStyles()}
        ref={this.setListMediaRowRef}
      >
        <div style={this.thumbnailStyles()}>
          <div
            class="wistia_responsive_padding"
            style={`padding:${inverseAspectPercent}% 0 0 0;position:relative;`}
          >
            <div
              class="wistia_responsive_wrapper"
              style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}
            >
              <RawHTMLStub
                class="wistia_popover"
                stubRef={(el) => (this.popoverContainer = el)}
                style={{
                  height: '100%',
                  position: 'relative',
                  width: '100%',
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ paddingLeft: '32px' }}>
          <div style={{ display: 'flex' }}>
            {media.type === 'Audio' ? (
              <HeadphonesIcon fill={iconColor} />
            ) : (
              <VideoIcon fill={iconColor} />
            )}
            <div style={this.mediaDurationStyles()}>{duration}</div>
          </div>
          <h3 style={this.nameStyle()} title={this.unescapedName}>
            {this.unescapedName}
          </h3>
          <p style={this.descriptionStyle()}>{shortDescription}</p>
        </div>
      </a>
    );
  }
}

export default ListMediaRow;
