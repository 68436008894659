import { Wistia } from '../../../wistia_namespace.ts';

export const createApiSugar = ({ apiSugarName, embedMatcher, embedRegistry }) => {
  Wistia[apiSugarName] = (matcher, fn, opts = {}) => {
    const { once } = opts;
    let hasRun = false;

    // Matcher might not be a matcher at all, but instead just a function to run
    // on find
    const fnToRun = typeof matcher === 'function' ? matcher : fn;
    if (fnToRun) {
      const unbind = embedRegistry.onFind((apiHandle) => {
        // If the matcher isn't actually a matcher, but a function, run it on
        // all embeds. Otherwise treat the matcher as a matcher, and only run
        // the function on embeds that match it.
        const matches = typeof matcher === 'function' || embedMatcher.matches(apiHandle, matcher);
        if (!matches || (once && hasRun)) {
          return;
        }
        if (once) {
          setTimeout(() => {
            unbind();
          }, 0);
        }
        hasRun = true;
        fnToRun(apiHandle);
      });
      return unbind;
    }

    return embedMatcher.find(matcher);
  };

  Wistia[apiSugarName].once = (matcher, fn) => {
    return Wistia[apiSugarName](matcher, fn, { once: true });
  };

  Wistia[apiSugarName].all = () => {
    return embedMatcher.all();
  };
};
