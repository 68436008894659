import { h } from 'preact';

/**
 * Displays "no results" in a section which has been completely filtered out from search
 *
 * @param {string} backgroundColor - hex code usually either #ffffff or #000000, but could be set to any hex via embed options
 * @param {number} cardWidth - width calculated from first card in the section
 * @param {number} cardHeight - height calculated from first card in the section
 * @param {number} fontSize - scaled size of the text within the card
 * @param {number} totalMediaCardHeight - clientHeight of a video card grabbed after it has rendered - we need this to keep a consistent bottom margin
 * @returns {JSX.Element}
 */
function NoResultsCard({ backgroundColor, cardWidth, cardHeight, fontSize, totalMediaCardHeight }) {
  // Light style if foreground color is set to white, otherwise assume dark style for any other color
  const isDarkStyle = backgroundColor !== 'ffffff';

  const cardContainerStyle = () => {
    const marginBottom = totalMediaCardHeight ? totalMediaCardHeight - cardHeight : 0;
    return {
      marginBottom: `${marginBottom}px`,
      display: 'inline-block',
    };
  };

  const cardStyle = () => {
    return {
      backgroundColor: isDarkStyle ? '#1f1f22' : '#dedee1',
      borderRadius: '8px',
      color: isDarkStyle ? '#7b7b87' : '#4a4a51',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: `${fontSize}px`,
      verticalAlign: 'top',
      width: cardWidth || 0,
      height: cardHeight || 0,
    };
  };

  return (
    <div style={cardContainerStyle()}>
      <div style={cardStyle()}>No Results</div>
    </div>
  );
}

export default NoResultsCard;
