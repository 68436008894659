import { h, render } from 'preact';
import { standardSvgAttrs } from 'utilities/svg_boilerplate.ts';

export const CaretDownIcon = ({ color }) => {
  return (
    <svg {...standardSvgAttrs({ width: 24, height: 24, ariaHidden: true })}>
      <g stroke="none" strokeWidth="1" fill={`${color}` || '#fff'} fillRule="evenodd">
        <path d="M2.68580668,7.23810271 C3.20973443,6.66479163 4.07637475,6.59142865 4.6865449,7.04498115 L4.80498179,7.14272518 L11.999,13.718 L19.1949762,7.14272794 C19.7682858,6.61879863 20.6380126,6.6235818 21.2049737,7.13011174 L21.3141516,7.23809969 C21.8380809,7.81140934 21.8332977,8.68113614 21.3267678,9.24809725 L21.2187798,9.35727506 L13.0119048,16.8572751 C12.4771172,17.3460003 11.676781,17.378583 11.1065857,16.9550226 L10.9881042,16.8572778 L2.78118421,9.35727782 C2.16965239,8.79842155 2.12695041,7.84963454 2.68580668,7.23810271 Z" />
      </g>
    </svg>
  );
};
