let cachedKeys = {};

const getKeysToCheck = (obj1, obj2, options) => {
  const { cacheKeys, only } = options;
  const keysToCheckCacheKey = cacheKeys;

  if (only) {
    return [only, only, only];
  }

  const keysFromCache = cachedKeys[keysToCheckCacheKey];
  if (keysFromCache) {
    return [keysFromCache, keysFromCache, keysFromCache];
  }

  const allKeys = {};
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);
  Object.keys(obj1).forEach((k) => {
    allKeys[k] = true;
  });
  Object.keys(obj2).forEach((k) => {
    allKeys[k] = true;
  });

  return [Object.keys(allKeys), obj1Keys, obj2Keys];
};

export const anyValuesChanged = (givenObj1, givenObj2, options = {}) => {
  const obj1 = givenObj1 || {};
  const obj2 = givenObj2 || {};
  const [keysToCheck, obj1Keys, obj2Keys] = getKeysToCheck(obj1, obj2, options);

  // cache the keys we got back. we expect no new keys to be added for this to
  // work.
  const { cacheKeys: keysToCheckCacheKey } = options;
  if (keysToCheckCacheKey && keysToCheck.length > 0) {
    cachedKeys[keysToCheckCacheKey] = keysToCheck;
  }

  const except = options.except || [];

  if (except.length === 0 && obj1Keys.length !== obj2Keys.length) {
    // console.log('length changed', obj1Keys.length, 'to', obj2Keys.length);
    return true;
  }

  return keysToCheck.some((k) => {
    return except.indexOf(k) < 0 && obj1[k] !== obj2[k];
    // console.log('changed', k);
  });
};

export const clearAnyValuesChangedKeyCache = () => {
  cachedKeys = {};
};
