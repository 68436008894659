import { isMouseDownRecently } from 'utilities/isMouseDown.js';
import { h, render, Component } from 'preact';

const DEFAULT_WIDTH = 20;
const DEFAULT_HEIGHT = 20;

export class CloseButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
    };
  }

  setRef = (e) => {
    if (this.props.closeRef) {
      this.props.closeRef(e);
    }
  };

  render() {
    return (
      <button
        class={this.props.class}
        onBlur={this.onBlur}
        onClick={this.props.onClickClose}
        onFocus={this.onFocus}
        style={this.buttonStyle()}
        type="button"
        data-test="close-button"
        ref={this.setRef}
      >
        <svg viewBox="0 0 18 18">
          <line style={this.lineStyle()} x1="1.5" y1="1.5" x2="16.5" y2="16.5" />
          <line style={this.lineStyle()} x1="16.5" y1="1.5" x2="1.5" y2="16.5" />
        </svg>
      </button>
    );
  }

  buttonStyle() {
    const focusOutline = this.state.isFocused ? '2px solid white' : 'none';
    return {
      background: 'transparent',
      border: 0,
      cursor: 'pointer',
      height: `${DEFAULT_HEIGHT}px`,
      outline: focusOutline,
      padding: '2px',
      width: `${DEFAULT_WIDTH}px`,
      ...this.props.style,
    };
  }

  lineStyle() {
    return {
      stroke: `#${this.props.color}`,
      strokeWidth: 3,
      strokeLinecap: 'round',
    };
  }

  onBlur = () => {
    this.setState({
      isFocused: false,
    });
  };

  onFocus = () => {
    this.setState({
      isFocused: !isMouseDownRecently(),
    });
  };
}
