import { h, Component } from 'preact';
import { seqId } from 'utilities/seqid.js';
import { cssResetForSelector } from 'utilities/cssReset.js';

export class CssReset extends Component {
  constructor(props) {
    super(props);

    this.id1 = seqId('w-react-css-reset-');
    this.id2 = seqId('w-react-css-reset-');
    const doubleIdPrefix = `#${this.id1} #${this.id2}`;
    const cssResetContent = cssResetForSelector(`${doubleIdPrefix} `, '.w-css-reset');
    const cssResetTreeContent = cssResetForSelector(`${doubleIdPrefix} .w-css-reset-tree `, '');
    this.cssResetString = `
      ${cssResetContent}
      ${cssResetTreeContent}
      ${doubleIdPrefix} .w-css-reset-max-width-none-important{max-width:none!important}
      ${doubleIdPrefix} .w-css-reset-button-important{border-radius:0!important;color:#fff!important;}
    `;

    const { selectionColor } = props;

    if (selectionColor) {
      this.cssResetString += `${doubleIdPrefix} ::selection{background-color: #${selectionColor};}`;
    }

    if (props.extraRules) {
      this.cssResetString += props.extraRules.map((rule) => `${doubleIdPrefix} ${rule}`).join('\n');
    }
  }

  // NOTE: This will setup a CSS reset that is specific to all descendant
  // elements. To use the reset on a single element, set the w-css-reset class
  // on it. To apply it to all descendants of that element, also use
  // w-css-reset-tree.
  render() {
    return (
      <div id={this.id1} style={this.props.div1Style || this.props.style}>
        <div id={this.id2} style={this.props.div2Style || this.props.style}>
          <style>{this.cssResetString}</style>
          {this.props.children}
        </div>
      </div>
    );
  }
}
