import { h, FunctionComponent } from 'preact';

interface Props {
  fill: string;
}

export const VideoIcon: FunctionComponent<Props> = ({ fill }) => {
  return (
    <svg width="16" height="14">
      <g fill="none" fill-rule="evenodd" stroke={fill}>
        <rect width="14.75" height="12.34" x=".65" y=".65" stroke-width="1.3" rx="2" />
        <path fill={fill} fill-rule="nonzero" d="M9.063 7 7 8.5v-3z" />
      </g>
    </svg>
  );
};
