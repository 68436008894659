export const upsertMetaTag = (name, content) => {
  let tag = document.querySelector(`meta[name="${name}"], meta[property="${name}"]`);
  if (!content || /^\s+$/.test(content)) {
    if (tag && tag.parentNode) {
      tag.parentNode.removeChild(tag);
    }
    return;
  }

  if (!tag) {
    tag = document.createElement('meta');
    document.head.appendChild(tag);
  }
  tag.setAttribute('name', name);
  tag.setAttribute('content', content);
};

export const getMetaContent = (name) => {
  const tag = document.querySelector(`meta[name="${name}"], meta[property="${name}"]`);
  if (!tag) {
    return undefined;
  }
  return tag.getAttribute('content');
};
