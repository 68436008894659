import { h, render, Component } from 'preact';
import { Color } from 'utilities/color.js';
import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { shouldUseNarrowLayout } from './galleryMath.js';
import headerFontSizeGw from './headerFontSizeGw.js';
import { DescriptionHTMLStub } from './DescriptionHTMLStub.jsx';

export class GalleryDescription extends Component {
  componentDidMount() {
    this.zeroOutEdgeMargins();
  }

  componentDidUpdate() {
    this.zeroOutEdgeMargins();
  }

  descriptionFontSize() {
    const { galleryContext, videoCardsLayout } = this.props;
    const isListView = videoCardsLayout === 'list';
    const baseFontSize = shouldUseNarrowLayout(galleryContext) ? 4 : 1.4;

    return isListView
      ? `${headerFontSizeGw(this.props, baseFontSize, 18, 22.5)}px`
      : `${headerFontSizeGw(this.props, baseFontSize, 16)}px`;
  }

  descriptionStyle() {
    const {
      foregroundColor,
      backgroundColor,
      headerFontFamily,
      heroAlignment,
      galleryContext,
      videoCardsLayout,
    } = this.props;

    const textShadowStyle = `1px 1px 3px ${new Color(backgroundColor).alpha(0.8).toRgba()}`;
    const isListView = videoCardsLayout === 'list' && !shouldUseNarrowLayout(galleryContext);

    return {
      color: `#${foregroundColor}`,
      fontFamily: headerFontFamily,
      fontSize: this.descriptionFontSize(),
      lineHeight: '1.5em',
      margin: `18px 0 ${shouldUseNarrowLayout(galleryContext) ? '5vh' : 0}`,
      maxWidth: isListView ? '808px' : '',
      textShadow:
        heroAlignment === 'left' || shouldUseNarrowLayout(galleryContext) ? textShadowStyle : '',
    };
  }

  getDescription() {
    return this.props.description.replace(/\n/g, '<br>');
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.getIsTweakModeEnabled && nextProps.getIsTweakModeEnabled()) {
      return false;
    }

    return anyValuesChanged(this.props, nextProps) || anyValuesChanged(this.state, nextState);
  }

  zeroOutEdgeMargins() {
    const nonTextNodeChildren = Array.prototype.slice
      .call(this.descriptionRoot.childNodes)
      .filter((n) => n.nodeType === 1);
    nonTextNodeChildren.forEach((node, i) => {
      if (i === 0) {
        node.style.marginTop = 0;
      }
      if (i === nonTextNodeChildren.length - 1) {
        node.style.marginBottom = 0;
      }
    });
  }

  render() {
    return (
      <div
        class="w-gallery-view__description"
        ref={(el) => (this.descriptionRoot = el)}
        style={this.descriptionStyle()}
      >
        <DescriptionHTMLStub html={this.getDescription()} />
      </div>
    );
  }
}
