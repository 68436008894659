import { h, render, Component } from 'preact';

export class DescriptionHTMLStub extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.html !== nextProps.html;
  }

  render() {
    return <span dangerouslySetInnerHTML={{ __html: this.props.html }} />;
  }
}
