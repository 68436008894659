import { Wistia } from '../../wistia_namespace.ts';
import Gallery from './Gallery.js';

if (!Wistia.embedGallery) {
  Wistia.embedGallery = (hashedId, options) => {
    return new Promise((resolve, reject) => {
      const galleryData = hashedId;
      try {
        const gallery = new Gallery(galleryData, options);
        resolve(gallery);
      } catch (e) {
        reject(e);
      }
    });
  };
}

export default Wistia.embedGallery;
